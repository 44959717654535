import React from 'react'
import { PageHeader, Space, Layout } from 'antd'
import { ReactComponent as Logo } from '../../public/logo-blanc.svg';
import preview1 from '../assets/gtm_id_1.jpg'
import preview2 from '../assets/gtm_id_2.jpg'
import preview3 from '../assets/gtm_id_3.jpg'
import { Link } from "react-router-dom";

export default function AnalyticsGuide() {
    return (
        <div>
            <Layout.Header style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Link to="/">
                        <div className="logo" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Logo  style={{width: 100}}/></div>
                    </Link>
            </Layout.Header>
            <div style={{padding: 50}}>
                <PageHeader
                    title={
                        <Space>
                            <h1>Tracking Google Tag Manager</h1>
                        </Space>}
                    style={{ borderBottom: '2px solid #2494D1' }}
                />
                <div style={{ marginTop: "2em" }}>
                    <p >
                        Google tag manager permet le tracking d’évènement sur votre site et peux donc permettre de traquer les cliques sur les étapes de vos widgets ainsi que d’autres éléments présent dans les étapes.
                    </p>
                    <p>
                        Chaque bouton de validation d’étape à donc un id qui peut être utilisé dans google tag manager. <br />
                        Un id est formé tel que suit : “brd_&#123;etape&#125;_&#123;widgetId&#125;”
                    </p>
                    <p>
                        Exemple d’id présent sur le bouton commencer : <strong>“brd_start_447b4698-bc73-11eb-b8dd-3cecef7448e8”</strong>
                    </p>
                    <p style={{ color: 'red' }}><strong>Points importants : </strong></p>
                    <ul>
                        <li>Chaque id contient l’identifiant du widget ouvert. <br /></li>
                        <li>Le bouton de réservation ne possède pas directement l'id, pour ne pas fausser les statistiques lorsqu'une réservation n'est pas validé <br />
                            L'évènement est déclenché par le script uniquement lorsque la réservation est enregistré
                        </li>
                    </ul>
                    <h3>Id dans le widget</h3>
                    <p>Ensemble des id présent dans le widget (prenons pour exemple un widgetId = ‘11-22’</p>
                </div>

                <img src={preview1} alt="preview1" style={{width: '50%'}}/>
                <img src={preview2} alt="preview2" style={{width: '50%'}}/>
                <img src={preview3} alt="preview3" style={{width: '50%'}}/>

            </div>
        </div>
    )
}
