import React, {useState} from "react";
import Button from "./Button";
import {ReactComponent as Phone} from '../svg/phone.svg'
import {ReactComponent as Email} from '../svg/email.svg'
import {ReactComponent as User} from '../svg/user.svg'
import {ReactComponent as Key} from '../svg/key.svg'
import Input from "./Input";
import {useWidgetContext} from "../../providers/WidgetProvider";
import Loading from "./Loading";

export default function SignUp({switchMode}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, i18n} = widgetState

    const {post, setter} = widgetDispatch;
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleChange = (e, setter) => {
        setter(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (password.trim() !== confirmPassword.trim()) {
            return setError(i18n.gettext('Les mots de passe ne correspondent pas'))
        }
        setLoading(true)

        let params = {
            email: email.trim(),
            password: password.trim(),
            lastName: lastName.trim() !== '' ? lastName.trim() : null,
            firstName: firstName.trim() !== '' ? firstName.trim() : null,
            phone: phone.trim(),
        }

        let response = await post('signup', params)
        if (response.success === 0) {
            setError(response.error)
            setLoading(false)

            return console.log('login fail')
        }
        response.type = 'inscription'
        setter('user', response)
        setLoading(false)
    }

    return (
        <div className="order-content">
            <Loading loading={loading} >

            <div className="title" style={{marginBottom: '0'}}>
                <h2>{i18n.gettext('Inscription')}</h2>
            </div>
            <div style={{height: '35px'}}>
                <p style={{color: "red", textAlign: 'center'}}>
                    <small >{error}</small>
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <Input required value={email} type="email"
                       placeholder={i18n.gettext("Votre adresse mail")}
                       icon={<Email style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                       onChange={(e) => handleChange(e, setEmail)}/>

                <Input value={firstName} required
                       placeholder={i18n.gettext("Votre prénom")}
                       icon={<User style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                       onChange={(e) => handleChange(e, setFirstName)}/>

                <Input value={lastName} required
                       placeholder={i18n.gettext("Votre Nom")}
                       icon={<User style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                       onChange={(e) => handleChange(e, setLastName)}/>

                <Input value={phone} required
                       placeholder={i18n.gettext("Votre téléphone portable")}
                       icon={<Phone style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                       onChange={(e) => handleChange(e, setPhone)}/>

                <Input required value={password} type="password" 
                       placeholder={i18n.gettext("Votre mot de passe")}
                       icon={<Key style={{fill: widget.backgroundColor || "#2494D1"}}/>} onChange={(e) => handleChange(e, setPassword)}/>

                <Input required value={confirmPassword} type="password"
                       placeholder={i18n.gettext("Confirmer le mot de passe")}
                       icon={<Key style={{fill: widget.backgroundColor || "#2494D1"}}/>} onChange={(e) => handleChange(e, setConfirmPassword)}/>

                <div style={{textAlign: 'center', margin: '10px auto 16px'}}>
                    <p style={{ marginBottom: '5px', fontWeight: 'bold'}}>{i18n.gettext('Vous avez déjà un compte ?')}</p>
                    <p style={{color: widget.backgroundColor || "#2494D1", textDecoration: 'underline', cursor: "pointer"}} onClick={switchMode}>{i18n.gettext('Connectez-vous !')}</p>
                </div>

                <div>
                    <Button type={"submit"} id={`brd_signup_${widget.id}`}
                            style={{width: '100%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}}>{i18n.gettext('Valider')}</Button>
                </div>
            </form>

            </Loading>
        </div>
    )

}