import React, {useState} from 'react'
import {Button, Modal, Radio, Space} from "antd";
import DatePicker from "../DatePicker";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";

const {RangePicker} = DatePicker;

export default function ModalExportData({}) {

    const {t} = useTranslation()
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;
    const [accountState] = useAccountContext();

    const [visible, setVisible] = useState(false);
    const [isPeriodic, setIsPeriodic] = useState(false);
    const [dates, setDates] = useState([]);

    async function exportCsv() {
        const data = {
            accountId: accountState.account.id,
            dates: []
        }

        if (isPeriodic && typeof dates === "object" && dates.length === 2)
            data.dates = dates;

        const response = await apiPostEntity('export-bookings', data);
        dlCsv(response);
    }

    function dlCsv(response) {
        const now = new Date();
        const arr = [addZero(now.getDate()), addZero(now.getMonth() + 1), now.getFullYear()]
        const date = arr.join('-')
        const filename = 'breaking-day-' + date + '.csv'
        const link = document.createElement('a');
        link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function addZero(number) {
        if (number < 10)
            return "0" + number;

        return number;
    }

    function handleChange(e) {
        setIsPeriodic(e.target.value);
    }

    function handleDateChange(moments, string) {
        if (!string[0] && !string[1])
            return resetDates();

        setDates(string);
    }

    return (
        <div>
            <Button onClick={() => setVisible(true)}>{t('Exporter CSV')}</Button>

            <Modal
                title={t('Exporter les données de réservation')}
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={exportCsv}
            >
                <Space direction="vertical" size="large">
                    <Radio.Group onChange={handleChange} value={isPeriodic}>
                        <Radio value={false}>{t('Toutes les données')}</Radio>
                        <Radio value={true}>{t('Définir une période')}</Radio>
                    </Radio.Group>

                    <div>
                        {isPeriodic ?
                            <RangePicker
                                onChange={handleDateChange}
                                placeholder={[t('Début'), t('Fin')]}
                            />
                            : null}
                    </div>
                </Space>
            </Modal>
        </div>
    );
}