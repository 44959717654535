import React, {useState} from 'react'
import {ReactComponent as Minus} from "../svg/minus.svg";
import {ReactComponent as Add} from "../svg/add.svg";
import {useWidgetContext} from "../../providers/WidgetProvider";
import Button from "./Button";
import getSymbolFromCurrency from "currency-symbol-map";

export default function Addons({close = null}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, lng, i18n} = widgetState;
    const {bookingInfos} = widgetState;
    const {setBookingInfos} = widgetDispatch;
    const [selectedAddon, setSelectedAddon] = useState(bookingInfos.addons || [])

    const handleSelection = (addon, action) => {
        let current = [...selectedAddon]
        let amount = addon.amount
        if (action === 'add') {
            current = current.filter(item => item.id !== addon.id)
            current = [...current, {...addon, amount: ++amount}]
        } else {
            current = current.filter(item => item.id !== addon.id)
            if (amount > 1) {
                current = [...current, {...addon, amount: --amount}]
            }
        }
        setBookingInfos({...bookingInfos, addons: current})
        setSelectedAddon(current)
    }


    let addons = [...bookingInfos.offer.category.addons].sort((a, b) => a.price - b.price);

    return (
        <div>
            {
                close &&
                <h2 className="title">{i18n.gettext('Souhaiteriez - vous des services additionnels ?')}</h2>
            }
            <div style={{marginBottom: '20px'}}>
                {
                    addons.map(addon => {
                        let item = selectedAddon.find(item => item.id === addon.id) || {
                            ...addon,
                            amount: 0
                        }
                        return (
                            <div className="addon" key={addon.id}
                                 style={{border: item.amount > 0 ? `2px solid ${widget.backgroundColor || '#2494D1'}` : '2px solid transparent'}}>
                                <div className="tab-infos" style={{width: '100%'}}>
                                    <div
                                        onClick={() => handleSelection(item, 'remove')}
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            minWidth: '16px',
                                            marginRight: '16px',
                                            cursor: "pointer",
                                            color: widget.backgroundColor || '#2494D1'
                                        }}>
                                        <Minus/>
                                    </div>
                                    <div className="icon-square"
                                         style={{color: '#2494D1', fontWeight: "bold"}}>
                                        <div className="background-transparent"
                                             style={{backgroundColor: widget.backgroundColor || '#2494D1'}}/>
                                        <div style={{color: widget.backgroundColor || '#2494D1'}}>
                                            {item.amount}
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => handleSelection(item, 'add')}
                                        style={{
                                            width: '16px',
                                            height: '16px',
                                            minWidth: '16px',
                                            marginRight: '16px',
                                            cursor: "pointer",
                                            color: widget.backgroundColor || '#2494D1'
                                        }}>
                                        <Add/>
                                    </div>
                                    <div className="infos">
                                        <div className="addon-title">{item.names[lng] ? item.names[lng] : item.names[widget.account.defaultLanguage]}</div>
                                        <div
                                            style={{color: widget.backgroundColor || '#2494D1'}}
                                            title={'prix en ' + widget.account.currency}>+{item.price} {getSymbolFromCurrency(widget.account.currency)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                close &&
                <Button onClick={close}
                        style={{width: '100%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}}>
                    {i18n.gettext('Valider les services')}
                </Button>
            }

        </div>
    )
}