import React from 'react'
import {Col, PageHeader, Row} from "antd";
import {useAccountContext} from "../providers/AccountProvider";
import CategoriesDash from "../components/Categories/CategoriesDash";
import TimeSlotDash from "../components/TimeSlot/TimeSlotDash";
import AddonsDash from "../components/Addons/AddonsDash";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next";
import CategoriesTagsDash from "../components/CategoriesTags/CategoriresTagsDash";


export default function Settings() {

    const [accountState] = useAccountContext();
    const {t} = useTranslation();

    if (!accountState.account) {
        return <Redirect to={{
            pathname: "/",
            state: {status: '404', subtitle: ''}
        }}/>
    }


    return (
        <>
            <PageHeader
                title={<h1>{t('Configuration')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: "2em"}}>
                <Col className="gutter-row" span={24} lg={16} xl={12}>
                    <CategoriesDash id={accountState.account.id}/>
                </Col>
                <Col className="gutter-row" span={24} lg={16} xl={12}>
                    <TimeSlotDash id={accountState.account.id}/>
                </Col>
                <Col className="gutter-row" span={24} md={16} lg={10} xl={8}>
                    <AddonsDash/>
                </Col>
                <Col className="gutter-row" span={24} md={16} lg={10} xl={8}>
                    <CategoriesTagsDash id={accountState.account.id}/>
                </Col>
            </Row>

        </>
    )
}