import React from 'react';
import {useWidgetContext} from "../../providers/WidgetProvider";
import BigLogo from "../svg/BigLogo";
import Button from "../component/Button";
import {ReactComponent as Close} from '../svg/close.svg'

export default function BeginStep() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, lng, local, i18n} = widgetState;
    const {next, setVisible} = widgetDispatch;

    function resolveText() {

        let content = null
        if (widget.description[lng]) {
            content = widget.description[lng]
        } else if (widget.description[widget.account.defaultLanguage]) {
            content = widget.description[widget.account.defaultLanguage]
        }

        if (content) {
            return (
                <div dangerouslySetInnerHTML={{__html: content}}/>
            )
        }
        return (
            <div>
                <p style={{padding: '10px 0'}}>
                    Vous cherchez à réserver un espace en journée pour vos&nbsp;
                    <strong>réunions</strong>,&nbsp;
                    du <strong>coworking</strong>, ou tout simplement pour
                    <strong> faire une pause</strong> ?
                </p>
                <p style={{padding: '10px 0'}}>
                    En quelques étapes, réservez une chambre à l’<strong>{widget.account.name}</strong>&nbsp;
                    pour une journée entière ou juste pour une matinée ou un après-midi.
                </p>
            </div>
        )
    }

    return (
        <div
            style={{
                minHeight: '100%',
                backgroundColor: widget.backgroundColor || '#2494D1',
                color: '#fff',
                position: "relative"
            }}
        >
            {
                !local &&
                <Close className="close"
                       onClick={() => setVisible(false)}
                />
            }

            <BigLogo/>

            <div className="flex column between" style={{zIndex: 2, position: 'relative'}}>
                <div
                    style={{padding: '4em 20px'}}
                >
                    <div>
                        <small className="smaller" style={{fontSize: '10px'}}>
                            {i18n.gettext('RÉSERVATION EN JOURNÉE')}
                        </small>
                        <h1 style={{color: "white"}}>
                            Breaking Day
                        </h1>
                    </div>

                    <div style={{fontSize: 16, padding: '20px 0'}}>
                        {
                            resolveText()
                        }
                    </div>

                </div>

                <div style={{padding: 20}}>
                    <Button
                        style={{
                            width: '100%',
                            backgroundColor: "#fff",
                            color: widget.backgroundColor || '#2494D1',
                            textTransform: 'uppercase'
                        }}
                        onClick={next}
                        id={`brd_start_${widget.id}`}
                    >
                        {i18n.gettext('Commencer')}
                    </Button>
                </div>

            </div>
            <div style={{
                position: "absolute",
                bottom: '10px',
                right: '10px',
                textAlign: 'center',
                fontSize: 13
            }}
            >
                Powered by <img src="https://api.hapidam.com/share/5970996f-84f3-4a0e-86f4-58a6e0c3fd37/resaday-logo-002.png?size=th" alt="mmcreation" style={{width: 100}}/>
            </div>
        </div>
    )
}