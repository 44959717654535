import React from "react";

export default function Input({placeholder, icon, value, onChange,  type = 'text', required = false, error = false}) {

    return (
        <div className="input-container">
            {icon}
            <input type={type} className={`resaday-input ${error && 'error'}`} placeholder={`${placeholder}${required && '*'}`} value={value} onChange={onChange} required={required}/>
        </div>
    )
}