import React, {useState} from 'react';
import {Button, Space} from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import {useAccountContext} from "../../providers/AccountProvider";
import {AddonsForm} from "./AddonsForm";
import Modal from "antd/es/modal/Modal";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import {resolveNamesLanguage} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";

export default function AddonsDash({}) {

    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch
    const [accountState] = useAccountContext();
    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const [contentVersion, setContentVersion] = useState(0)
    const {t, i18n} = useTranslation();

    const columns = [
        {
            title: t('nom'),
            dataIndex: 'names',
            render: (names, record) => resolveNamesLanguage(i18n, accountState, record)
        },
        {
            title: t('prix'),
            dataIndex: "price",
            render: price => <span>{price} {getSymbolFromCurrency(accountState.account.currency)}</span>
        },
        {
            title: t('email'),
            dataIndex: 'email',
            width: '50px',
            render: (email, record) =>
                <div style={{textAlign: "right"}}>
                    <Space>
                        <EditTwoTone onClick={() => setSelectedItem(record)}/>
                        <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)}/>
                    </Space>
                </div>
        },
    ];

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null);
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: t('confirmer la suppression'),
            icon: <ExclamationCircleOutlined/>,
            content: t('ce service ne serra plus disponible pour les prochaines réservation mais les réservations en cours reste valide.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item),
        });
    }

    const deleteItem = async (item) => {
        let response = await apiDeleteEntity('addons', item.id)
        setContentVersion((new Date).getTime())
    }

    if (creationMode || selectedItem)
        return <AddonsForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <>
            <ApiCollectionLatest
                title={() => <div className="flex between">
                    <b>{t('extras')}</b>
                    <Button size="small" onClick={() => setCreationMode(true)}>+</Button>
                </div>}
                fetch_endpoint={`accounts/${accountState.account.id}/addons`}
                columns={columns}
                pageSize={5}
                contentVersion={contentVersion}
            />
        </>
    );
};
