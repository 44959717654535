import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../providers/AccountProvider";
import {Button, Input, message, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import LanguageSelect from "../Translation/LanguageSelect";

/**
 * edition d email avec appercu en temps reel
 * plusieurs type different (ex: booking, cancel)
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
export default function EditMail({type}) {

    const {t} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity, apiPostEntity, apiFetchEntity} = apiDispatch;

    const [account, setAccount] = useState({});
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('en');
    const [disabled, setDisabled] = useState(true);
    const [text, setText] = useState({})
    const [emailContent, setEmailContent] = useState("")

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        getEmail();
    }, [language])

    /**
     * on récupère les infos lié au compte
     * on défini la langue par default
     * on met le bon text qui correspond au type
     * @returns {Promise<void>}
     */
    async function fetch() {
        const response = await apiFetchEntity('accounts', accountState.account.id);
        setAccount(response);

        if (response.defaultLanguage)
            setLanguage(response.defaultLanguage);

        switch (type) {
            case 'booking':
                setText(response.bookingText);
                break;
            case 'cancel':
                setText(response.cancelText);
                break;
            default:
                break;
        }

        setLoading(false);
    }

    /**
     * on remet les données de la base dans les champs
     * @returns {Promise<void>}
     */
    async function refresh() {
        setLoading(true);
        await fetch();
        await getEmail();
        setDisabled(true);
    }

    /**
     * on update le textarea et l appercu du mail en direct
     * on enleve le disabled sur les boutons
     * @param e
     */
    function onTextChange(e) {
        let copy = {...text}
        copy[language] = e.target.value;
        setText(copy);
        editCustomText(e.target.value)
        setDisabled(false);
    }

    /**
     * on sauvegarde la nouvelle version du texte selon le type
     * @returns {Promise<void>}
     */
    async function save() {
        setLoading(true);

        let data = {};

        switch (type) {
            case 'booking':
                data.bookingText = text;
                break;
            case 'cancel':
                data.cancelText = text;
                break;
            default:
                break;
        }

        await apiUpdateEntity('accounts', account.id, data);

        message.success(t('modifications enregistrées avec succès'))
        setLoading(false);
    }

    /**
     * edite le texte dans l appercu
     * @param text
     */
    function editCustomText(text) {
        let divElt = document.getElementById(`custom-text-${type}`);
        divElt.innerText = text;
    }

    /**
     * recipere l email de l API et l injecte dans la preview
     * @returns {Promise<void>}
     */
    async function getEmail() {
        const data = {id: accountState.account.id, type: type, locale: language, debug:true}

        const response = await apiPostEntity('get-email', data)
        console.log('response', response)
        setEmailContent(response)
        // let divElt = document.getElementById(`email-preview-${type}`);
        // divElt.innerHTML = response;
        setLoading(false);
    }

    return (
        <Spin spinning={loading}>

            <div>
                <h2 style={{fontSize: 20}}>{t('Modifier le contenu du mail')}</h2>
            </div>

            <div style={{display: "flex", justifyContent: "space-around", alignItems: "flex-start"}}>
                <div style={{
                    minHeight: 300,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 20
                }}>
                    <div>
                        <LanguageSelect
                            callback={setLanguage}
                            currentLng={language}
                            account={account}
                        />
                        <Input.TextArea
                            value={text[language]}
                            onChange={onTextChange}
                            placeholder={t('Nous vous remercions pour votre réservation')}
                            style={{width: 400}}
                            rows={5}
                        />
                    </div>

                    <Space>
                        <Button
                            type="primary"
                            onClick={save}
                            disabled={disabled}
                        >
                            {t('Enregistrer')}
                        </Button>
                        <Button
                            type="danger"
                            onClick={refresh}
                            disabled={disabled}
                        >
                            {t('Annuler')}
                        </Button>
                    </Space>
                </div>
                <div id={`email-preview-${type}`} style={{maxWidth: 500, border: '1px solid #ccc', padding: 2, borderRadius: 2}} dangerouslySetInnerHTML={{__html: emailContent.replace('{{custom-text}}', text[language] || '')}}></div>
            </div>

        </Spin>
    )
}