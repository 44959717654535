import React, { useEffect, useState } from 'react';
import MyModal from "./MyModal";
import { ReactComponent as Logo } from './svg/logo.svg'

import { useWidgetContext } from "../providers/WidgetProvider";

export default function Popup({ widgetId, debug = false, local = false }) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { widget, i18n } = widgetState
    const { get, setVisible, setter, getLanguage, loadJed } = widgetDispatch;

    const [position, setPosition] = useState({});
    const [mobileAdditionalStyle, setMobileAdditionalStyle] = useState({});
    const [style, setStyle] = useState("");
    const [loaded, setLoaded] = useState(false);

    const [triggerId, setTriggerId] = useState("");
    const [text, setText] = useState("");

    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        if (triggerId) {
            if (!debug) {
                let element = document.getElementById(triggerId);
                if (element) {
                    element.addEventListener('click', () => {
                        setVisible(true);
                    })
                }

            }
        }

        return () => {
            if (triggerId && !debug) {
                let element = document.getElementById(triggerId);
                if (element) {
                    element.removeEventListener('click', () => {
                        setVisible(true);
                    })
                }
            }
        }
    }, [triggerId])

    useEffect(() => {
        setter('debug', debug);
        setter('local', local);
        fetch();
    }, [])

    async function fetch() {
        let currentLng = getLanguage();
        loadJed(currentLng)
        const response = await get(`widgets/${widgetId}`);
        setWidgetPosition(response);
        setter('widget', response);
        setter('accountId', response.id);
        setter('lng', currentLng);
        setStyle(`.__resaday_modal__ {--primary-color: ${response.backgroundColor || '#2494D1'} ;}`)
        setText(resolveText(currentLng, response))
        if (response.isTrigger)
            setTriggerId(response.triggerId);

        if (local) {
            setVisible(true)
        }
    }

    function setWidgetPosition(response) {
        const enableMobile = isMobile && response.enableMobileStyle;

        const position = enableMobile ? response.mobilePosition : response.position;
        const absolutePosition = enableMobile ? response.mobileAbsolutePosition : response.absolutePosition;

        if (enableMobile)
            setMobileAdditionalStyle(response.mobileAdditionalStyle);

        const [y, x] = position.split('_');
        const yValue = absolutePosition.top || absolutePosition.bottom;

        setPosition({ [x]: -200, [y]: yValue });
        setLoaded(true);
        setTimeout(() => setPosition(absolutePosition), 1000);
    }

    const title = {
        fr: 'Réserver en journée',
        en: "Book during the day",
        es: 'Reservar durante el dia'
    }

    function resolveText(currentLng, widgetData) {
        if (widgetData.textButton?.[currentLng]) {
            return widgetData.textButton[currentLng]
        }

        if (widgetData.textButton?.[widgetData.account.defaultLanguage]) {
            return widgetData.textButton[widgetData.account.defaultLanguage]
        }

        return title[currentLng];
    }

    return (
        <>
            <style>{style}</style>
            {!widget.isTrigger || debug ?
                <div
                    id={`brd_widget_${widget.id}`}
                    onClick={() => setVisible(true)}
                    className="__resaday_open_modal_btn__"
                    style={{
                        display: (widgetState.visible || !loaded) ? 'none' : 'block',
                        backgroundColor: '#fff',
                        color: widget.backgroundColor || '#2494D1',
                        ...mobileAdditionalStyle,
                        ...position
                    }}
                >
                    <div>
                        {widget.icon ?
                            <div
                                className="icon-widget-btn"
                                style={{
                                    fill: widget.backgroundColor || '#2494D1'
                                }}
                                dangerouslySetInnerHTML={{ __html: widget.icon }}
                            /> :
                            <Logo style={{
                                width: '40px',
                                height: '40px',
                                color: widget.backgroundColor || '#2494D1',
                            }} />}
                    </div>

                    <div className="brd_title">
                        {text}
                    </div>
                </div> : null}

            <MyModal />
        </>
    )
}