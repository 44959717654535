import React from 'react'
import {PageHeader} from "antd";
import PriceTable from "../components/Prices/PriceTable";
import {useTranslation} from "react-i18next";

export default function Prices() {
    const {t} = useTranslation();

    return (
        <>
            <PageHeader
                title={<h1>{t('Prix')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            <PriceTable/>
        </>
    )
}

