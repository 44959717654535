import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Button} from 'antd';
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";



export function TranslationDropdown() {

    const {t, i18n} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [languages, setLanguages] = useState([]);

    useEffect(()=> {
        fetchLanguages()

    }, [])

    const fetchLanguages = () => {
        apiFetchCollection('translations', {}, response => {
            console.log(response);
            setLanguages(response['hydra:member'])
        })
    }

    const menu = (
        <Menu>
            {
                languages.length > 0 ?
                    languages.map(item =>
                        <Menu.Item key={"lng/"+item.lng+item.type} onClick={() => i18n.changeLanguage(item.lng)}>
                            {item.lng} - {item.type}
                        </Menu.Item>
                    )
                    : null
            }
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
            <Button>
                {i18n.language.toUpperCase()}
            </Button>
        </Dropdown>
    )

}
