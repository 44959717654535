import React from "react";
import {ReactComponent as Wave} from '../images/waves/autonomie.svg';
import image from '../images/autonomie-pic.png'

export default function Autonomy() {
    return (
        <div className="autonomy-container">

            <div className="first-block">

                <div style={{width: 300, margin: "auto"}}>
                    <div><h1>Autonomie</h1></div>

                    <div className="divider"/>

                    <p>
                        Breaking Day est un widget<br/>
                        compatible avec tous les sites<br/>
                        Internet et que vous contrôlez<br/>
                        entièrement, ce qui vous assure<br/>
                        une gestion totale de votre<br/>
                        système de réservation en<br/>
                        journée.
                    </p>
                </div>

            </div>

            <div className="second-block"><img src={image} alt=""/></div>

            <Wave/>
        </div>
    )
}