import React, {useState} from "react";
import {Badge, Button, Form, Input, Modal, PageHeader} from "antd";
import {useAuthContext} from "../providers/AuthProvider";
import {useApiContext} from "../providers/ApiProvider";
import CollectionTable from "../components/CollectionTable";
import dayjs from "dayjs";
import {CommentOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import CommentDrawer from "../components/Drawer/CommentDrawer";
import {useTranslation} from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";

const {confirm} = Modal;

export default function ClientHome() {

    const {t} = useTranslation();
    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity} = apiDispatch;

    const [loading, setLoading] = useState(0);
    const [needUpdate, setNeedUpdate] = useState(0);

    const [visible, setVisible] = useState(false);
    const [bookingSelected, setBookingSelected] = useState({});

    const [form] = Form.useForm();

    const initialFilters = [
        {name: 'order[bookAt]', value: 'desc'}
    ];
    const [filters, setFilters] = useState(initialFilters);

    const columns = [
        {
            title: t('hôtel'),
            dataIndex: 'account',
            render: account => <small>{account.name}</small>
        },
        {
            title: t('service'),
            dataIndex: 'categoryName',
            render: categoryName => <small>{categoryName}</small>
        },
        {
            title: t('horaires'),
            dataIndex: 'timeSlotName',
            render: timeSlotName => <small>{timeSlotName}</small>
        },
        {
            title: t('extras'),
            dataIndex: 'addons',
            render: (addons, record) => <small>
                {addons && addons.length ? addons.map(addon => (
                    <p key={`${record.id}_${addon.name}`}>
                        <span>{addon.amount} x {addon.name} {addon.price} {getSymbolFromCurrency(record.currency)}</span><br/>
                    </p>
                )) : '-'}
            </small>
        },
        {
            title: t('n° de réservation'),
            dataIndex: 'bookingId',
            render: bookingId => <small>{bookingId}</small>
        },
        {
            title: t('statut'),
            dataIndex: 'status',
            render: status => <small>{status}</small>
        },
        {
            title: t('reservé pour le'),
            dataIndex: 'bookAt',
            render: bookAt => <small>{dayjs(bookAt).format('DD/MM/YYYY')}</small>
        },
        {
            title: t('heure d\'arrivée'),
            dataIndex: 'arrivedAt',
            render: arrivedAt => <small>{arrivedAt || '-'}</small>
        },
        {
            title: t('prix'),
            dataIndex: 'price',
            render: (price, record) => <small>{price} {getSymbolFromCurrency(record.currency)}</small>
        },
        {
            title: t('actions'),
            dataIndex: 'id',
            render: (id, record) => (
                <Button
                    type="danger"
                    onClick={() => showConfirm(id)}
                    loading={loading === id}
                    disabled={record.status === 'canceled'}
                >
                    {t('annuler')}
                </Button>
            )
        },
        {
            title: t('commentaires'),
            dataIndex: 'comment',
            render: (comment, record) => (
                <Badge dot={comment && comment.length}>
                    <Button
                        icon={<CommentOutlined/>}
                        onClick={() => openDrawer(record)}
                    />
                </Badge>
            )
        }
    ];

    function openDrawer(record) {
        setBookingSelected({...record});
        setVisible(true);
    }

    function showConfirm(id) {
        confirm({
            title: t('Êtes vous sûrs de vouloir annuler cette reservation ?'),
            icon: <ExclamationCircleOutlined/>,
            okText: t('Valider'),
            cancelText: t('Retour'),
            content: (
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label={t('Commentaire')}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                </Form>

            ),
            onOk() {
                cancel(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function cancel(id) {
        setLoading(id);
        const formData = form.getFieldsValue();
        let data = {status: 'canceled'};

        if (formData.comment) {
            data.comment = formData.comment;
            form.resetFields();
        }

        const response = await apiUpdateEntity('bookings', id, data);

        setNeedUpdate(needUpdate + 1);
        setLoading(0);
    }

    return (
        <div>
            <PageHeader
                title={(<h1>Bonjour {auth.username}</h1>)}
                style={{borderBottom: '2px solid #2494D1'}}
            />

            <div>
                <CollectionTable
                    endpoint={`users/${auth.id}/bookings`}
                    columns={columns}
                    reload={needUpdate}
                    filters={filters}
                />
            </div>

            <CommentDrawer
                visible={visible}
                setVisible={setVisible}
                booking={bookingSelected}
                canEdit
                reload={() => setNeedUpdate(needUpdate + 1)}
            />
        </div>
    )
}