import React from 'react'
import {ReactComponent as Minus} from "../../widget/svg/minus.svg";
import {ReactComponent as Add} from "../../widget/svg/add.svg";
import {useTranslation} from "react-i18next";
import NbPeople from "./NbPeople";
import {Input} from 'antd'

export default function Services({
                                     addons,
                                     selectedAddons,
                                     setSelectedAddons,
                                     comment,
                                     setComment,
                                     account,
                                     category,
                                     nbPeople,
                                     setNbPeople
                                 }) {

    const {t, i18n} = useTranslation();

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const handleSelection = (addon, action) => {

        let current = [...selectedAddons]
        let amount = addon.amount
        if (action === 'add') {
            current = current.filter(item => item.id !== addon.id)
            current = [...current, {...addon, amount: ++amount}]
        } else {
            current = current.filter(item => item.id !== addon.id)
            if (amount > 1) {
                current = [...current, {...addon, amount: --amount}]
            }
        }
        setSelectedAddons(current)
    }

    return (
        <div>
            {
                category?.isLimited && <NbPeople range={category?.rangePeople} nbPeople={nbPeople} setNbPeople={setNbPeople}/>
            }

            {
                !!addons.length &&
                <div className="confirm-tab">
                    <div className="confirm-tab-title">
                        <div className="background-transparent"
                             style={{backgroundColor: '#2494D1'}}/>
                        {t('Services Additionnels')} :
                    </div>
                    <div className="confirm-tab-content">
                        <div style={{marginBottom: '20px'}}>
                            {
                                addons.map(addon => {
                                    let item = selectedAddons.find(item => item.id === addon.id) || {
                                        ...addon,
                                        amount: 0
                                    }
                                    return (
                                        <div className="addon" key={addon.id}
                                             style={{
                                                 border: item.amount > 0 ? `2px solid #2494D1` : '2px solid transparent',
                                                 backgroundColor: 'rgb(245, 245, 245)'
                                             }}>
                                            <div className="tab-infos" style={{width: '100%'}}>
                                                <div
                                                    onClick={() => handleSelection(item, 'remove')}
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '16px',
                                                        cursor: "pointer",
                                                        color: '#2494D1'
                                                    }}>
                                                    <Minus/>
                                                </div>
                                                <div className="icon-square"
                                                     style={{color: '#2494D1', fontWeight: "bold"}}>
                                                    <div className="background-transparent"
                                                         style={{backgroundColor: '#2494D1'}}/>
                                                    <div style={{color: '#2494D1'}}>
                                                        {item.amount}
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() => handleSelection(item, 'add')}
                                                    style={{
                                                        width: '16px',
                                                        height: '16px',
                                                        marginRight: '16px',
                                                        cursor: "pointer",
                                                        color: '#2494D1'
                                                    }}>
                                                    <Add/>
                                                </div>
                                                <div className="infos">
                                                    <div
                                                        className="addon-title">{item.names[i18n.language] ? item.names[i18n.language] : item.names[account.defaultLanguage]}</div>
                                                    <div
                                                        style={{color: '#2494D1'}}>+{item.price} {account.currency}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            <div className="confirm-tab" style={{marginTop: '28px'}}>
                <div className="confirm-tab-title">
                    <div className="background-transparent"
                         style={{backgroundColor: '#2494D1'}}/>
                    {t('Avez-vous des demandes particulières ?')}
                </div>
                <div className="confirm-tab-content">
                    <Input.TextArea onChange={handleChange} value={comment} />
                </div>
            </div>

        </div>
    )
}