import React, {useEffect, useState} from 'react'
import CollectionTable from "../CollectionTable";
import dayjs from "dayjs";
import {Badge, Button, Input, Modal, Select, Space, Tooltip} from "antd";
import DatePicker from "../DatePicker";
import {CommentOutlined, DeleteTwoTone, ExclamationCircleOutlined, WarningOutlined} from "@ant-design/icons";
import CommentDrawer from "../Drawer/CommentDrawer";
import {useTranslation} from "react-i18next";
import DropdownStatus from "./DropdownStatus";
import ModalExportData from "../Modal/ModalExportData";
import {displayTime} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";

const {confirm} = Modal;
const {RangePicker} = DatePicker;

export default function ReservationList({id, needUpdate}) {

    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const {t, i18n} = useTranslation()
    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apitDispatch] = useApiContext();
    const {apiDeleteEntity} = apitDispatch

    const [contentVersion, setContentVersion] = useState(0)

    const initialFilters = [{name: 'bookAt[after]', value: dayjs().format('YYYY-MM-DD')}];
    const [filters, setFilters] = useState(initialFilters);

    const [visible, setVisible] = useState(false);
    const [bookingSelected, setBookingSelected] = useState({});

    useEffect(() => {
        setContentVersion(needUpdate)
    }, [needUpdate])

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }

    const columns = [
        {
            title: t('client'),
            dataIndex: 'firstname',
            render: (firstname, record) => <>
                <div>
                    {firstname} {record.lastname}
                </div>
                {
                    !record.isConfidential && <div><small>{record.email} - {record.phone} </small></div>
                }
            </>
        },
        {
            title: t('service'),
            dataIndex: 'categoryName',
            render: categoryName => <small>{categoryName}</small>
        },
        {
            title: `${('Date')} / ${t('horaire')}`,
            dataIndex: 'bookAt',
            render: (bookAt, record) => <span>
                {dayjs(bookAt).format('DD/MM/YYYY')} <br/>
                <small>{displayTime(i18n.language, record.timeSlotName.split('-')[0])}-{displayTime(i18n.language, record.timeSlotName.split('-')[1])}</small>
            </span>
        },
        {
            title: t('heure d\'arrivée'),
            dataIndex: 'arrivedAt',
            render: arrivedAt => arrivedAt ? displayTime(i18n.language, arrivedAt) : '-'
        },
        {
            title: t('nombre de personnes'),
            dataIndex: 'nbPeople',
            render: nbPeople => nbPeople || '-'
        },
        {
            title: t('extras'),
            dataIndex: 'addons',
            render: (addons, record) => <small>
                {addons && addons.length ? addons.map(addon => (
                    <p key={`${record.id}_${addon.name}`}>
                        <span>{addon.amount} x {addon.name} {addon.price} {getSymbolFromCurrency(record.currency)}</span><br/>
                    </p>
                )) : t('aucun service')}
            </small>
        },
        {
            title: t('statut'),
            dataIndex: 'status',
            render: (status, record) => (
                <DropdownStatus
                    infos={{name: status, id: record.id}}
                    reload={() => setContentVersion(new Date())}
                />
            )
        },
        {
            title: t('N° et heure de réservation'),
            dataIndex: 'bookingId',
            render: (bookingId, record) => (
                <span>
                    <span>{bookingId}</span><br/>
                    <small>{dayjs(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
                </span>)
        },
        {
            title: t('Reserver sur'),
            dataIndex: 'bookOn',
            render: bookOn => bookOn
        },
        {
            title: t('prix'),
            dataIndex: 'price',
            render: (price, record) => <small>{price} {getSymbolFromCurrency(record.currency)}</small>
        },
        {
            title: t('commentaires'),
            dataIndex: 'comment',
            render: (comment, record) => (
                <Space size="large">
                    <Badge dot={comment && comment.length}>
                        <Button
                            icon={<CommentOutlined/>}
                            onClick={() => openDrawer(record)}
                        />
                    </Badge>
                    {
                        !record.offer && record.status !== 'canceled' &&
                        <Tooltip
                            title="Cette réservation était lié aux anciens horaires, une action de votre part est peut être nécessaire"
                            color="#FF9900">
                            <WarningOutlined style={{color: "#FF9900"}}/>
                        </Tooltip>
                    }
                    {auth.roles.includes('ROLE_SUPER_ADMIN') ?
                        <DeleteTwoTone twoToneColor="red" onClick={() => showConfirm(record.id)}/> : null}
                </Space>
            )
        }
    ];

    function showConfirm(id) {
        confirm({
            title: t('Êtes-vous sûre de vouloir supprimer cette réservation ?'),
            icon: <ExclamationCircleOutlined/>,
            content: t('Cette réservation ne pourra être récuperé après sa suppression.'),
            okText: t('Supprimer'),
            okType: 'danger',
            cancelText: t('Annuler'),
            onOk() {
                deleteBooking(id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    async function deleteBooking(id) {
        await apiDeleteEntity('bookings', id);
        setContentVersion(new Date());
    }


    function openDrawer(record) {
        setBookingSelected(record);
        setVisible(true);
    }

    function updateArrayObject(array, key, value) {
        let index = array.findIndex(filter => filter.name === key);

        if (!value && index !== -1)
            return removeArrayElement(array, key);

        if (index !== -1)
            array[index].value = value;
        else
            array.push({name: key, value: value});

        return array;
    }

    function removeArrayElement(array, key) {
        const index = array.findIndex(filter => filter.name === key);

        if (index !== -1)
            array.splice(index, 1);

        return array;
    }

    function handleFilter(key, value) {
        let copy = [...filters];

        copy = updateArrayObject(copy, key, value);

        setFilters(copy);
    }

    function resetDates() {
        let copy = [...filters];

        copy = updateArrayObject(copy, 'bookAt[after]', dayjs().format('YYYY-MM-DD'));
        copy = removeArrayElement(copy, 'bookAt[before]');

        setFilters(copy);
    }

    function handleDateChange(moments, string) {
        if (!string[0] && !string[1])
            return resetDates();

        let copy = [...filters];

        copy = updateArrayObject(copy, 'bookAt[after]', string[0]);
        copy = updateArrayObject(copy, 'bookAt[before]', string[1]);

        setFilters(copy);
    }

    const options = [
        {name: 'Booked', value: 'booked'},
        {name: 'Confirmed', value: 'confirmed'},
        {name: 'No show', value: 'noShow'},
        {name: 'Canceled', value: 'canceled'},
    ]

    return (
        <div>
            <div style={{marginBottom: 20}} className="flex between">
                <div>
                    <Input
                        className="ml-10"
                        style={{width: 250}}
                        onChange={e => handleFilter('lastname', e.target.value)}
                        placeholder={t('nom')}
                        allowClear
                    />

                    <Input
                        style={{width: 200}}
                        className="ml-10"
                        onChange={e => handleFilter('categoryName', e.target.value)}
                        placeholder={t("service")}
                        allowClear
                    />

                    <RangePicker
                        className="ml-10"
                        onChange={handleDateChange}
                        placeholder={[t('Date d\'arrivée'), t('Date de départ')]}
                    />

                    <Select
                        className="ml-10"
                        style={{width: 250}}
                        placeholder={t('statut')}
                        onChange={value => handleFilter('status', value)}
                        allowClear
                    >
                        {options.map((option, id) => (
                            <Select.Option key={id} value={option.value}>{option.name}</Select.Option>
                        ))}
                    </Select>
                </div>

                <div>
                    <ModalExportData/>
                </div>
            </div>

            <CollectionTable
                endpoint={`accounts/${id}/bookings`}
                columns={columns}
                onClick={(item) => setSelectedItem({...item})}
                reload={contentVersion}
                filters={filters}
            />

            <CommentDrawer visible={visible} setVisible={setVisible} booking={bookingSelected}/>
            {/*{selectedItem ? <ContactDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}*/}
        </div>
    );
}