import React, {useEffect, useState} from 'react'
import {Button, Input, message, Modal, PageHeader, Space, Spin} from "antd";
import {CloseCircleTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import ServiceForm from "./ServiceForm";
import ServiceCard from "./ServiceCard";
import {useTranslation} from "react-i18next";
import MyPagination from "../MyPagination";


export default function ServiceList() {

    const [needUpdate, setNeedUpdate] = useState(0)
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity, apiFetchCollection, apiPostEntity} = apiDispatch;
    const [filters, setFilters] = useState([{name: "name", value: ""}])
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [selectedService, setSelectedService] = useState(null)
    const [services, setServices] = useState([])
    const [searchInterval, setSearchInterval] = useState(null)
    const [loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1)
    const {t, i18n} = useTranslation();

    useEffect(() => {
        fetchService()
    }, [needUpdate, filters, page])

    async function fetchService() {
        setLoading(true)
        const params = {
            page: page,
            itemsPerPage: 30,
            filters: [...filters]
        };
        let response = await apiFetchCollection('services', params)
        setServices(response['hydra:member'])
        setTotalPage(response['hydra:totalItems']);
        setLoading(false)
    }

    async function deleteItem(id) {
        let response = await apiDeleteEntity('services', id)
        setNeedUpdate((new Date()).getTime())
    }


    function editService(service) {
        setSelectedService(service)
        setIsCreateVisible(true)
    }


    const showConfirm = (e, item) => {
        e.stopPropagation()
        Modal.confirm({
            title: `${t('supprimer')} ${item.name}`,
            icon: <ExclamationCircleOutlined/>,
            content: t('ce service ne sera plus visible sur les catégories liées.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item.id),
        });
    }


    function handleSearch(e) {
        clearTimeout(searchInterval)
        // console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setPage(1)
            setFilters([{name: 'name', value: e.target.value.trim()}])
        }, 200))

    }

    const handleCreationFormClose = () => {
        setIsCreateVisible(false);
        setSelectedService(null)
        setNeedUpdate((new Date).getTime())
    }

    const translateAll = async () => {
        setLoading(true)
        let response = await apiPostEntity('translate/services/')

        if (response.errors.length) {
            message.warning(t('Certains services n\'ont pas été traduit'))
        } else {
            message.success(t('Service traduit avec succes'))
        }
        setLoading(false)
    }

    return (
        <>
            <PageHeader
                title={t('Equipements')}
                extra={[
                    <Input onChange={handleSearch} style={{width: '200px'}} placeholder={t("rechercher par nom")}
                           key="user_search"/>,
                    <Button type="primary"
                            key="service_create"
                            onClick={() => setIsCreateVisible(true)}>{t('créer')}</Button>,
                    <Button type="primary"
                            loading={loading}
                            key="service_translate"
                            title={t('traduire tous les services dans les langues manquantes')}
                            onClick={translateAll}>{t('traduire')}</Button>,
                ]}
            />
            <Spin spinning={loading} style={{minHeight: '200px'}}>
                <Space wrap>
                    {
                        services.map(item =>
                            <div className="pointer"
                                 style={{position: "relative"}}
                                 key={item.id}
                                 onClick={() => editService(item)}>
                                <CloseCircleTwoTone
                                    style={{position: 'absolute', right: 5, top: 5, zIndex: 1000, cursor: 'pointer'}}
                                    onClick={(e) => showConfirm(e, item)}
                                />
                                <ServiceCard service={item} i18n={i18n}/>
                            </div>)
                    }
                </Space>
                <MyPagination
                    current={page}
                    totalItem={totalPage}
                    itemPerPage={30}
                    changePage={setPage}
                    margin={'1rem'}
                    bgColor={'none'}
                />
            </Spin>
            {
                (selectedService || isCreateVisible) &&
                <ServiceForm onClose={handleCreationFormClose} isVisible={isCreateVisible} modal
                             service={selectedService}/>
            }
        </>
    )

}