import React, { useState } from 'react'
import { Button, Input, message, Modal, PageHeader, Space, Switch, Tag } from "antd";
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import CollectionTable from "../CollectionTable";
import { useApiContext } from "../../providers/ApiProvider";
import { useHistory } from "react-router-dom";
import { useAccountContext } from "../../providers/AccountProvider";
import AccountPermissions from "./AccountPermissions";
import AccountForm from "./AccountForm";
import { useTranslation } from "react-i18next";

export default function AccountList() {

    const [needUpdate, setNeedUpdate] = useState(0)
    const [apiDispatch] = useApiContext();
    const { apiDeleteEntity, apiUpdateEntity, apiFetchEntity } = apiDispatch;
    const [filters, setFilters] = useState([{ name: "name", value: "" }])
    const [accountState, accountDispatch] = useAccountContext();
    const { accountSet } = accountDispatch;
    const history = useHistory();
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [showPermission, setShowPermission] = useState(null)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [searchInterval, setSearchInterval] = useState(null)
    const { t } = useTranslation();


    async function deleteItem(id) {
        let response = await apiDeleteEntity('accounts', id)
        setNeedUpdate((new Date()).getTime())
    }

    function showAccountPermission(account) {
        setShowPermission(account)
    }

    function editAccount(account) {
        setSelectedAccount(account)
        setIsCreateVisible(true)
    }


    function handleClick(account) {
        accountSet(account);
        history.push('/dashboard');
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: `${t('supprimer')} ${item.name}`,
            icon: <ExclamationCircleOutlined />,
            content: t('ce compte ne sera plus accessible.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item.id),
        });
    }
    const [switchTimeout, setSwitchTimeout] = useState(null)
    const [switchLoading, setSwitchLoading] = useState(false)

    async function onChange(value, id) {
        clearTimeout(switchTimeout)

        setSwitchTimeout(setTimeout(async () => {
            setSwitchLoading(true)
            let data = {
                isPremium: value,
                freemiumStartDate: value ? null : new Date()
            }
            let response = await apiUpdateEntity('accounts', id, data)

            if (response['@type'] === 'hydra:Error') {
                message.error(t('il y a eu un soucis'))
                setNeedUpdate((new Date()).getTime())
            }
            setSwitchLoading(false)
        }, 300))
    }
    async function toggleArchive(value, id) {
        setSwitchLoading(true)
        let data = { isArchived: value }
        let response = await apiUpdateEntity('accounts', id, data)

        if (response['@type'] === 'hydra:Error') {
            message.error(t('il y a eu un soucis'))
            setNeedUpdate((new Date()).getTime())
        }
        setSwitchLoading(false)
    }

    const columns = [
        {
            title: t('nom du compte'),
            dataIndex: "name",
            fixed: 'left',
            render: (name, record) => (
                <div>
                    <span onClick={() => handleClick(record)} style={{ cursor: 'pointer' }}>{name}</span>
                    <br />
                    <small>{record.url}</small>
                </div>
            )
        },
        {
            title: t('catégories'),
            dataIndex: "categories",
            render: (categories) => (
                <span>
                    {categories.length}
                </span>)
        },
        {
            title: t('horaires'),
            dataIndex: "timeSlots",
            render: (timeSlots) => (
                <span>
                    {timeSlots.length}
                </span>)
        },
        {
            title: t('premium'),
            dataIndex: "isPremium",
            render: (isPremium, record) => (
                <Space>
                    <Switch defaultChecked={isPremium} size="small"
                        onChange={(value) => onChange(value, record.id)} />
                    {/*{*/}
                    {/*    !isPremium && record.freemiumStartDate &&*/}
                    {/*        <p>{dayjs(record.freemiumStartDate).format('DD-MM-YYYY')}</p>*/}
                    {/*}*/}
                </Space>)
        },
        {
            title: t('archiver'),
            dataIndex: "isArchived",
            render: (isArchived, record) => (
                <Space>
                    <Switch defaultChecked={isArchived} size="small"
                        onChange={(value) => toggleArchive(value, record.id)} />
                </Space>)
        },
        {
            title: t('actions'),
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <Tag onClick={() => showAccountPermission(record)} className="pointer">{t('permissions')}</Tag>
                    <EditTwoTone onClick={() => editAccount(record)} />
                    <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)} />
                </Space>
            )
        }
    ]


    function handleSearch(e) {
        clearTimeout(searchInterval)
        console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setFilters([{ name: 'name', value: e.target.value.trim() }])
        }, 500))

    }

    function handleCreate() {
        setSelectedAccount(null)
        setIsCreateVisible(false)
        setNeedUpdate((new Date()).getTime())
    }

    async function dlCsv() {
        let response = await apiFetchEntity('export-account-csv', '')

        const now = new Date();
        const date = `${now.getDate().toString().padStart(2, 0)}-${now.getMonth().toString().padStart(2, 0)}-${now.getFullYear()}`;
        const filename = `breaking-day_export-account-${date}.csv`
        const link = document.createElement('a');
        link.href = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(response);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <PageHeader
                title={t("comptes")}
                extra={[
                    <Input onChange={handleSearch} style={{ width: '200px' }} placeholder={t("rechercher par nom")}
                        key="user_search" />,
                    <Button type="primary"
                        key="account_create"
                        onClick={() => setIsCreateVisible(true)}>{t('créer')}</Button>,
                    <Button
                        key="accounts_export"
                        onClick={dlCsv}>Export</Button>,
                ]}

            />

            <CollectionTable columns={columns} filters={filters} endpoint={"accounts"} reload={needUpdate} />

            {
                showPermission &&
                <AccountPermissions account={showPermission} setAccount={setShowPermission} modal />
            }
            <AccountForm callback={handleCreate} isVisible={isCreateVisible} modal account={selectedAccount} />
        </>
    )

}