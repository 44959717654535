import React from 'react';
import {Route} from 'react-router-dom';
import {useAuthContext} from '../../providers/AuthProvider.js';
import ClientLayout from "../Layout/Client/ClientLayout";

const ClientRoute = ({component: Component, sidebar, searchbar,   ...rest}) => {

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const {authIsLogged, authLogout} = authDispatch;

    const renderer = (sidebar,props) => {
        if (authIsLogged())
            return <ClientLayout {...props}><Component {...props} /></ClientLayout>;

        authLogout();
        window.location.href = '/login';
    }

    return <Route {...rest} render={renderer.bind(null,sidebar)} />;
};

export default ClientRoute;
