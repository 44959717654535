import React, {useEffect, useRef, useState} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import {Button, Checkbox, Descriptions, Form, Input, message, PageHeader, Select, Space, Spin, Tag} from "antd";
import {useApiContext} from "../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import GooglePlaceAutoComplete from "../components/GooglePlaceAutoComplete";
import ReactCountryFlag from "react-country-flag";
import cc from 'currency-codes'
import getSymbolFromCurrency from 'currency-symbol-map'
import {CheckCircleTwoTone} from '@ant-design/icons';

const countriesData = cc.data
const baseLanguage =
    {
        label: (<ReactCountryFlag
            countryCode='GB'
            svg
            title="anglais"
        />),
        value: 'en',
    }

export default function AccountSettings() {

    const [accountState, accountDispatch] = useAccountContext();
    const {accountSet} = accountDispatch;
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity, apiFetchEntity, apiFetchCollection} = apiDispatch;
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [hapidamClient, setHapidamClient] = useState(null);
    const [hapidamIsConnect, setHapidamIsConnect] = useState(false);

    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState({});
    const [loadingStart, setLoadingStart] = useState(true);

    const [locations, setLocations] = useState(account.locations || []);
    const [locationUpdated, setLocationUpdated] = useState(0)

    const [lngOptions, setLngOptions] = useState(account.widgetLanguages ? account.widgetLanguages : [{...baseLanguage}])
    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        fetchLng()
        return () => {
            isMounted.current = false;
        }
    }, [])

    const fetchLng = async () => {
        setLoading(true)
        let params = {
            filters: [{name: 'type', value: 'admin'}]
        }
        let response = await apiFetchCollection('translations', params)
        if (isMounted.current) {
            if (response['@type'] !== 'hydra:Error') {
                setLngOptions(parseLngs(response['hydra:member']))
            }
        }
        setLoading(false)
    }

    function parseLngs(lngs) {
        let result = [{...baseLanguage}]

        lngs.map(lng => {
            if (lng.lng !== 'en') {
                result.push({
                    label: (<ReactCountryFlag
                        countryCode={lng.lng}
                        svg
                        title={lng.lng}
                    />),
                    value: lng.lng,
                    disabled: false
                })
            }
        })
        return result
    }

    useEffect(() => {
        if (locationUpdated !== 0)
            handleChange()
    }, [locationUpdated])

    useEffect(() => {
        setHapidamClient(new Hapidam())
        fetch()
    }, [])

    function connect() {
        hapidamClient.on('select', handleResponse);
        hapidamClient.connect()
    }

    function handleResponse(response) {
        hapidamClient.off('select', handleResponse);
        form.setFieldsValue({hapidamKey: response.token})
        setHapidamIsConnect(true);
    }

    async function fetch() {
        const response = await apiFetchEntity('accounts', accountState.account.id)
        setAccount(response);

        if (response.hapidamKey)
            setHapidamIsConnect(true);

        setLocations(response.locations || []);
        setLoadingStart(false);
    }

    const onFinish = (values) => {
        updateAccount(values);
    };

    const updateAccount = async (values) => {
        setLoading(true);

        if (values.emails.length)
            values.emails = values.emails.map(email => email.trim());

        values.locations = locations;
        values.defaultLanguage = values.defaultLanguage.value;
        values.widgetLanguages = values.widgetLanguages.includes(values.defaultLanguage) ? values.widgetLanguages : [...values.widgetLanguages, values.defaultLanguage];

        let response = await apiUpdateEntity('accounts', account.id, values);

        setAccount(response);
        delete response.hapidamKey;
        delete response.bookingText;
        delete response.cancelText;
        accountSet(response);

        form.setFieldsValue({'widgetLanguages': values.widgetLanguages})
        message.success(t('modifications enregistrées avec succès'))
        setLoading(false);
    }


    function handleChange() {
        form.setFieldsValue({locations: locations})
    }

    function handleDeSelect(value) {
        setLocations(previousLocations => [...previousLocations.filter(location => location.place_id !== value)])
        setLocationUpdated((new Date()).getTime())
    }

    const [currencyOptions, setCurrencyOptions] = useState([])
    const [fetching, setFetching] = useState(false)

    async function fetchLocation(value) {
        setFetching(true)
        let match = []
        await countriesData.map(country => country.currency.toLowerCase().includes(value.toLowerCase())
        && !country.currency.toLowerCase().includes('bond') ? match.push(country) : null)
        setCurrencyOptions(match)
        setFetching(false)
    }

    return (
        <div>
            <PageHeader
                onBack={() => window.history.back()}
                title={<h1>{accountState.account.name} ({t('réglages')})</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            {
                !loadingStart && !loading &&
                <Form
                    className="mt-20"
                    form={form}
                    layout="vertical"
                    initialValues={{
                        hapidamKey: account.hapidamKey,
                        emails: account.emails,
                        location: account.locations || [],
                        widgetLanguages: account.widgetLanguages || ['en'],
                        defaultLanguage: lngOptions.find(opt => opt.value === account.defaultLanguage) || baseLanguage,
                        currency: account?.currency ? account.currency : 'EUR',
                        url: account?.url ? account.url : null
                    }}
                    onFinish={onFinish}
                >

                    <Descriptions title={t('général')}>
                        <Descriptions.Item>
                            <Form.Item
                                name="emails"
                                label={t("Emails")}
                                tooltip={t('adresse mail pour recevoir les réservations')}
                            >
                                <Select
                                    mode="tags"
                                    style={{width: 300}}
                                    placeholder="emails"
                                >
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item>
                            <Form.Item label="Hapidam api key" name={"hapidamKey"}
                                       extra={hapidamIsConnect &&
                                       <div>
                                           <CheckCircleTwoTone twoToneColor="#52c41a"/> {t('Connecté a HapiDam')}
                                       </div>}
                                       tooltip={t('ajouter une clé api privé Hapidam')}>
                                <Input.Search enterButton="Modifier" onSearch={connect}
                                              placeholder="ex : 2695de9-95de31-ed56e9d" style={{width: '300px'}}/>
                            </Form.Item>
                        </Descriptions.Item>

                        <Descriptions.Item>
                            <Form.Item label={t("Limiter l'affichage géographiquement")} name={"location"}
                                       tooltip={t('le widget ne s\'affichera que si l\'utilisateur correspond à tous les critères')}>
                                <Space direction="vertical">
                                    <GooglePlaceAutoComplete displayValues={false} setValues={setLocations}
                                                             values={locations}
                                                             setLocationUpdated={setLocationUpdated}/>
                                    <Space className="wrap">
                                        {
                                            locations.map(location =>
                                                <Tag closable
                                                     onClose={() => handleDeSelect(location.place_id, setLocations)}
                                                     key={`tag_${location.place_id}`}>{location.name}</Tag>
                                            )
                                        }
                                    </Space>
                                </Space>
                            </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <Form.Item label={t("Devise")} name={"currency"}
                                       tooltip={t('le widget ne s\'affichera que si l\'utilisateur correspond à tous les critères')}>
                                <Select
                                    showSearch
                                    placeholder={t("Entrer le nom d'une devise")}
                                    notFoundContent={fetching ? <Spin size="small"/> : null}
                                    filterOption={false}
                                    onSearch={fetchLocation}
                                    style={{width: '250px'}}
                                >
                                    {currencyOptions.map(d => (
                                        <Select.Option key={d.number}
                                                       value={d.code}>
                                            {d.code} - {d.currency} ({getSymbolFromCurrency(d.code)})
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Descriptions.Item>


                        <Descriptions.Item>
                            <Form.Item label="Site web" name={"url"}>
                                <Input placeholder="ex : https://mon-site-web.fr" style={{width: '300px'}}/>
                            </Form.Item>
                        </Descriptions.Item>

                    </Descriptions>


                    <Descriptions title="Widget">

                        <Descriptions.Item>
                            <Space direction="vertical">
                                <Form.Item
                                    name={'defaultLanguage'}
                                    label={t('Langues du widget par defaut')}
                                    tooltip={t('Cette langue sera affiché si des traductions sont manquante ou si la langue demandé n\'existe pas')}
                                >
                                    <Select labelInValue style={{width: '100px'}}>
                                        {
                                            lngOptions.map(lng => <Select.Option key={`default_${lng.value}`}
                                                                                 value={lng.value}>{lng.label}</Select.Option>)
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={'widgetLanguages'}
                                    label={t('Langues du widget')}
                                    tooltip={t('Choisissez les langues qui pourront s\'afficher dans le widget')}
                                >
                                    <Checkbox.Group options={lngOptions}/>
                                </Form.Item>
                            </Space>
                        </Descriptions.Item>

                    </Descriptions>

                    <Form.Item>
                        <Button
                            type="primary" htmlType="submit"
                            loading={loading}>
                            {t('Valider')}
                        </Button>
                    </Form.Item>

                </Form>}
        </div>
    )
}