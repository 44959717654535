import React from "react";
import {Modal, Result, Button} from "antd";
import {useTranslation} from "react-i18next";

export default function ModalFreemium({visible, setVisible}) {

    const {t} = useTranslation();

    return (
        <Modal
            title={t('Passer en mode Premium')}
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
        >
            <Result
                status="404"
                title={t('désolé')}
                subTitle={t('pour acceder à cette fonctionalité, vous devez passer en mode premium')}
                extra={<Button type="primary"><a href="mailto: infos@mmcreation.com">{t('Contacter MMCreation')}</a></Button>}
            />
        </Modal>
    )
}