import React from "react";
import SliderMobile from "./SliderMobile";
import '../../../styles/landing-mobile.scss';
import NavbarMobile from "./NavbarMobile";
import ErgonomyMobile from "./Ergonomy-mobile";
import AutonomyMobile from "./AutonomyMobile";
import ConfidentialityMobile from "./Confidentiality-mobile";
import OfferMobile from "./OfferMobile";
import SubscriptionMobile from "./SubscriptionMobile";
import FooterMobile from "./FooterMobile";
import VideoMobile from "./VideoMobile";

export default function LandingMobile({data}) {
    return (
        <div className="landing-mobile">
            <NavbarMobile/>
            <SliderMobile/>
            <VideoMobile data={data}/>
            <ErgonomyMobile/>
            <AutonomyMobile/>
            <ConfidentialityMobile/>
            <OfferMobile/>
            <SubscriptionMobile/>
            <FooterMobile/>
        </div>
    )
}