import React, {useState, useEffect} from 'react';
import {Drawer, Typography} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";

const {Paragraph} = Typography;

export default function CommentDrawer({visible, setVisible, booking, canEdit = false, reload}) {

    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity} = apiDispatch;
    const {t} = useTranslation();

    const emptyValue = 'No comment';
    const [comment, setComment] = useState(booking.comment && booking.comment.length ? booking.comment : emptyValue);

    useEffect(() => {
        setComment(booking.comment && booking.comment.length ? booking.comment : emptyValue)
    }, [booking])

    async function updateComment(value) {
        if (value === emptyValue)
            return;

        setComment(value);

        await apiUpdateEntity('bookings', booking.id, {comment: value});

        if (typeof reload === 'function')
            reload();
    }

    return (
        <Drawer
            title={t("commentaire du client")}
            visible={visible}
            onClose={() => setVisible(false)}
        >
            <Paragraph editable={canEdit ? {tooltip: false, onChange: updateComment} : false}>
                {comment}
            </Paragraph>
        </Drawer>
    )
}