import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {useApiContext} from "../providers/ApiProvider";
import {Badge, Button, Card, Input, PageHeader, Space, Spin, Tag, Typography} from 'antd';
import {useAccountContext} from "../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../providers/AuthProvider";

import {LinkOutlined} from '@ant-design/icons';

export default function Home() {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [authState] = useAuthContext();
    const {auth} = authState;
    const [accountState, accountDispatch] = useAccountContext();
    const {accountSet} = accountDispatch;
    const history = useHistory();
    const {t} = useTranslation();

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [filters, setFilters] = useState([{name: "name", value: ""}, {name: 'isArchived', value: false}]);
    const [searchTimeout, setSearchTimeout] = useState()

    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const itemsPerPage = 20;

    useEffect(() => {
        fetch();
    }, [filters])

    async function fetch() {
        const response = await apiFetchCollection("accounts", {itemsPerPage: itemsPerPage, filters: filters});

        setAccounts(response["hydra:member"]);
        setHasMore(itemsPerPage < response["hydra:totalItems"]);
        setLoading(false);
    }

    async function onLoadMore() {
        setLoadingMore(true)

        const newPage = page + 1;
        const params = {
            page: newPage,
            itemsPerPage: itemsPerPage,
            filters: filters
        }

        const response = await apiFetchCollection("accounts", params);

        setAccounts([...accounts, ...response["hydra:member"]]);
        setHasMore(newPage * itemsPerPage < response["hydra:totalItems"]);
        setPage(newPage);
        setLoadingMore(false);
    }

    function handleClick(account) {
        accountSet(account);
        history.push('/dashboard');
    }

    function updateFilter(key, value) {
        let index = filters.findIndex(filter => filter.name === key);
        filters[index].value = value;
        setFilters([...filters]);
    }

    function search(e) {
        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(() => {
            setLoading(true);
            // console.log(e.target.value)
            updateFilter("name", e.target.value.trim());
        }, 500))

    }

    const loadMore = (
        hasMore ?
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 20,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button loading={loadingMore} onClick={onLoadMore}>{t('Afficher plus')}</Button>
            </div> : null
    );

    return (
        <div>
            <p style={{color: 'black', fontSize: '20px', margin: '1em 0 -1em'}}>{t('Bonjour !')}</p>
            <PageHeader
                title={<h1>{t('Choix du compte')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            >
                <div className="flex between align-baseline">
                    <span>{t('Quel compte souhaitez-vous administrer ?')}</span>
                    <Input
                        size={"large"}
                        allowClear
                        style={{width: 210}}
                        placeholder={t("Rechercher")}
                        onChange={search}
                    />
                </div>
            </PageHeader>
            <Spin spinning={loading}>

                <div className="account-card-grid">
                    {
                        accounts.map(account =>
                            <Card key={account.id} className="card-style" style={{width: '350px'}}>
                                <div style={{minHeight: 155}}>
                                    <Typography.Title level={4} >
                                        {account.name}
                                    </Typography.Title>

                                    <Space direction="vertical">
                                        {auth.roles.includes('ROLE_SUPER_ADMIN') ?
                                            <Space size="large">
                                                <div>
                                                    {account.isPremium ?
                                                        <Tag color="#531dab">Premium</Tag>
                                                        : <Tag color="#87d068">Freemium</Tag>}
                                                </div>
                                                <div>
                                                    {account.isActive ?
                                                        <Badge color="green" text="actif"/> :
                                                        <Badge color="red" text="inactif"/>}
                                                </div>
                                            </Space> : null}

                                        <div>Réservation(s) : {account.bookings.length}</div>

                                        {account.url &&
                                            <a target="_blank" href={account.url}><LinkOutlined/> Site Web</a>}
                                    </Space>

                                </div>
                                <Button
                                    type="primary"
                                    style={{width: '100%', marginTop: 20}}
                                    size="large"
                                    onClick={() => handleClick(account)}
                                >
                                    {t('Accéder à ce compte')}
                                </Button>
                            </Card>)
                    }
                </div>
                {loadMore}
            </Spin>
        </div>
    )
}