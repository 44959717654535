import React, {useState} from 'react';
import {Button, Space} from 'antd';
import ApiCollectionLatest from '../ApiCollectionLatest.js';
import {useApiContext} from "../../providers/ApiProvider";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import {useTranslation} from "react-i18next";
import ModalFreemium from "../Modal/ModalFreemium";
import {useAccountContext} from "../../providers/AccountProvider";
import { resolveNamesLanguage} from "../utils";
import {CategoriesTagsForm} from "./CategoriesTagsForm";

const CategoriesTagsDash = ({id, endpoint, cardTitle, ...rest}) => {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch
    const {t, i18n} = useTranslation();

    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const [contentVersion, setContentVersion] = useState(0)

    const [visible, setVisible] = useState(false);

    const columns = [
        {
            title: t('nom'),
            dataIndex: 'name',
            fixed: 'left',
            render: (name, record) =>
                resolveNamesLanguage(i18n, accountState, record)
        },
        {
            title: t('services'),
            dataIndex: "categories",
            render: (categories) => (
                <span>
                    {categories.length} {t("service(s) lié(s)")}
                </span>
            )
        },
        {
            title: t('actions'),
            dataIndex: 'id',
            width: '50px',
            render: (id, record) =>
                <div style={{textAlign: "right"}}>
                    <Space>
                        <EditTwoTone onClick={() => setSelectedItem(record)}/>
                        <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)}/>
                    </Space>
                </div>
        },
    ];

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: t('confirmer la suppression'),
            icon: <ExclamationCircleOutlined/>,
            content: t('ce type de catégorie ne pourra plus être utilisé pour filtrer dans vos widget, vérifiez la configuration de vos widgets.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item),
        });
    }

    const deleteItem = async (item) => {
        let response = await apiDeleteEntity('cat_tags', item.id)
        setContentVersion((new Date).getTime())
    }

    if (creationMode || selectedItem)
        return <CategoriesTagsForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <>
            <ApiCollectionLatest
                title={() => <div className="flex between">
                    <b>{t('Type de service')}</b>
                    <Space>
                        <Button
                            size="small"
                            onClick={()=>setCreationMode(true)}
                        >
                            +
                        </Button>
                    </Space>
                </div>
                }
                fetch_endpoint={`accounts/${id}/cat_tags`}
                columns={columns}
                pageSize={5}
                contentVersion={contentVersion}
                {...rest}
            />
            <ModalFreemium
                visible={visible}
                setVisible={setVisible}
            />
        </>
    );
};

export default CategoriesTagsDash;
