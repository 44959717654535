import React from "react";
import image from '../images/autonomie-pic.png'
import {useTranslation} from "react-i18next";

export default function AutonomyMobile() {

    const {t} = useTranslation();

    return (
        <div className="autonomy-mobile-container">
            <div>
                <div><h1>{t('Autonomie')}</h1></div>

                <div className="divider-mobile"/>

                <p>
                    {t('Breaking Day est un widget compatible avec tous les sites Internet et que vous contrôlez entièrement, ce qui vous assure une gestion totale de votre système de réservation en journée.')}
                </p>
            </div>

            <div>
                <img src={image} alt=""/>
            </div>
        </div>
    )
}