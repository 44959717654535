import React from "react";
import {Form, Input, message, Space} from "antd";
import {useTranslation} from "react-i18next";

export default function PermissionForm({formRef, apiPostEntity, account, setIsAddingPermission}) {

    const {t} = useTranslation();

    const handleFinish = async (values) => {
        console.log(values)
        let data = {
            account: account['@id'],
            email: values.email.trim(),
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            isAdmin: true,
            sendMail: true
        }
        let response = await apiPostEntity('permissions', data)
        if (response["@type"] === "hydra:Error") {
            return message.error(t('la permission, n\'a pas pu être crée. Si le problème persiste contacter un administrateur'))
        }

        message.success(t('permission ajoutée avec succes'))
        formRef.current.resetFields()
        setIsAddingPermission(false)
    }

    return (
        <Form ref={formRef} onFinish={handleFinish}>
            <p><strong>{t('donnez une permission à votre compte')}</strong></p>
            <Form.Item
                {...{
                    labelCol: {span: 0},
                    wrapperCol: {span: 18},
                }}
                name={'email'}
                rules={[
                    {required: true, message: t('email requis')},
                    {type: 'email', message: t('email non valide!')}
                ]}
            >
                <Input placeholder="Email"/>
            </Form.Item>
            <Space style={{display: 'flex', marginBottom: 8}}
                   align="baseline">
                <Form.Item
                    {...{
                        labelCol: {span: 0},
                        wrapperCol: {span: 24},
                    }}
                    name={'lastName'}
                    rules={[
                        {required: true, message: t('nom requis')}
                    ]}
                >
                    <Input placeholder={t("nom")}/>
                </Form.Item>
                <Form.Item
                    {...{
                        labelCol: {span: 0},
                        wrapperCol: {span: 24},
                    }}
                    name={'firstName'}
                    rules={[
                        {required: true, message: t('prénom requis')}
                    ]}
                >
                    <Input placeholder={t("prénom")}/>
                </Form.Item>
            </Space>

        </Form>
    )
}