import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../Translation/LanguageSelect";
import { resolveNamesLanguage } from "../utils";

export function AddonsForm({ modal, onClose, item, visible = true }) {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const { apiPostEntity, apiUpdateEntity, apiFetchSubResource } = apiDispatch
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();

    const [form] = Form.useForm();

    const [loadingTags, setLoadingTags] = useState(true);
    const [tags, setTags] = useState([]);
    useEffect(() => {
        fetchTags();
    }, [])

    async function fetchTags() {
        const response = await apiFetchSubResource('accounts', { id: accountState.account.id }, 'tags');

        setTags(response['hydra:member']);
        setLoadingTags(false);
    }

    async function onFinish(values) {
        setLoading(true)
        let params = {
            name: language === accountState.account.defaultLanguage ? values.name.trim() : _names[accountState.account.defaultLanguage],
            names: { ..._names, [language]: values.name.trim() },
            price: values.price,
            addTags: values.addTags
        }
        if (item) {
            await update(params)
        } else {
            await postEntity(params)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(params) {
        params.account = accountState.account['@id']
        let response = await apiPostEntity('addons', params)
    }

    async function update(params) {
        let response = await apiUpdateEntity('addons', item.id, params);
    }

    function onFinishFailed(values) {
        console.log(values)
    }

    function handleOk() {
        form.submit()
    }

    const [language, setLanguage] = useState(null)
    const [_names, setNames] = useState(item?.names ? item.names : { en: '' })
    const handleLanguageChange = (newLanguage) => {

        let values = form.getFieldsValue()
        console.log(values)
        if (language) {
            setNames(prev => {
                return { ...prev, [language]: values.name.trim() }
            })
        }

        console.log(_names)
        form.setFieldsValue({
            name: _names[newLanguage] || '',
        })
        setLanguage(newLanguage)
    }

    const content = (
        <Spin spinning={loading}>
            <LanguageSelect callback={handleLanguageChange} currentLng={language} />
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    name: item ? item.name : '',
                    price: item ? item.price : '',
                    addTags: item ? item.tags.map(tag => tag.name) : []
                }}
            >
                <Form.Item
                    label={t('nom')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t('La langue par défault est requise'),
                            validator: (rule, value) => {
                                if ((language === accountState.account.defaultLanguage && !value) && !_names[accountState.account.defaultLanguage])
                                    return Promise.reject(new Error('La langue anglaise est requise'))

                                return Promise.resolve();
                            }
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("prix")}
                    name="price"
                    rules={[
                        {
                            required: true,
                            message: t('entrer un prix!'),
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    label="Tags"
                    name="addTags"
                >
                    <Select
                        mode="tags"
                        style={{ width: 300 }}
                        placeholder="Selectionner un tag"
                        loading={loadingTags}
                        disabled={loadingTags}
                    >
                        {tags.map(tag => (
                            <Select.Option key={tag.id} value={tag.name}>{tag.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Spin>
    )

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal visible={visible}
                destroyOnClose
                title={item ? `${t('editer')} ${resolveNamesLanguage(i18n, accountState, item)}` : t("créer un extra")}
                onOk={handleOk}
                okText={item ? t("modifier") : t("créer")}
                onCancel={handleCancel}
                cancelText={t("retour")}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}