import React, {useEffect, useState} from 'react'
import CollectionTable from "../CollectionTable";
import {Space, Tag} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {TimeslotForm} from "./TimeSlotForm";
import {useTranslation} from "react-i18next";
import {displayTime} from "../utils";

export default function TimeSlotList({id, needUpdate}) {

    const [selectedItem, setSelectedItem ] = useState(null)
    const [creationMode, setCreationMode ] = useState(false)

    const [contentVersion, setContentVersion ] = useState(0)
    const {t, i18n} = useTranslation();

    useEffect(()=> {
        setContentVersion(needUpdate)
    }, [needUpdate])

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }


    const columns = [
        {
            title: t('nom'),
            dataIndex: 'name',
            render: (name,record) => <>
                {name} <br />
            </>
        },
        {
            title: t('début'),
            dataIndex: 'start',
            render: (start,record) => <small>{displayTime(i18n.language, start)}</small>
        },
        {
            title: t('fin'),
            dataIndex: 'end',
            render: (end,record) => <small>{displayTime(i18n.language, end)}</small>
        },
        {
            title: t('catégorie lié'),
            dataIndex: 'categories',
            render: (categories,record) => <Space>
                {
                    categories.map(item => <Tag >{item.name}</Tag>)
                }
            </Space>
        },
        {
            title: t('actions'),
            dataIndex: 'email',
            render: (email,record) => <>
                <EditOutlined onClick={()=> setSelectedItem(record)}/>
            </>
        },
    ];


    if(creationMode || selectedItem)
        return <TimeslotForm modal item={selectedItem} onClose={handleCreationFormClose} />;

    return (
        <>
            <CollectionTable
                endpoint={`accounts/${id}/time_slots`}
                columns={columns}
                onClick={(item) => setSelectedItem({...item}) }
                reload={contentVersion}
            />
            {/*{selectedItem ? <ContactDrawer item={selectedItem} onClose={handleEditFormClose} onChange={handleEditFormChange} /> : null}*/}
        </>
    );
}