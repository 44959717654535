import React from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import BookingCell from "./BookingCell";
import {EyeOutlined} from "@ant-design/icons";
import {useAccountContext} from "../../providers/AccountProvider";
import {displayTime, resolveNamesLanguage} from "../utils";

export default function ClassicCategories({
                                              looping, currentDay, categories,
                                              showModal, showBookingModal,
                                              setSelectedCategory, bookings
                                          }) {

    const {t, i18n} = useTranslation();
    const [accountState] = useAccountContext();

    return (
        categories.map(category => {
            if (category.timeSlots.length === 0) return null
            const isDuration = category.type === 'duration';

            return (
                <div key={category.name}>
                    <div className="category-cell">
                        <span>{resolveNamesLanguage(i18n, accountState, category)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        {isDuration &&
                        <span>
                            <span>({displayTime(i18n.language, category.timeSlots[0].start)} - {displayTime(i18n.language, category.timeSlots[0].end)})</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                type="link"
                                icon={<EyeOutlined/>}
                                style={{fontWeight: 'bold'}}
                                onClick={() => setSelectedCategory(category)}
                            >
                                {t('Voir')}
                            </Button>
                        </span>}
                    </div>

                    {!isDuration &&
                    <table className="header-table">
                        <tbody>
                        {category.timeSlots.map(time => {
                            return (
                                <tr key={`t_${time.id}`} className="table-row">
                                    <td className="ant-table-cell"
                                        style={{width: 20, fontSize: 12}}>
                                        <small>{displayTime(i18n.language, time.start)} - {displayTime(i18n.language, time.end)}</small>
                                    </td>
                                    {
                                        looping.map((value, index) => {
                                            let day = dayjs(currentDay).add(index, 'days').format('DD-MM-YYYY')
                                            let dayNumber = dayjs(currentDay).add(index, 'days').day()
                                            let offer = time.offers.find(offer => offer.day === day) || {
                                                day: day,
                                                id: null,
                                                price: 0
                                            }

                                            const books = bookings.filter(booking => dayjs(booking.bookAt).format('DD-MM-YYYY') === offer.day && booking.offer === `/api/offers/${offer.id}` && booking.status !== 'canceled');
                                            const isAvailable = offer.status === 'available' && offer.quantity > 0;

                                            let backgroundColor = '#f50';
                                            if (isAvailable && (offer.quantity - books.length) === 0)
                                                backgroundColor = '#2db7f5';
                                            else if (isAvailable)
                                                backgroundColor = '#87d068';

                                            return (
                                                <td
                                                    className={dayNumber > 4 || dayNumber === 0 ? 'table-cell weekend' : 'table-cell'}
                                                    style={{
                                                        padding: 0,
                                                        width: 40,
                                                        textAlign: 'center',
                                                        fontSize: 12,
                                                        backgroundColor: backgroundColor
                                                    }}
                                                    key={time + day}
                                                >
                                                    <BookingCell
                                                        offer={offer}
                                                        books={books}
                                                        showModal={showModal}
                                                        showBookingModal={showBookingModal}
                                                        account={accountState.account}
                                                    />
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>}
                </div>
            )
        })
    )
}