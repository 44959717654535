import React, {useEffect, useState} from 'react'
import {Button, PageHeader, Select} from "antd";
import TranslationForm from "./TranslationForm";
import {TranslationDropdown} from "./TranslationDropdown";
import {useTranslation} from 'react-i18next';
import {useApiContext} from "../../providers/ApiProvider";
import TranslationList from "./TranslationList";


export default function TranslationDash({}) {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiFetchEntity} = apiDispatch;
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [languages, setLanguages] = useState([]);
    const [baseLanguages, setBaseLanguages] = useState({})
    const [loadingLng, setLoadingLng] = useState({})
    const [selectedLng, setSelectedLng] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(1);


    useEffect(() => {
        setLoadingLng(true);
        fetchTranslations();

    }, [needUpdate])


    const fetchTranslations = async () => {
        let response = await apiFetchCollection('translations', {})

        console.log(response['hydra:member']);
        let lngs = response['hydra:member']
        await fetchBaseLanguage(lngs)
        setLanguages(response['hydra:member'])
        setLoadingLng(false)

    }

    const fetchBaseLanguage = async (lngs) => {
        let types = ['admin', 'widget']
        let bases = {}
        types.map(async type => {
            console.log(lngs)
            let lng = lngs.find(item => item.lng === 'fr' && item.type === type)
            console.log(lng)
            let response = await apiFetchEntity('translations', lng.id)
            console.log(response);
            bases[type] = response
        })
        console.log('b', bases)
        setBaseLanguages(bases)
    }

    const handleCreationFormClose = () => {
        setIsCreateVisible(false);
        setNeedUpdate((new Date).getTime())
    }

    const selectLng = (id) => {
        setSelectedLng(languages.find(item => item.id === id))
    }
console.log(selectedLng)
    return (
        <>
            <PageHeader
                title={t("traductions")}
                extra={[
                    <Button type="primary"
                            key="service_create"
                            onClick={() => setIsCreateVisible(true)}>Créer</Button>,
                ]}
            />
            <p>{t('choisissez une langue à éditer')}</p>
            <Select defaultValue={null} style={{width: 200}} onChange={(value) => selectLng(value)}>
                <Select.Option key="default-lng" value={null}>{t('langues')}</Select.Option>
                {
                    languages.map(item =>
                        <Select.Option key={item.id} value={item.id}>{item.lng} - {item.type}</Select.Option>
                    )
                }
            </Select>
            {
                selectedLng ?
                    <TranslationList selectedLng={selectedLng} baseLanguage={baseLanguages[selectedLng.type]}
                                     setNeedUpdate={setNeedUpdate}/>
                    : null
            }
            <TranslationForm visible={isCreateVisible} onClose={handleCreationFormClose} modal/>
        </>

    )
}