import React, {useState} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import CategoriesList from "../components/Categories/CategoriesList";
import {Button, PageHeader} from "antd";
import {CategoriesForm} from "../components/Categories/CategoriesForm";
import {useTranslation} from "react-i18next";

export default function Categories() {

    const {t} = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const [creating, setCreating] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)

    function onClose() {
        setNeedUpdate((new Date()).getTime())
        setCreating(false)
    }

    return (
        <>
            <PageHeader
                title={<h1>{t('catégories')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            >
                <div className="flex justify-end align-baseline">
                    <Button onClick={()=> setCreating(true)}>{t('créer une catégorie')}</Button>
                </div>
            </PageHeader>
            <CategoriesList id={accountState.account.id} needUpdate={needUpdate}/>
            <CategoriesForm modal visible={creating} onClose={onClose} />
        </>
);

}
