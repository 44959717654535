import React, { useEffect, useState } from 'react';
import { useWidgetContext } from "../../providers/WidgetProvider";
import Loading from "../component/Loading";

export default function TagStepLight({ selectedTags, setSelectedTags }) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { widget, lng } = widgetState;
    const { post } = widgetDispatch;
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState(true)

    useEffect(() => {
        fetchTags()
    }, [])

    const fetchTags = async () => {
        setLoading(true)
        const data = { widgetId: widget.id }

        const response = await post('get-tags', data);
        if (response['hydra:member']) {
            if (widget.tags && widget.tags.length)
                setTags(response['hydra:member'].filter(tag => !widget.tags.includes(`/api/cat_tags/${tag.id}`) && tag.categories.length));
            else
                setTags(response['hydra:member'].filter(tag => tag.categories.length));
        }

        setLoading(false)
    }

    const toggleTag = (iri) => {
        let tags = [...selectedTags];
        if (tags.includes(iri)) {
            tags = tags.filter(item => item !== iri)
        } else {
            tags.push(iri)
        }

        setSelectedTags(tags);
    }

    const resolveImage = (tag) => {

        let link = ''
        if (tag?.image?.native) {
            link = tag.image?.native?.sm
        } else {
            let i = 0;
            while (!link && i < tag.categories.length) {
                if (tag.categories[i].images && tag.categories[i].images.length > 0) {
                    link = tag.categories[i].images[0]?.display.native.sm
                    i = tag.categories.length
                }
                i++
            }
        }

        return link;
    }

    return (
        <Loading>
            <div className="flex wrap offers-container">
                {
                    loading ?
                        <div className="offer-container" key={`dummy`}>
                            <div className="offer relative">
                                <div className="overlay" />
                            </div>
                        </div>
                        :
                        tags.map(tag => {
                            let link = resolveImage(tag)
                            return (
                                <div className="offer-container"
                                    style={{ width: tags.length <= 3 ? '100%' : '50%' }}
                                    key={tag.id}>
                                    <div
                                        className="offer relative"
                                        onClick={() => toggleTag(`/api/cat_tags/${tag.id}`)}
                                        style={selectedTags.includes(`/api/cat_tags/${tag.id}`) ?
                                            { border: `3px solid ${widget.backgroundColor || '#2494D1'}` } : null}
                                    >
                                        <img
                                            src={link}
                                            alt="tag"
                                        />

                                        <div className="overlay" />

                                        <div className="category">
                                            {tag.names[lng] ? tag.names[lng] : tag.names[widget.account.defaultLanguage]}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )
                }
            </div>
        </Loading>
    )
}

