import React, {useEffect, useState} from "react";
import {useAccountContext} from "../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {Card, Col, Row, Statistic} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";

export default function WidgetsStatistics() {

    const [accountState] = useAccountContext();
    const {account} = accountState;
    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;

    const [widgets, setWidgets] = useState([]);

    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        const data = {
            filters: [
                {name: 'account.id', value: account.id}
            ]
        }

        const response = await apiFetchCollection('widgets_with_stats', data);

        setWidgets(response['hydra:member']);
    }

    return (
        <Row gutter={16}>
            {widgets.map(widget => (
                <Col span={12} style={{marginTop: 20}} key={widget.id}>
                    <Card title={widget.name}>
                        <Row gutter={24}>
                            <Col span={12} style={{padding: 20}}>
                                <Statistic title={t('Nombre d\'ouverture du widget')} value={widget.consolidatedStatistic.opened}/>
                            </Col>
                            <Col span={12} style={{padding: 20}}>
                                <Statistic title={t('Nombre de réservation')} value={widget.consolidatedStatistic.booked}/>
                            </Col>
                            <Col span={12} style={{padding: 20}}>
                                <Statistic title={t('Chiffre d\'affaire')}
                                           value={`${widget.consolidatedStatistic.price} ${getSymbolFromCurrency(account.currency)}`}/>
                            </Col>

                            {/* Need to update stats to display stats porperly */}
                            {/* <Col span={12} style={{padding: 20}}>
                                <div className="ant-statistic">
                                    <div className="ant-statistic-title">
                                        {t('Étape de fermeture')}
                                    </div>
                                    <div className="ant-statistic-content" style={{fontSize: 14}}>
                                        {Object.keys(widget.consolidatedStatistic.exitSteps).map((key, id) => (
                                            <div key={id}>{t([key])}: {widget.consolidatedStatistic.exitSteps[key]}</div>
                                        ))}
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}