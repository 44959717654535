import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useTranslation} from "react-i18next";

export default function NbPeople({range, nbPeople, setNbPeople}) {

    const [values, setValues] = useState([]);
    const {t} = useTranslation();

    useEffect(() => {
        const [min, max] = range;
        setNbPeople(min);

        let array = [];
        for (let i = min; i <= max; i++) {
            array.push(i);
        }

        setValues(array);
    }, [])

    return (
        <div className="confirm-tab" style={{marginTop: '28px'}}>
            <div className="confirm-tab-title">
                <div className="background-transparent"
                     style={{backgroundColor: '#2494D1'}}/>
                {t('Pour combien de personnes souhaitez-vous réserver ?')}
            </div>
            <div className="confirm-tab-content">
                <Select
                    style={{width: 300}}
                    value={nbPeople}
                    onChange={e => setNbPeople(e)}
                >
                    {values.map(value => (
                        <Select.Option key={value} value={value}>{value} {t('personnes')}</Select.Option>
                    ))}
                </Select>
            </div>
        </div>
    )
}