import React, { useEffect, useState } from 'react';
import { useWidgetContext } from "../../providers/WidgetProvider";
import dayjs from "dayjs";
import Drawer from "../component/Drawer";
import CategoryDetails from "../component/CategoryDetails";
import { ReactComponent as Glass } from "../svg/magnifying-glass.svg";
import Loading from "../component/Loading";
import SimpleNextStepBtn from "../component/SimpleNextStepBtn";
import getSymbolFromCurrency from "currency-symbol-map";

export default function Step2() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { bookingInfos } = widgetState;
    const { widget, lng, i18n } = widgetState;
    const { post, setter, stringDateDMonthY, setBookingInfos, displayTime } = widgetDispatch;
    const [loading, setLoading] = useState(false)

    const [offers, setOffers] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);

    const [filterTimeSlots, setFilterTimeSlots] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [showDetails, setShowDetails] = useState(null);

    useEffect(() => {
        fetch();
        if (bookingInfos.addons?.length)
            setBookingInfos({ ...bookingInfos, addons: [] });
    }, [filterTimeSlots])

    async function fetch() {
        setLoading(true)
        let data = {
            accountId: widget.account.id,
            date: bookingInfos.date || dayjs().format('YYYY-MM-DD'),
            filterTimeSlots: filterTimeSlots
        }

        if (widget.tags && widget.tags.length)
            data.tags = widget.tags.map(tag => parseFloat(tag.split('/').pop()))

        const response = await post('get-offers-by-day', data);

        setOffers(response['hydra:member']);

        if (!filterTimeSlots.length)
            setData(response['hydra:member']);

        setLoading(false)
    }

    function setData(items) {
        let array = [];

        items.map(item => {
            if (item.category.type === 'duration' && !array.some(a => a.id === item?.categoryTimeslot?.id) && item.categoryTimeslot) {
                return array.push(item.categoryTimeslot);
            } else if (item.category.type !== 'duration' && !array.some(a => a.id === item.timeSlot.id))
                return array.push(item.timeSlot);
        })

        array.sort((a, b) => a.start === b.start ? a.end.localeCompare(b.end) : a.start.localeCompare(b.start));

        setTimeSlots(array);
    }

    function toggleFilter(id) {
        if (filterTimeSlots.includes(id))
            setFilterTimeSlots(filterTimeSlots.filter(fts => fts !== id));
        else
            setFilterTimeSlots([...filterTimeSlots, id]);
    }

    function toggleOffer(offer) {
        let selected = { ...offer }
        if (!!selected?.categoryTimeslot?.id) {
            selected.firstSelection = true
        }
        let copy = { ...bookingInfos };
        copy.offer = selected;
        setter('bookingInfos', copy);
    }

    function closeDetails() {
        setSelectedOffer(null)
        setShowDetails(false)
    }

    function openDetails(e, offer) {
        e.stopPropagation()
        setSelectedOffer(offer)
        setShowDetails(true)
    }

    return (
        <div className="background-grey" style={{ minHeight: '100%' }}>

            <Drawer visible={showDetails} close={closeDetails} contentStyle={{ padding: 0 }}
                iconStyle={{ backgroundColor: 'white' }}>
                <CategoryDetails close={closeDetails} offer={selectedOffer} />
            </Drawer>

            <div className="step_content flex column between overflow-auto" style={{ color: "black" }}>

                <div style={{ fontSize: 14 }}>
                    {
                        timeSlots.length > 1 &&
                        <>
                            <div>
                                {i18n.gettext('Filtrer par créneaux horaires pour le')}&nbsp;
                                <strong>
                                    {stringDateDMonthY(bookingInfos.date || dayjs().format('YYYY-MM-DD'))}
                                </strong>
                                &nbsp;:
                            </div>

                            <div className="flex wrap timeslots-container">
                                {timeSlots.map(timeSlot => (
                                    <div className="timeslot-container" key={`filters_${timeSlot.id}`}>
                                        <div
                                            className="timeslot"
                                            onClick={() => toggleFilter(timeSlot.id)}
                                            style={filterTimeSlots.includes(timeSlot.id) ? {
                                                backgroundColor: widget.backgroundColor || '#2494D1',
                                                color: "#fff"
                                            } : null}
                                        >
                                            {displayTime(timeSlot.start)} - {displayTime(timeSlot.end)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                    <Loading loading={loading}>
                        {timeSlots.map(timeSlot =>
                            filterTimeSlots.includes(timeSlot.id) || filterTimeSlots.length === 0 ?
                                <div className="timeslot-container" key={`time_${timeSlot.id}`}>
                                    <div>
                                        {displayTime(timeSlot.start)} {i18n.gettext('À')} {displayTime(timeSlot.end)}
                                    </div>
                                    {
                                        <div className="flex wrap offers-container">
                                            {
                                                loading ?
                                                    <div className="offer-container" key={`${timeSlot.id}_dummy`}>
                                                        <div className="offer relative">
                                                            <div className="overlay" />
                                                        </div>
                                                    </div>
                                                    :
                                                    offers.map(offer =>
                                                        (offer.timeSlot.id === timeSlot.id || offer?.categoryTimeslot?.id === timeSlot.id) && (
                                                            <div className="offer-container"
                                                                key={`${timeSlot.id}_${offer.id}`}>
                                                                <div
                                                                    className="offer relative"
                                                                    onClick={() => toggleOffer(offer)}
                                                                    style={bookingInfos.offer?.id === offer.id ||
                                                                        (!!bookingInfos.offer?.categoryTimeslot?.id && bookingInfos.offer.category.id === offer.category.id) ?
                                                                        { border: `3px solid ${widget.backgroundColor || '#2494D1'}` } : null}
                                                                >
                                                                    <img
                                                                        src={offer.category.images[0]?.display.native.sm}
                                                                        alt="offer"
                                                                    />

                                                                    <div className="overlay" />

                                                                    <div className="more-info"
                                                                        onClick={(e) => openDetails(e, offer)}>
                                                                        <div className="background" />
                                                                        <Glass style={{
                                                                            color: 'black',
                                                                            width: '10px',
                                                                            zIndex: 1
                                                                        }} />
                                                                    </div>

                                                                    {
                                                                        !offer?.categoryTimeslot?.id && !!offer.price &&
                                                                        <div className="price"
                                                                            title={'prix en ' + widget.account.currency}>
                                                                            <div className="background" />
                                                                            <strong>{offer.price} {getSymbolFromCurrency(widget.account.currency)}</strong>
                                                                        </div>
                                                                    }
                                                                    <div className="category">
                                                                        {offer.category.names[lng] ? offer.category.names[lng] : offer.category.names[widget.account.defaultLanguage]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                            }
                                        </div>
                                    }
                                </div>
                                : null
                        )}
                    </Loading>
                </div>
            </div>
            <SimpleNextStepBtn disabled={!bookingInfos.offer} />
        </div>
    )
}