import React from "react";

export default function VideoMobile({data}) {

    return (
        <div className="video-container-mobile">
            <iframe
                src={`https://streamable.com/e/${data.youtubeId}`} frameBorder="0" width="100%" height="100%"
                allowFullScreen
            />
        </div>
    )
}