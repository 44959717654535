import React, {useState} from "react";
import {Form, message, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";

export default function UserPermissionForm({formRef, apiPostEntity, user, setIsAddingPermission}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [searchTimeout, setSearchTimeout] = useState(null)
    const [searchLoading, setSearchLoading] = useState(false)
    const [options, setOptions] = useState([])
    const {t} = useTranslation();

    const handleFinish = async (values) => {

        console.log(values)
        await Promise.all(values.accounts.map(async account => {
            let data = {
                account: account,
                email: user.email,
                sendMail: true,
                isAdmin: true
            }
            let response = await apiPostEntity('permissions', data)
            if (response["@type"] === "hydra:Error") {
                return message.error(t('la permission, n\'a pas pu être crée. Si le problème persiste contacter un administrateur'))
            }
        }))

        message.success(t('permission ajoutée avec succes'))
        formRef.current.resetFields()
        setIsAddingPermission(false)
    }

    function handleSearch(value) {
        setSearchLoading(true)
        clearTimeout(searchTimeout)
        setSearchTimeout(setTimeout(async () => {
            let params = {
                filters: [{value: value.trim(), name: 'name'}]
            }
            let response = await apiFetchCollection('accounts', params)
            console.log(response['hydra:member'])
            setOptions(response['hydra:member'])
            setSearchLoading(false)
        }, 250))

    }

    return (
        <Form ref={formRef} onFinish={handleFinish}>
            <p><strong>{t('donnez une permission à cet utilisateur')}</strong></p>
            <Form.Item
                {...{
                    labelCol: {span: 0},
                    wrapperCol: {span: 18},
                }}
                name={'accounts'}
                rules={[
                    {required: true, message: t('sélectionner au moins un compte')},
                ]}
            >
                <Select
                    placeholder={t('rechercher un compte')}
                    filterOption={false}
                    onSearch={handleSearch}
                    mode="multiple"
                    notFoundContent={null}
                    loading={searchLoading}
                    >
                    {
                        options.map(account => <Select.Option key={account.id} value={account['@id']}>{account.name}</Select.Option>)
                    }
                </Select>
            </Form.Item>
        </Form>
    )
}