import React from 'react'
import {ReactComponent as Logo} from "../svg/logo.svg";

export default function Loading({children, loading}) {


    return (
        <div style={{position: 'relative'}}>
            {loading &&
            <div className="loader">
                <Logo style={{width: '50px', color: 'white'}} />
            </div>
            }
            {children}
        </div>
    )
}