import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import {CloseOutlined, PlayCircleTwoTone} from "@ant-design/icons";
import bandeau from "../images/VIDEO-BREAKING DAY.png"

export default function Video({data}) {

    const [visible, setVisible] = useState(false);

    return (
        <div className="video-container" id="video-container">
            {/*<div className="video-iframe-container">
                <iframe
                    src="https://streamable.com/e/kp3efw" frameBorder="0" width="100%" height="100%"
                    allowFullScreen
                />

                <div id="overlay-video" onClick={() => setVisible(true)}></div>
            </div>*/}

            <img src={bandeau} onClick={() => setVisible(true)} alt="Presentation breaking-day banner"/>
            <PlayCircleTwoTone
                onClick={() => setVisible(true)}
                twoToneColor="#2394D1"
                style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize: 50}}
            />

            <Modal
                className="modal-video"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={"calc(95vh*16/9)"}
                bodyStyle={{
                    height: "95vh",
                    padding: 0
                }}
                style={{
                    top: 10,
                }}
                destroyOnClose
                zIndex={10000000}
                closeIcon={<CloseOutlined style={{color: "#fff"}} />}
            >
                <iframe
                    src={`https://streamable.com/e/${data.youtubeId}`} frameBorder="0" width="100%" height="100%"
                    allowFullScreen
                />
            </Modal>
        </div>
    )
}