import React, {useState} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import {Button, PageHeader, Space} from "antd";
import ReservationList from "../components/Reservation/ReservationList";
import {useTranslation} from "react-i18next";
import {BarsOutlined, AppstoreAddOutlined} from "@ant-design/icons";
import BookingCalendar from "../components/BookingCalendar/BookingCalendar";

export default function Reservations() {

    const [accountState] = useAccountContext();
    const {t} = useTranslation();

    const [isCalendar, setIsCalendar] = useState(true);

    return (
        <>
            <PageHeader
                title={<h1>{t('Réservations')}</h1>}
                style={{borderBottom: '2px solid #2494D1', marginBottom: 20}}
                extra={<Space>
                    <Button
                        icon={<AppstoreAddOutlined/>}
                        onClick={() => setIsCalendar(true)}
                        className={isCalendar ? 'selected-view' : ''}
                    />
                    <Button
                        icon={<BarsOutlined/>}
                        onClick={() => setIsCalendar(false)}
                        className={!isCalendar ? 'selected-view' : ''}
                    />
                </Space>}
            >
            </PageHeader>
            {isCalendar ? <BookingCalendar/> : <ReservationList id={accountState.account.id}/>}
        </>
    );

}
