import React, { useRef, useState } from "react";
import { Form, Input, message, Modal, Spin } from "antd";
import { useApiContext } from "../../providers/ApiProvider";
import { useTranslation } from "react-i18next";

export function UserForm({ modal, onClose, item, visible = true }) {

    const { t } = useTranslation();
    const [apiDispatch] = useApiContext();
    const { apiFetchCollection, apiPostEntity, apiUpdateEntity } = apiDispatch
    const [loading, setLoading] = useState(false)
    const [validatingNickName, setValidatingNickName] = useState('')

    const formRef = useRef()

    async function onFinish(values) {
        setLoading(true)

        if ((values.password && !values.confirm))
            return message.error(t('veuillez confirmer votre mot de passe'));

        if ((values.password && values.confirm) && (values.password.trim() !== values.confirm.trim()))
            return message.error(t('les deux mots de passe ne sont pas identique'));


        if (item) {
            await update(values)
        } else {
            await postEntity(values)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(values) {
        let params = {
            email: values.email.trim(),
            username: values.username.trim(),
            roles: ['ROLE_ADMIN']
        }
        if (values.password) {
            params.password = values.password.trim()
        }
        let response = await apiPostEntity('users', params)

        if (response['@type'] === 'hydra:Error') {
            message.error(response['hydra:description'])
        }
        console.log(response)

    }

    async function update(values) {
        let params = {
            email: values.email.trim(),
            username: values.username.trim()
        }
        if (values.password) {
            params.password = values.password.trim()
        }
        let response = await apiUpdateEntity('users', item.id, params)

        if (response['@type'] === 'hydra:Error') {
            message.error(response['hydra:description'])
        }
    }


    function handleOk() {
        formRef.current.submit()
    }


    const [usernameTimeout, setUsernameTimeout] = useState(null)
    const [emailTimeout, setEmailTimeout] = useState(null)

    const handleValidation = async (data, value) => {
        console.log('rule', data)

        if (data.field === 'username') {
            return checkUsername(value)
        }
        if (data.field === 'email') {
            return checkEmail(value)
        }
    };

    function checkUsername(value) {
        clearTimeout(usernameTimeout);

        return new Promise((resolve, reject) => setUsernameTimeout(setTimeout(async () => {
            const results = await apiFetchCollection('users', { filters: [{ name: "username", value: value.trim() }] })
            if (results['@type'] === "hydra:Collection") {
                let r = results['hydra:member'].find(item => item.username.toLowerCase() === value.toLowerCase())

                if (r && r.id !== item?.id) {
                    return reject('Ce username existe déja.')
                }
            }
            return resolve(true);
        }, 250)))
    }

    function checkEmail(value) {
        clearTimeout(emailTimeout);

        return new Promise((resolve, reject) => setEmailTimeout(setTimeout(async () => {
            const results = await apiFetchCollection('users', { filters: [{ name: "email", value: value.trim() }] })
            if (results['@type'] === "hydra:Collection") {
                let r = results['hydra:member'].find(item => item.email.toLowerCase() === value.toLowerCase())

                if (r && r.id !== item?.id) {
                    return reject('Cet email existe déja.')
                }
            }
            return resolve(true);
        }, 250)))
    }


    const content = (
        <Spin spinning={loading}>
            <Form
                ref={formRef}
                name="basic"
                onFinish={onFinish}
                initialValues={{
                    email: item ? item.email : '',
                    username: item ? item.username : ''
                }}
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: t('entrer un username!'),
                        },
                        { validator: handleValidation },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: t('email requis') },
                        { type: 'email', message: t('email non valide!') },
                        { validator: handleValidation },
                    ]}
                    hasFeedback

                >
                    <Input />
                </Form.Item>

                {
                    item && <p>{t('laissez le mot de passe vide si vous ne souhaitez pas le modifier')}</p>
                }
                <Form.Item
                    name="password"
                    label={t("mot de passe")}
                    hasFeedback
                    rules={[
                        {
                            required: !item,
                            message: t('entrer un mot de passe!'),
                        },
                    ]}
                >
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={t("confirmer le mot de passe")}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || getFieldValue('password') === value)
                                    return Promise.resolve();

                                return Promise.reject(t('les deux mots de passe ne sont pas indentiques'));
                            }
                        })
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Spin>
    )

    if (modal) {
        return (
            <Modal visible={visible}
                destroyOnClose
                title={item ? `${t('editer')} ${item.username}` : t("créer un utilisateur")}
                onOk={handleOk}
                okText={item ? t("modifier") : t("créer")}
                onCancel={onClose}
                cancelText={t("retour")}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}