import React, {useState} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import {Button, PageHeader} from "antd";
import TimeSlotList from "../components/TimeSlot/TimeSlotList";
import {TimeslotForm} from "../components/TimeSlot/TimeSlotForm";
import {useTranslation} from "react-i18next";

export default function TimeSlots() {

    const [accountState, accountDispatch] = useAccountContext();
    const [creating, setCreating] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)
    const {t} = useTranslation();

    function onClose() {
        setNeedUpdate((new Date()).getTime())
        setCreating(false)
    }

    return (
        <>
            <PageHeader
                title={<h1>{('Horaires')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            >
                <div className="flex justify-end align-baseline">
                    <Button onClick={()=> setCreating(true)}>{t('Créer une tranche horaire')}</Button>
                </div>
            </PageHeader>
            <TimeSlotList id={accountState.account.id} needUpdate={needUpdate}/>
            <TimeslotForm modal visible={creating} onClose={onClose} />
        </>
    );

}
