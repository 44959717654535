import React, {useRef} from 'react'
import {Button, Form, Input, message, Modal, Space} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default function AccountForm({callback, isVisible, modal, account}) {

    const [apiDispatch] = useApiContext()
    const {apiPostEntity, apiFetchCollection, apiUpdateEntity} = apiDispatch
    const {t} = useTranslation();

    const form = useRef()


    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };


    const searchUsername = async (rule, value) => {
        // console.log(value)
        if (!value || !value.trim()) return
        // console.log(value)
        let params = {
            filters: [
                {
                    name: "username", value: value.trim()
                }
            ]
        }

        let response = await apiFetchCollection('users', params)
        let usernameExist = response['hydra:member'].find(user => user.username === value.trim())

        if (!!usernameExist) {
            return Promise.reject(t('nom d\'utilisateur est déjà pris!'))
        }
        return Promise.resolve()

    }


    const onFinish = async (values) => {
        console.log('Success:', values);

        if (account) {
            await updateAccount(values)
        } else {
            await postAccount(values)
        }

        form.current.resetFields()
        callback()

    };

    const updateAccount = async (values) => {

        let response = await apiUpdateEntity('accounts',account.id,  values)
        if (response['@type'] === 'hydra:Error') {
            return message.error(t('il y eu une erreur'))
        }
        message.success(t('compte mis à jour avec succes'))
        // console.log(response)
    }

    const postAccount = async (values) => {
        let response = await apiPostEntity('accounts', values)
        message.success(t('compte crée avec succes'))

        if (values.perms && values.perms.length > 0) {
            await handlePerms(response, values.perms)
        }
        console.log('done')
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handlePerms = async (account, perms) => {


        await perms.map(async perm => {
            let data = {
                account  :  account['@id'],
                email    :  perm.email.trim(),
                username :  perm.username.trim(),
                firstName:  perm.firstName.trim(),
                lastName :  perm.lastName.trim(),
                isAdmin  :  true,
                sendMail :  true,
            }

            let response = await apiPostEntity('permissions', data)
        })
    }

    const handleSubmit = () => {
        if (form.current)
            form.current.submit()
    }

    const content = (
        <Form
            {...layout}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            ref={form}
            initialValues={{
                name: account ? account.name : '',
                url: account?.url ? account.url : '',
            }}
        >
            <Form.Item
                label={t("nom du compte")}
                name="name"
                rules={[
                    {
                        required: true,
                        message: t('Le nom est requis !'),
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={t("Site internet")}
                name="url"
            >
                <Input/>
            </Form.Item>
            {
                !account &&
                <>
                    <p>{t('permissions')}</p>
                    <Form.List name="perms" label={t('permissions')}>
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map((field) => (
                                    <div className="form-field" key={field.key}>
                                        <p>{t('nouvel utilisateur')}</p>
                                        <Space  style={{display: 'flex', marginBottom: 8}}
                                               align="baseline">
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'email']}
                                                fieldKey={[field.fieldKey, 'email']}
                                                rules={[
                                                    {required: true, message: t('email requis')},
                                                    {type: 'email', message: t('email non valide!')}
                                                ]}
                                            >
                                                <Input placeholder="Email"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                validateTrigger={"onBlur"}
                                                name={[field.name, 'username']}
                                                fieldKey={[field.fieldKey, 'username']}
                                                rules={[
                                                    {required: true, message: t('entrer un username!')},
                                                    {validator: searchUsername}
                                                ]}
                                            >
                                                <Input placeholder="Username"/>
                                            </Form.Item>
                                        </Space>
                                        <Space style={{display: 'flex', marginBottom: 8}} align="baseline">
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'lastName']}
                                                fieldKey={[field.fieldKey, 'lastName']}
                                                rules={[
                                                    {required: true, message: t('nom requis')},
                                                ]}
                                            >
                                                <Input placeholder={t('nom')}/>
                                            </Form.Item>
                                            <Form.Item
                                                {...{
                                                    labelCol: {span: 0},
                                                    wrapperCol: {span: 24},
                                                }}
                                                name={[field.name, 'firstName']}
                                                fieldKey={[field.fieldKey, 'firstName']}
                                                rules={[
                                                    {required: true, message: t('prénom requis')},
                                                ]}
                                            >
                                                <Input placeholder={t("prénom")}/>
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                        </Space>

                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        {t('ajouter des permissions')}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </>
            }
        </Form>
    )

    if (modal)
        return (
            <Modal
                destroyOnClose
                visible={isVisible}
                title={account ? `${t('editer')} ${account.name}` :t('créer un compte')}
                onCancel={() => callback()}
                footer={[
                    <Button key="cancel_create" onClick={() => callback(false)}>
                        {t('retour')}
                    </Button>,
                    <Button key="create" type="primary" htmlType="submit" onClick={handleSubmit}>
                        {account ? t('modifier') : t('créer')}
                    </Button>
                ]}>
                {content}
            </Modal>
        )

    return (content)

}