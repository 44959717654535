import React from 'react'
import {Layout, Menu, Space} from 'antd';
import {Link} from "react-router-dom";
import AvatarDropdown from "./AvatarDropdown";

const {Header, Content} = Layout;

export default function ClientLayout({children}) {

    return (
        <Layout className="mainLayout">
            <Header className="header">
                <div className="flex between align-center">
                    <div className="logo"/>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[window.location.pathname]}>
                        <Menu.Item key="/accueil">
                            <Link to="/accueil">Home</Link>
                        </Menu.Item>
                    </Menu>
                </div>
                <Space>
                    <AvatarDropdown/>
                </Space>
            </Header>
            <Content className="flex" style={{padding: '0 50px'}}>
                <Layout className="site-layout-background" style={{padding: '24px 0', maxWidth: '100%'}}>
                    <Content style={{padding: '0 24px', minHeight: 280}}>
                        {children}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}