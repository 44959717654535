import React from "react";
import Button from "./Button";
import {useWidgetContext} from "../../providers/WidgetProvider";
import getSymbolFromCurrency from "currency-symbol-map";

export default function OrderDetails({close}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, lng, i18n} = widgetState;
    const {displayTime, stringDateDMonthY} = widgetDispatch;

    const total = bookingInfos.addons ?
        bookingInfos.offer.price + bookingInfos.addons.map(addon => addon.price*addon.amount).reduce((a, b) => a + b, 0)
        : bookingInfos.offer.price;


    return (
        <div className="order-content">
            <div className="title">
                <h2>{i18n.gettext("Détails tarifaires de votre réservation")}</h2>
                {/*place order infos*/}
                <p>{stringDateDMonthY(bookingInfos.offer.day)}, {displayTime(bookingInfos.offer.timeSlot.start)} à {displayTime(bookingInfos.offer.timeSlot.end)}</p>
                {bookingInfos.nbPeople &&
                <p>{bookingInfos.nbPeople} {i18n.gettext("personnes")}</p>}
            </div>

            <div className="order-details">
                <div className="order-items">
                    <div className="flex between" style={{marginBottom: '11px'}}>
                        <span>{bookingInfos.offer.category.names[lng] ? bookingInfos.offer.category.names[lng] : bookingInfos.offer.category.names[widget.account.defaultLanguage]}</span>
                        <span className="price" style={{color: widget.backgroundColor || '#2494D1'}}
                              title={'prix en ' + widget.account.currency}>
                            {bookingInfos.offer.price} {getSymbolFromCurrency(widget.account.currency)}
                        </span>
                    </div>
                    {/*map on the addons*/}
                    {bookingInfos.addons ? bookingInfos.addons.map(addon => (
                        <div className="flex between" key={addon.id}>
                            <span>{addon.names[lng] ? addon.names[lng] : addon.names[widget.account.defaultLanguage]}</span>
                            <div>
                                <span className="price" style={{color: widget.backgroundColor ||'#2494D1'}}>{addon.amount} x </span>
                                <span className="price" style={{color: widget.backgroundColor ||'#2494D1'}}
                                      title={'prix en ' + widget.account.currency}>
                                    {addon.price} {getSymbolFromCurrency(widget.account.currency)}
                                </span>
                            </div>
                        </div>
                    )) : null}

                </div>
                <div className="flex between">
                    <span className="total">{i18n.gettext("Total dû")}</span>
                    <span className="total" style={{color: widget.backgroundColor || '#2494D1'}}
                          title={'prix en ' + widget.account.currency}>{total} {getSymbolFromCurrency(widget.account.currency)}</span>
                </div>

            </div>
            <div className="paiement-details">
                {i18n.gettext("Le paiement de votre réservation se fera directement à l'hôtel, au moment de votre venue !")}
            </div>
            <div>
                <Button onClick={close} style={{width: '100%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}}>{i18n.gettext("Ok pour moi !")}</Button>
            </div>
        </div>
    )
}