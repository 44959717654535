import React, {useEffect} from "react";
import Button from "./Button";
import {useWidgetContext} from "../../providers/WidgetProvider";
import dayjs from "dayjs";

export default function StockErrorFooter() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, i18n} = widgetState;
    const {goTo, setBookingInfos} = widgetDispatch

    useEffect(()=> {
        const timer = setTimeout(redirect, 5000)

        return () => {
            clearTimeout(timer)
            setBookingInfos({})
        }
    }, [])

    function redirect() {
        let index = 2

        if (dayjs( bookingInfos.date).isBefore(dayjs().format('YYYY-MM-DD'))) {
            index = 1
        }
        goTo(index)
    }


    return (
                <Button onClick={redirect}
                        style={{width: '100%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}}>
                    {i18n.gettext("d'accord")}
                </Button>
    )
}