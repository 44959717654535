import React, {useEffect, useState} from "react";
import {Button, Form, Input, PageHeader, Select} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../providers/ApiProvider";

export default function AdminSetting() {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiPostEntity, apiUpdateEntity} = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [loadingStart, setLoadingStart] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {
        const response = await apiFetchEntity('administrations', 1);

        if (response['@type'] !== 'hydra:Error')
            setData(response);

        setLoadingStart(false);
    }

    function onFinish(values) {
        if (data.id)
            update(values);
        else
            post(values);
    }

    async function post(values) {
        setLoading(true);

        if (values.emails.length)
            values.emails = values.emails.map(email => email.trim());

        const response = await apiPostEntity('administrations', values);
        setData(response);
        setLoading(false);
    }

    async function update(values) {
        setLoading(true);

        if (values.youtubeId.includes('youtube'))
            values.youtubeId = values.youtubeId.split('=').pop()

        if (values.emails.length)
            values.emails = values.emails.map(email => email.trim());

        const response = await apiUpdateEntity('administrations', data.id, values);
        setData(response);
        setLoading(false);
    }

    return (
        <div>
            <PageHeader
                title={t("Réglages")}
            />

            {loadingStart ? null :
                <Form
                    layout="vertical"
                    initialValues={{
                        emails: data.emails,
                        youtubeId: data.youtubeId
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={t('email')}
                        tooltip={t('Liste d\'emails a prévenir pour les inscriptions')}
                        name="emails"
                    >
                        <Select
                            mode="tags"
                            style={{width: 300}}
                            placeholder="emails"
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={t('Vidéo landing page')}
                        tooltip={t('Url ou id de la video Youtube qui apparait sur la landing page')}
                        name="youtubeId"
                    >
                        <Input style={{width: 300}}/>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            {t('Valider')}
                        </Button>
                    </Form.Item>
                </Form>}
        </div>
    )
}