import React from 'react'
import {ShadowView} from "./ShadowRoot";
import {WidgetProvider} from "../providers/WidgetProvider";
import Popup from "../widget/Popup";

export default function Preview({id, debug = true, local = false}) {

    return (
        <ShadowView>
            <div id="__resaday__" className="__breakingday__">
                <WidgetProvider>
                    <Popup widgetId={id} debug={debug} local={local}/>
                </WidgetProvider>
            </div>
        </ShadowView>
    )
}