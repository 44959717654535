import React from 'react';

export default function BigLogo({style}) {
    return (
        <div style={{
            background: "right 100px no-repeat url(https://api.hapidam.com/share/1b49c142-0c9a-4f1f-9ef2-e0cf5e5ef61e/resaday-logo.png?size=lg)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: '.1',
            zIndex: 1
        }}>

        </div>
    )
}