import React from 'react'
import { ReactComponent as CheckCircle } from '../svg/check--circle.svg'
import { ReactComponent as Copy } from '../svg/copy.svg'
import BigLogo from "../svg/BigLogo";
import Button from "../component/Button";
import { useWidgetContext } from "../../providers/WidgetProvider";
import { ReactComponent as Close } from '../svg/close.svg'
import getSymbolFromCurrency from "currency-symbol-map";

export default function EndStep({ }) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { widget, bookingId, i18n, bookingInfos } = widgetState;
    const { setVisible, resetBook } = widgetDispatch;

    function copyId() {
        const textArea = document.createElement('textarea');
        textArea.textContent = bookingId;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    const total = bookingInfos.addons ?
        bookingInfos.offer.price + bookingInfos.addons.map(addon => addon.price * addon.amount).reduce((a, b) => a + b, 0)
        : bookingInfos.offer.price;

    return (
        <div style={{
            minHeight: '100%',
            backgroundColor: widget.backgroundColor || '#2494D1',
            padding: '57px 20px 20px',
            textAlign: 'center',
            color: "white",
        }}>
            <BigLogo />
            <div
                className="close"
                onClick={() => setVisible(false)}
            >
                <Close />
            </div>
            <div style={{ zIndex: '2', position: 'relative' }}>
                <div style={{ marginBottom: '39px' }}>
                    <CheckCircle style={{ maxWidth: "55px", fill: 'white' }} />
                </div>
                <div style={{ fontWeight: "bold", padding: '0 30px', fontSize: '16px' }}>
                    <p style={{ marginBottom: '16px' }}>{i18n.gettext('Merci !')}</p>
                    <p style={{ marginBottom: '16px' }}>
                        {i18n.gettext("Votre réservation a bien été prise en compte.")}&nbsp;
                        {i18n.gettext("Un e-mail vous a été envoyé.")}
                        <span style={{ fontWeight: "normal" }}>({widgetState.user.email})</span>
                    </p>
                    <p>{i18n.gettext("Votre numéro de réservation est le")} <span id={`brd_bookId_${widget.id}`}>{bookingId}</span>.</p>
                </div>
                <Button style={{ color: widget.backgroundColor || '#2494D1' }} className="code-button" onClick={copyId}>
                    <div className="icon-button" style={{ backgroundColor: widget.backgroundColor || '#2494D1' }}>
                        <Copy style={{ stroke: 'white', width: '50%' }} />
                    </div>
                    <span>
                        {i18n.gettext("Copier ce numéro dans le presse papier.")}
                    </span>
                </Button>
                <div className="paiement-info">
                    <p style={{ display: total > 0 ? 'none' : 'initial' }}>{i18n.gettext("Votre réservation s'élève à un total de")} <span id={`brd_book-total_${widget.id}`} className="booking-total-price">{total}</span> {getSymbolFromCurrency(widget.account.currency)}</p>
                    <p>
                        {i18n.gettext("Le paiement de votre réservation se fera directement à l'hôtel, au moment de votre venue")}
                    </p>
                </div>
                <Button
                    style={{ width: '100%', color: widget.backgroundColor || '#2494D1' }}
                    onClick={resetBook}
                    id={`brd_retry_${widget.id}`}
                >
                    {i18n.gettext("Réserver une autre plage horaire")}
                </Button>
            </div>
        </div>
    )
}