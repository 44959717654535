import React from 'react';
import {useWidgetContext} from "../../providers/WidgetProvider";
import Button from "../component/Button";

export default function SimpleNextStepBtn({onClick, disabled}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {bookingInfos, widget, index, i18n} = widgetState;
    const {next} = widgetDispatch;

    const id = widget.displayTags ?
    [ "service_type", "date", "service", "addons", "end" ]
    : [ "date", "service", "addons", "end" ];

    const wordings = widget.displayTags ?
        [
           "Choisir ma date", "Choisir mon créneau horaire", "Choisissez un service",
            "Finaliser votre réservation", "Terminer"]
        :
        ["Choisir mon créneau horaire", "Choisissez un service",
            "Finaliser votre réservation", "Terminer"];

    function nextStep() {
        if (typeof onClick === 'function')
            onClick();
        next();
    }

    function resolveText(text) {
        if (bookingInfos.offer?.categoryTimeslot?.id && text === "Choisissez un service" && !disabled) {
            return "Choisir l’heure et les options"
        } else if (text === "Choisissez un service" && !disabled) {
            return "Ajouter des options"
        } else if (disabled && text === "Finaliser votre réservation") {
            return "Choississez un horaire"
        }

        return text
    }

    return (
        <Button
            className="simple_next_step_btn"
            style={{
                backgroundColor: widget.backgroundColor ||"#2494D1",
                color: '#fff',
            }}
            onClick={nextStep}
            disabled={disabled}
            id={`brd_${id[index-1]}_${widget.id}`}
        >
            {i18n.gettext(resolveText(wordings[index - 1]))}
        </Button>
    )
}