import React from 'react';
import {Dropdown, Menu, Tag} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import {useTranslation} from "react-i18next";

export default function BookingCell({offer, books, showModal, showBookingModal, account}) {

    const place = offer.status ? offer.quantity - books.length : 0;
    const {t} = useTranslation();

    function handleClick(e) {
        if (e.key === 'showBookingModal')
            showBookingModal(offer.id);
    }

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="showBookingModal" disabled={place < 1 || offer.status !== 'available'}>{t('Faire une réservation')}</Menu.Item>
            <Menu.Item key="place">{t('Places disponibles')}: {place}</Menu.Item>
            <Menu.Item key="price">{t('Prix')}: {offer.price} {getSymbolFromCurrency(account.currency)}</Menu.Item>
        </Menu>
    )

    return (
        <div>
            {books.filter(booking => booking.status !== 'canceled').map(book => (
                <Tag
                    key={book.id}
                    style={{cursor: 'pointer', margin: 2}}
                    onClick={e => showModal(e, books)}
                >
                    {
                        book.firstname || book.lastName ?
                            `${book.firstname} ${book.lastname}`
                            : 'No name'
                    }
                </Tag>
            ))}
            <Dropdown overlay={menu} trigger={['click']}>
                <Tag
                    style={{cursor: 'pointer', margin: 2}}
                >
                    +
                </Tag>
            </Dropdown>
        </div>
    )
}