import React from "react"
import {CopyOutlined} from "@ant-design/icons";
import {Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../providers/AccountProvider";

export default function ModalScript({visible, setVisible}) {

    const {t} = useTranslation();
    const [accountState] = useAccountContext();
    const {account} = accountState;

    function copyCode(id) {
        const copyText = document.getElementById(id).textContent;
        const textArea = document.createElement('textarea');
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        message.success(t('texte copié'));
        document.body.removeChild(textArea);
    }

    return (
        <Modal
            width={1200}
            title={t("importer le script depuis votre site")}
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
        >
            <div>
                <h4>{t('méthode 1')}</h4>
                <p><strong>{t('balise script à placer dans la balise head de votre site')}</strong></p>
                <div>
                    <pre id="script-tag" style={{fontSize: '0.9em', backgroundColor: '#eee', padding: 5}}>
                        {`<script type="text/javascript" src="https://resaday.mmcreation.com/widget.js" id="__resaday_Ul^HwX$gqxUG__" data-account="${account.id}" async></script>`}
                    </pre>
                    <p>
                        <Button type="link" icon={<CopyOutlined/>} onClick={() => copyCode('script-tag')}>
                            {t('copier le code')}
                        </Button>
                    </p>
                </div>
            </div>

            <div>
                <h4>{t('méthode 2')}</h4>
                <p><strong>{t('code javascript')}</strong></p>
                <div>
                    <pre id="javascript-code" style={{fontSize: '0.9em', backgroundColor: '#eee', padding: 5}}>
{`(function (window, document) {
    let elt = document.createElement('script');
    elt.type = 'text/javascript';
    elt.async = true;
    elt.src = 'https://resaday.mmcreation.com/widget.js';
    elt.id = "__resaday_Ul^HwX$gqxUG__";
    elt.setAttribute('data-account', '${account.id}');
    var before = document.getElementsByTagName('script')[0];
    before.parentNode.insertBefore(elt, before);
})(window, document)`}
                    </pre>
                    <p>
                        <Button type="link" icon={<CopyOutlined/>} onClick={() => copyCode('javascript-code')}>
                            {t('copier le code')}
                        </Button>
                    </p>
                </div>
            </div>
        </Modal>
    )
}