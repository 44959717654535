import React, {useEffect, useState} from "react";
import {useAccountContext} from "../providers/AccountProvider";
import {useApiContext} from "../providers/ApiProvider";
import {Button, message, PageHeader, Space, Spin, Switch, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import CollectionTable from "../components/CollectionTable";
import {CopyTwoTone, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {WidgetForm} from "../components/Widgtets/WidgetForm";
import ModalScript from "../components/Modal/ModalScript";
import ModalFreemium from "../components/Modal/ModalFreemium";

export default function Widgets() {

    const {t} = useTranslation();
    const [accountState] = useAccountContext();
    const {account} = accountState;
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity, apiUpdateEntity, apiFetchSubResource} = apiDispatch;

    const [needUpdate, setNeedUpdate] = useState(0);

    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);
    const [total, setTotal] = useState(0);
    const [totalCreate, setTotalCreate] = useState(0);

    const [freemiumVisible, setFreemiumVisible] = useState(false);

    useEffect(() => {
        fetch();
    }, [needUpdate, totalCreate])

    async function fetch() {
        const response = await apiFetchSubResource('accounts', {id: account.id}, 'widgets');
        setTotal(response['hydra:totalItems']);
    }

    const columns = [
        {
            title: t('nom'),
            dataIndex: 'name',
            render: name => name
        },
        {
            title: t('Bouton visible'),
            dataIndex: 'isTrigger',
            render: isTrigger => isTrigger ? 'non' : 'oui'
        },
        {
            title: 'Page du widget',
            dataIndex: 'name',
            render: (name, record) => <a href={`/${slugify(account.name)}/${account.id}/${record.id}`} target="_blank">Voir la page</a>
        },
        {
            title: t('actif'),
            dataIndex: 'isActive',
            render: (isActive, record) =>
                <Switch
                    size="small" defaultChecked={isActive}
                    onChange={checked => handleChange(checked, record.id)}
                />

        },
        {
            title: t('action'),
            dataIndex: 'id',
            render: (id, record) =>
                <div>
                    <Space>
                        <Tooltip title={t('Editer')}>
                            <EditTwoTone onClick={() => setSelectedItem(record)}/>
                        </Tooltip>
                        {total > 1 && <Tooltip title={t('Supprimer')}>
                            <DeleteTwoTone
                                twoToneColor="#E64D3D"
                                onClick={() => remove(id)}
                            />
                        </Tooltip>}
                    </Space>
                </div>
        }
    ]

    // Slugity a string
    function slugify(str)
    {
        str = str.replace(/^\s+|\s+$/g, '');

        // Make the string lowercase
        str = str.toLowerCase();

        // Remove accents, swap ñ for n, etc
        var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
        var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        // Remove invalid chars
        str = str.replace(/[^a-z0-9 -]/g, '')
            // Collapse whitespace and replace by -
            .replace(/\s+/g, '-')
            // Collapse dashes
            .replace(/-+/g, '-');

        return str;
    }

    async function handleChange(checked, id) {
        const data = {isActive: checked};
        await apiUpdateEntity('widgets', id, data);
    }

    async function remove(id) {
        setLoading(true);
        await apiDeleteEntity('widgets', id);
        setNeedUpdate(needUpdate + 1);
        setLoading(false);
    }

    function handleCreationFormClose(hasCreate = false) {
        setSelectedItem(null);
        setCreationMode(false);

        if (hasCreate)
            setTotalCreate(totalCreate => totalCreate + 1);
    }

    function copyCode(id) {
        const copyText = document.getElementById(id).textContent;
        const textArea = document.createElement('textarea');
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        message.success(t('texte copié'));
        document.body.removeChild(textArea);
    }

    function wantCreate() {
        if (!account.isPremium && total >= 1)
            setFreemiumVisible(true);
        else
            setCreationMode(true);
    }

    if (selectedItem || creationMode)
        return <WidgetForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <div>
            <PageHeader
                title={<h1>{t('Widgets')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />

            <div>
                <div style={{
                    padding: '10px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    maxWidth: '100%'
                }}>
                    <Space style={{maxWidth: '100%'}} wrap>
                        <pre id="script-tag"
                             style={{fontSize: '0.9em', backgroundColor: '#eee', padding: 5, whiteSpace: 'normal'}}>
                            {`<script type="text/javascript" src="https://resaday.mmcreation.com/widget.js" id="__resaday_Ul^HwX$gqxUG__" data-account="${account.id}" async></script>`}
                        </pre>

                        <Tooltip title={t('Copier')}>
                            <CopyTwoTone onClick={() => copyCode('script-tag')}/>
                        </Tooltip>

                        <Tooltip title={t('Voir script')}>
                            <EyeTwoTone onClick={() => setVisible(true)}/>
                        </Tooltip>
                    </Space>
                    <div>
                        <Button
                            type="primary"
                            size="large"
                            onClick={wantCreate}
                        >
                            {t('Creer')}
                        </Button>
                    </div>
                </div>

                <Spin spinning={loading}>
                    <CollectionTable
                        endpoint={`accounts/${account.id}/widgets`}
                        columns={columns}
                        reload={needUpdate}
                    />
                </Spin>
            </div>

            {visible && <ModalScript visible={visible} setVisible={setVisible}/>}
            <ModalFreemium visible={freemiumVisible} setVisible={setFreemiumVisible}/>
        </div>
    )
}