import React, {useState} from 'react'
import {useAccountContext} from "../providers/AccountProvider";
import {Col, PageHeader, Row, Space, Menu, Dropdown} from "antd";
import ReservationDash from "../components/Reservation/ReservationDash";
import PriceDash from "../components/Prices/PriceDash";
import {Link} from "react-router-dom";
import {SettingOutlined} from "@ant-design/icons";
import ModalScript from "../components/Modal/ModalScript";
import AccountPermissions from "../components/Account/AccountPermissions";
import {useTranslation} from "react-i18next";
import AccountStatistic from "../components/Statistics/AccountStatistic";
import WidgetsStatistics from "../components/Statistics/WidgetsStatistics";

export default function Dashboard() {

    const [accountState] = useAccountContext();
    const [scriptVisible, setScriptVisible] = useState(false);
    const [permissionVisible, setPermissionVisible] = useState(false);
    const {t} = useTranslation();

    const settings = (
        <Menu>
            <Menu.Item key="script">
                <Link to="/widgets">{t('Widgets')}</Link>
            </Menu.Item>
            <Menu.Item key="permission" onClick={() => setPermissionVisible(true)}>
                <span>{t('Permissions')}</span>
            </Menu.Item>
            <Menu.Item key="emails">
                <Link to="/emails">{t('Emails')}</Link>
            </Menu.Item>
            <Menu.Item key="settings">
                <Link to="/account">{t('Paramétrage')}</Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <PageHeader
                title={
                    <Space>
                        <h1>{accountState.account.name}</h1>
                        <Dropdown overlay={settings} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <SettingOutlined/>
                            </a>
                        </Dropdown>
                    </Space>}
                style={{borderBottom: '2px solid #2494D1'}}
            />

            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} style={{marginTop: "2em"}}>
                <Col className="gutter-row" span={24} lg={24} xl={16}>
                    <ReservationDash id={accountState.account.id}/>

                    <AccountStatistic/>

                    <WidgetsStatistics/>
                </Col>

                <Col className="gutter-row" span={24} md={16} lg={12} xl={8}>
                    <PriceDash id={accountState.account.id}/>
                </Col>
            </Row>

            {scriptVisible ?
                <ModalScript
                    visible={scriptVisible}
                    setVisible={setScriptVisible}
                    accountId={accountState.account.id}
                /> : null}

            {permissionVisible &&
            <AccountPermissions account={accountState.account} setAccount={setPermissionVisible} modal/>}
        </>
    )
}