import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Preview from "../components/Preview";
import {useApiContext} from "../providers/ApiProvider";
import {Result} from "antd";

export default function Booking() {

    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic} = apiDispatch;

    const [widgetId, setWidgetId] = useState()
    const [image, setImage] = useState()
    const [error, setError] = useState(false)

    const params = useParams();

    useEffect(()=> {
        fetch()
    }, [])

    async function fetch() {
        let data = {
            accountId: params.accountId,
            widgetId: params.widgetId,
        }
        let response = await apiPostEntityPublic('public/check-widget', data)

        if (!response.shouldRedirect) {
            setWidgetId(data.widgetId)
            setImage(response.image)
        } else if (response.url) {
            window.location.href = `//${response.url.replace(/http(s)?:\/\//g, '')}`
        } else {
               setError(true)
        }
    }


    return (
        <div style={{backgroundSize: 'cover',backgroundImage: `url(${image?.display?.native?.lg})`, minWidth: '100vw', minHeight: '100vh'}}>
            {
                widgetId &&
                    <Preview id={widgetId} debug={false} local={true}/>
            }
            {
                error &&
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                    />
            }
        </div>
    )
}