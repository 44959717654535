import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import BookingCell from "./BookingCell";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {displayTime, getTimeSlots, resolveNamesLanguage} from "../utils";

export default function DurationCategory({
                                             looping,
                                             needUpdate,
                                             currentDay,
                                             setLoading,
                                             showModal,
                                             showBookingModal,
                                             setSelectedCategory,
                                             category,
                                             bookings
                                         }) {

    const {t, i18n} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;

    const [timeSlots, setTimeSlots] = useState([]);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        if (category)
            getTimes();
    }, [category])

    async function getTimes() {
        let array = getTimeSlots(category.timeSlots[0].start, category.timeSlots[0].end, category.duration, category.breaktime);
        setTimeSlots(array);
    }

    useEffect(() => {
        const {after, before} = getInterval();
        fetch(after, before);
    }, [needUpdate, currentDay])

    async function fetch(after, before) {
        setLoading(true);

        const data = {
            filters: [
                {name: 'day[after]', value: after},
                {name: 'day[before]', value: before},
                {name: 'category.id', value: category.id}
            ]
        }

        const response = await apiFetchCollection('activity-offers', data);
        setOffers(response['hydra:member'].map(item => {
            item.day = dayjs(item.day).format('DD-MM-YYYY');
            return item;
        }));

        setLoading(false);
    }

    function getInterval() {
        let date = dayjs(currentDay);

        const after = date.format('YYYY-MM-DD');
        const before = date.add(7, 'day').format('YYYY-MM-DD');

        return {after, before};
    }

    return (
        category.id ?
            <div key={category.name}>
                <div className="category-cell">
                    <span>{resolveNamesLanguage(i18n, accountState, category)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>({displayTime(i18n.language, category.timeSlots[0].start)} - {displayTime(i18n.language, category.timeSlots[0].end)})</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button type="link" onClick={() => setSelectedCategory(null)}><strong>{t('retour')}</strong></Button>
                </div>

                <table className="header-table">
                    <tbody>
                    {timeSlots.map((timeSlot, id) => (
                        <tr key={id} className="table-row">
                            <td
                                className="ant-table-cell"
                                style={{width: 20, fontSize: 12}}
                            >
                                <small>{displayTime(i18n.language, timeSlot.split('-')[0])}-{displayTime(i18n.language, timeSlot.split('-')[1])}</small>
                            </td>

                            {looping.map((value, index) => {
                                let day = dayjs(currentDay).add(index, 'days').format('DD-MM-YYYY')
                                let dayNumber = dayjs(currentDay).add(index, 'days').day()
                                let offer = offers.find(offer => offer.day === day && `${offer.timeSlot.start}-${offer.timeSlot.end}` === timeSlot) || {
                                    day: day,
                                    id: null,
                                    price: 0
                                }

                                const books = bookings.filter(booking => dayjs(booking.bookAt).format('DD-MM-YYYY') === offer.day && booking.offer === `/api/offers/${offer.id}` && booking.status !== 'canceled');
                                const isAvailable = offer.status === 'available' && offer.quantity > 0;

                                let backgroundColor = '#f50';
                                if (isAvailable && (offer.quantity - books.length) === 0)
                                    backgroundColor = '#2db7f5';
                                else if (isAvailable)
                                    backgroundColor = '#87d068';

                                return (
                                    <td
                                        className={dayNumber > 4 || dayNumber === 0 ? 'table-cell weekend' : 'table-cell'}
                                        style={{
                                            padding: 0,
                                            width: 40,
                                            textAlign: 'center',
                                            fontSize: 12,
                                            backgroundColor: backgroundColor
                                        }}
                                        key={timeSlot + day}
                                    >
                                        <BookingCell
                                            offer={offer}
                                            books={books}
                                            showModal={showModal}
                                            showBookingModal={showBookingModal}
                                            account={accountState.account}
                                        />
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div> : null
    )
}