import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuthContext} from '../../providers/AuthProvider.js';
import Layout from '../Layout/Private/Layout.js';

const PrivateRoute = ({component: Component, sidebar, searchbar,   ...rest}) => {

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const {authIsLogged, authLogout} = authDispatch

    const renderer = (sidebar,props) => {
        if (authIsLogged() && auth.roles.includes('ROLE_ADMIN'))
            return <Layout {...props}><Component {...props} /></Layout>;

        authLogout();
        window.location.href = '/login-admin';
    }

    return <Route {...rest} render={renderer.bind(null,sidebar)} />;
};

export default PrivateRoute;
