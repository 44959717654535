import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";

export default function TagSelect({selectedTags, setSelectedTags}) {

    const [accountState] = useAccountContext();
    const {account} = accountState;
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource} = apiDispatch;
    const {t} = useTranslation();

    const [tags, setTags] = useState([]);

    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        const data = {
            id: account.id,
            filters: [
                {name: 'exists[categories]', value: true}
            ]
        }

        const response = await apiFetchSubResource('accounts', data, 'cat_tags');

        setTags(response['hydra:member']);
    }

    return (
        <Select
            mode="multiple"
            style={{width: 300}}
            placeholder={t("Sélectionner un tag")}
            optionLabelProp="label"
            maxTagCount='responsive'
            optionFilterProp="label"
            value={selectedTags}
            onChange={values => setSelectedTags(values)}
        >
            {tags.map(tag => (
                <Select.Option
                    key={tag.id}
                    value={tag['@id']}
                    label={tag.name}
                >
                    {tag.name}
                </Select.Option>
            ))}
        </Select>
    )
}