import React from 'react';
import {useWidgetContext} from "../../providers/WidgetProvider";
import {ReactComponent as Left} from '../svg/left.svg'
import {ReactComponent as Close} from '../svg/close.svg'

export default function Header() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {index, widget, isDrawerVisible, bookingInfos, local, i18n} = widgetState;
    const {prev, setVisible} = widgetDispatch;

    const wordings = widget.displayTags ?
        [
            {prev: i18n.gettext('Accueil'), title: i18n.gettext('Type de service')},
            {prev: i18n.gettext('Type de service'), title: i18n.gettext('Date de réservation')},
            {prev: i18n.gettext('Date de réservation'), title: i18n.gettext('Votre service')},
            {prev: i18n.gettext('Votre service'), title: i18n.gettext('Vos extras')},
            {prev: i18n.gettext('Vos extras'), title: i18n.gettext('Confirmation de reservation')},
            {prev: i18n.gettext('Récapitulatif'), title: i18n.gettext('Et en dernier...')}
        ]
            :
        [
        {prev: i18n.gettext('Accueil'), title: i18n.gettext('Date de réservation')},
        {prev: i18n.gettext('Date de réservation'), title: i18n.gettext('Votre service')},
        {prev: i18n.gettext('Votre service'), title: i18n.gettext('Vos extras')},
        {prev: i18n.gettext('Vos extras'), title: i18n.gettext('Confirmation de reservation')},
        {prev: i18n.gettext('Récapitulatif'), title: i18n.gettext('Et en dernier...')}
    ]

    if (index < 1 || index > wordings.length - 1 ) return null

    function resolveText(text) {
        if (bookingInfos.offer?.categoryTimeslot?.id && text === "Vos extras") {
            return i18n.gettext("Votre créneau et extras")
        }

        return text
    }

    return (
        <div
            className="__resaday_modal_header__"
            style={{
                backgroundColor: widget.backgroundColor || "#2494D1",
                color: "#fff",
                zIndex: isDrawerVisible ? 1: 100
            }}
        >
            <div
                className="prev"
                onClick={prev}
                id={`brd_back_${widget.id}`}
            >
                <Left/> <small className="smaller">{wordings[index - 1].prev}</small>
            </div>
            {
                !local &&
                <div
                    className="close"
                    onClick={() => setVisible(false)}
                    id={`brd_close_${widget.id}`}
                >
                    <Close/>
                </div>
            }

            <div className="title">
                {resolveText(wordings[index - 1].title)}
            </div>

            <div className="progress_container">
                <div className="step">
                    <small className="smaller">{i18n.gettext('ETAPE')} {index} / 5</small>
                </div>
                <div
                    className="progress"
                    style={{
                        border: '1px solid white',
                    }}
                >
                    <div
                        className="progress_content"
                        style={{
                            backgroundColor: "#fff",
                            width: `${index / 5 * 100}%`
                        }}
                    />
                </div>
            </div>
        </div>
    )
}