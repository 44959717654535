import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Form, Input, Select} from "antd";


const User = React.forwardRef(({offer, timeslot, user, setUser, arrivalHour, setArrivalHour, setCurrentStep}, ref) => {

    const {t} = useTranslation();
    const [hours, setHours] = useState([]);

    useEffect(() => {

        const start = Number(timeslot.start.split('h').shift());
        const end = Number(timeslot.end.split('h').shift());
        const diff = end - start;

        for (let i = 0; i < diff; i++) {
            hours.push(`${start + i}h00`);
            hours.push(`${start + i}h30`);
        }

        setHours([...hours]);
    }, [timeslot])

    async function onFinish(values) {

        let userData = {
            email: values.email.trim(),
            lastname: values.lastname ? values.lastname.trim() : null,
            firstname: values.firstname ? values.firstname.trim() : null,
            phone: values.phone ? values.phone.trim() : null,
        }
        setUser(userData)
        setArrivalHour(values.arrivalHour)
        setCurrentStep(2)

    }

    return (
        <div>
            <div className="confirm-tab">
                <div className="confirm-tab-title">
                    <div className="background-transparent"
                         style={{backgroundColor: '#2494D1'}}/>
                    {t('Informations client')} :
                </div>
                <div className="confirm-tab-content">

                    <Form
                        name="booking"
                        ref={ref}
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '33',
                            email: user.email || '',
                            firstname: user.firstname || '',
                            lastname: user.lastname || '',
                            phone: user.phone || '',
                            arrivalHour: user.arrivalHour || null,
                        }}
                    >
                        <Form.Item name="email"
                                   rules={[{type: 'email', required: true, message: t('L\'email est requis')}]}>
                            <Input placeholder={t("Email")}/>
                        </Form.Item>
                        <Form.Item name="firstname" rules={[{required: true, message: t('Le prénom est requis')}]}>
                            <Input placeholder={t("Prénom")}/>
                        </Form.Item>
                        <Form.Item name="lastname" rules={[{required: true, message: t('Le nom est requis')}]}>
                            <Input placeholder={t("Nom")}/>
                        </Form.Item>
                        <Form.Item name={t("Téléphone")}>
                            <Input placeholder="06 29 69 56 46" style={{width: '100%'}}/>
                        </Form.Item>

                        {
                            !offer.categoryTimeslot &&
                            <Form.Item name="arrivalHour">
                                <Select
                                    allowClear
                                    style={{width: 200, margin: '1em 0'}}
                                    placeholder={t("Heure d'arrivée")}
                                    value={arrivalHour}
                                    onChange={setArrivalHour}
                                >
                                    {hours.map(hour => (
                                        <Select.Option key={hour} value={hour}>{hour}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        }
                    </Form>
                </div>
            </div>

        </div>
    )
})

export default User