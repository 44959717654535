import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import SelectableCell from "../components/SelectableCell";
import {Button, InputNumber, PageHeader, Result, Select, Space, Spin} from "antd";
import {ArrowLeftOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {
    displayTime, getTimeSlots,
    handleClass, localiseDate,
    resolveNamesLanguage, startSelection
} from "../components/utils";
import {DatePicker} from "../components";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useApiContext} from "../providers/ApiProvider";
import {useAccountContext} from "../providers/AccountProvider";
import ModalBookings from "../components/Modal/ModalBookings";
import {PriceFormDuration} from "../components/Prices/PriceFormDuration";
import getSymbolFromCurrency from "currency-symbol-map";

const looping = Array.from({length: 31}); // create an empty array with length 30 to loop
const today = dayjs().format('YYYYMMDD')

export default function Category() {

    const {t, i18n} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiFetchEntity, apiFetchSubResource, apiPostEntity} = apiDispatch;
    const [accountState] = useAccountContext();

    const [nbSelected, setNbSelected] = useState(0);
    const [selection, setSelection] = useState(null);

    const [loading, setLoading] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(null);
    const [currentDay, setCurrentDay] = useState(today);
    const [timeSlots, setTimeSlots] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [offers, setOffers] = useState([]);
    const [category, setCategory] = useState({id: params.id});
    const [visible, setVisible] = useState(false);
    const [selectedBookings, setSelectedBookings] = useState([]);

    const [price, setPrice] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [status, setStatus] = useState(null);
    const [longVisible, setLongVisible] = useState(false);

    const [displayError, setDisplayError] = useState(false);

    useEffect(() => {
        fetchCategory();
    }, [])

    async function fetchCategory() {
        const response = await apiFetchEntity('categories', params.id);
        setCategory(response)

        if (response.type !== 'duration')
            return setDisplayError(true);

        let array = getTimeSlots(response.timeSlots[0].start, response.timeSlots[0].end, response.duration, response.breaktime);

        setTimeSlots(array);
    }

    useEffect(() => {
        const {after, before} = getInterval();
        fetch(after, before);
        fetchBookings(after, before);
    }, [needUpdate, currentDay])

    useEffect(() => {
        if (!selection)
            setSelection(startSelection(onSelectionEnd))
    }, [])

    function handleClose() {
        setLongVisible(false)
        setNeedUpdate(needUpdate + 1)
    }

    async function fetch(after, before) {
        setLoading(true);
        clear();

        const data = {
            filters: [
                {name: 'day[after]', value: after},
                {name: 'day[before]', value: before},
                {name: 'category.id', value: params.id}
            ]
        }

        const response = await apiFetchCollection('activity-offers', data);
        setOffers(response['hydra:member'].map(item => {
            item.day = dayjs(item.day).format('DD-MM-YYYY');
            return item;
        }));
        setLoading(false);
    }

    const onSelectionEnd = (selected) => {
        setNbSelected(selected.length)
    }

    function handleSelect(e, selector) {
        selection.resolveSelectables()

        if (!e.ctrlKey && !e.metaKey) {
            let selectedItems = selection.getSelection()
            handleClass(selectedItems, 'remove')
            selection.clearSelection();
        }

        selection.select(selector)
        selection.keepSelection();
        let selectedItems = selection.getSelection()
        handleClass(selectedItems, 'add')
        setNbSelected(selectedItems.length)
    }

    function clear() {
        if (selection) {
            let selectedItems = selection.getSelection()
            handleClass(selectedItems, 'remove')

            selection.clearSelection()
            setNbSelected(0)
        }
    }

    function showModal(event, books) {
        event.stopPropagation();

        setSelectedBookings(books);
        setVisible(true);
    }

    function prevMonth() {
        let prev = dayjs(currentDay).subtract(31, "days")
        let today = dayjs()
        if (today.isAfter(prev))
            prev = today
        handleDateChange(prev)
    }

    function nextMonth() {
        let next = dayjs(currentDay).add(31, "days")
        handleDateChange(next)
    }

    function handleDateChange(value) {
        setCurrentDay(value.format('YYYYMMDD'))
    }

    async function updatePrice() {
        let selectedItems = selection.getSelection();

        let offers = selectedItems.map(item => {
            const books = bookings.filter(booking => dayjs(booking.bookAt).format('DD-MM-YYYY') === item.getAttribute('data_day') && booking.offer === `/api/offers/${item.getAttribute('data_id')}`).length;

            return {
                id: item.getAttribute('data_id'),
                category: item.getAttribute('data_category'),
                timeSlot: item.getAttribute('data_timeslot'),
                day: item.getAttribute('data_day'),
                price: price !== null ? price : item.getAttribute('data_price'),
                status: status || item.getAttribute('data_status'),
                quantity: quantity !== null ? (quantity + books) : item.getAttribute('data_quantity')
            }
        })

        setLoading(true);
        let response = await apiPostEntity('update-activity-offers', {offers});
        setNeedUpdate((new Date()).getTime());
        setPrice(null);
        setQuantity(null);
        setStatus(null);
    }

    async function fetchBookings(after, before) {
        const data = {
            id: accountState.account.id,
            filters: [
                {name: 'bookAt[after]', value: after},
                {name: 'bookAt[before]', value: before},
                {name: 'pagination', value: false},
                {name: 'categoryId', value: params.id}
            ]
        }
        const response = await apiFetchSubResource('accounts', data, 'bookings');

        setBookings(response['hydra:member']);
    }

    function getInterval() {
        let date = dayjs(currentDay);

        const after = date.format('YYYY-MM-DD');
        const before = date.add(30, 'day').format('YYYY-MM-DD');

        return {after, before};
    }

    function goBack() {
        history.push('/prix');
    }

    if (displayError) {
        return (
            <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
                extra={<Button type="primary" onClick={goBack}>Back Home</Button>}
            />
        )
    }

    return (
        <Spin spinning={loading}>
            <PageHeader
                title={category.name && <h1>{resolveNamesLanguage(i18n, accountState, category)}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            <div className="price-table-options">
                <Space direction="vertical">
                    <Button
                        type="link"
                        size="small"
                        onClick={() => history.push('/prix')}
                        icon={<ArrowLeftOutlined/>}
                    >
                        {t('Retour au tableau principal')}
                    </Button>

                    <Space>
                        <DatePicker onChange={handleDateChange} format={'DD-MM-YYYY'}
                                    allowClear={false}
                                    value={dayjs(currentDay)}/>
                        {nbSelected > 0 && <Button onClick={clear}>{t('tout déselectionner')}</Button>}

                    </Space>

                    <div style={{minHeight: '65px'}}>
                        <Space direction="vertical" style={{display: nbSelected ? 'block' : 'none'}}>
                            <div>
                                {nbSelected} {t('prix sélectionné(s)')}, {t('vos prix sont en ')}
                                {accountState.account.currency} ({getSymbolFromCurrency(accountState.account.currency)})
                            </div>

                            <Space>
                                <InputNumber
                                    min={0}
                                    value={price}
                                    onChange={setPrice}
                                    onPressEnter={updatePrice}
                                    placeholder={t("prix")}
                                />
                                <InputNumber
                                    min={0}
                                    value={quantity}
                                    onChange={setQuantity}
                                    placeholder={t('quantité')}
                                    onPressEnter={updatePrice}
                                />
                                <Select
                                    style={{width: 200}}
                                    placeholder={t("statut")}
                                    value={status}
                                    onChange={setStatus}
                                >
                                    <Select.Option value="available">{t('disponible')}</Select.Option>
                                    <Select.Option value="notAvailable">{t('non disponible')}</Select.Option>
                                </Select>
                                <Button onClick={updatePrice}>{t('valider')}</Button>
                            </Space>
                        </Space>
                    </div>
                </Space>
                <Button onClick={() => setLongVisible(true)} type={'primary'}>{t('prix long terme')}</Button>
                <PriceFormDuration modal onClose={handleClose} visible={longVisible} category={category}/>

            </div>

            <div className={dayjs().isBefore(dayjs(currentDay)) ? "flex between" : "flex justify-end"}>
                {
                    dayjs().isBefore(dayjs(currentDay)) &&
                    <Button onClick={prevMonth} icon={<LeftOutlined/>}
                            title={"Reculer d'un mois"}/>
                }
                <Button onClick={nextMonth} icon={<RightOutlined/>}
                        title={"Avancer d'un mois"}/>
            </div>
            <div style={{textAlign: 'right'}}>
                <small>*{t('maintenez ctrl pour ajouter à la selection')}</small>
            </div>
            <div className="selections">
                <table className="header-table">
                    <thead className="ant-table-thead">
                    <tr>
                        <td className="ant-table-cell" key="Month">
                            <span>{localiseDate(i18n.language, dayjs(currentDay),
                                {month: 'long', year: 'numeric'})}</span>
                        </td>
                        {
                            looping.map((value, index) => {
                                let current = dayjs(currentDay)
                                let date = current.add(index, 'days')
                                let books = bookings.filter(booking => dayjs(booking.bookAt).format('YYYYMMDD') === date.format('YYYYMMDD') && booking.status !== 'canceled');
                                return (
                                    <th key={date.format('DD-MM-YYYY')} className="header-cell"
                                        style={{width: '40px', textAlign: 'center'}}
                                        onClick={(e) => handleSelect(e, `.d_${date.format('DD-MM-YYYY')}`)}>
                                        <Space direction="vertical">
                                            <span>{
                                                localiseDate(i18n.language, date,{weekday: 'short'})?.[0].toUpperCase() || '?'}
                                            </span>
                                            <span className="relative">
                                                {date.format('DD')}
                                                {books.length ?
                                                    <div
                                                        className="resaday_badge"
                                                        onClick={e => showModal(e, books)}
                                                    >
                                                        {books.length}
                                                    </div>
                                                    : null}
                                                    </span>
                                        </Space>
                                    </th>
                                )
                            })
                        }
                    </tr>
                    </thead>
                </table>

                <table className="header-table">
                    <tbody>
                    {timeSlots.map((timeSlot, id) => (
                        <tr key={id} className="table-row">
                            <td
                                className="ant-table-cell"
                                onClick={e => handleSelect(e, `.t_${timeSlot}`)}
                            >
                                <small>{displayTime(i18n.language, timeSlot.split('-')[0])}-{displayTime(i18n.language, timeSlot.split('-')[1])}</small>
                            </td>

                            {
                                looping.map((value, index) => {
                                    let day = dayjs(currentDay).add(index, 'days').format('DD-MM-YYYY')
                                    let dayNumber = dayjs(currentDay).add(index, 'days').day();

                                    let offer = offers.find(offer => offer.day === day && `${offer.timeSlot.start}-${offer.timeSlot.end}` === timeSlot) || {
                                        day: day,
                                        id: null,
                                        price: 0
                                    }

                                    return (
                                        <td
                                            className={dayNumber > 4 || dayNumber === 0 ? 'table-cell weekend' : 'table-cell'}
                                            style={{
                                                padding: 0,
                                                width: 40,
                                                textAlign: 'center',
                                            }}
                                            key={index}
                                        >
                                            <SelectableCell
                                                bookings={bookings}
                                                category={category}
                                                timeSlots={timeSlot}
                                                item={offer}
                                                showModal={showModal}
                                            />
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <ModalBookings
                visible={visible}
                setVisible={setVisible}
                bookings={selectedBookings}
                reload={() => setNeedUpdate(needUpdate + 1)}
                setBookings={setSelectedBookings}
            />
        </Spin>
    )
}