import React, {useEffect, useState} from 'react'
import CollectionTable from "../CollectionTable";
import {Space, Tag} from "antd";
import {CategoriesForm} from "./CategoriesForm";
import {EditOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

export default function CategoriesList({id, needUpdate}) {

    const [selectedItem, setSelectedItem ] = useState(null)
    const [creationMode, setCreationMode ] = useState(false)

    const [contentVersion, setContentVersion ] = useState(0)
    const {t} = useTranslation();

    useEffect(()=> {
        setContentVersion(needUpdate)
    }, [needUpdate])

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }


    const columns = [
        {
            title: t('nom'),
            dataIndex: 'name',
            search: true,
            sorter: true,
            render: (name,record) => <>
                {name}
            </>
        },
        {
            title: t('horaires'),
            dataIndex: "timeSlots",
            render: (timeSlots, record) => (
                <Space>
                    {
                        timeSlots.map(item => <Tag key={timeSlots.id}>{item.name}</Tag>)
                    }
                </Space>
            )
        },
        {
            title: t('actions'),
            dataIndex: 'email',
            search: true,
            sorter: true,
            render: (email,record) => <>
                <EditOutlined onClick={()=> setSelectedItem(record)}/>
            </>
        },
    ];

    if(creationMode || selectedItem)
        return <CategoriesForm modal item={selectedItem} onClose={handleCreationFormClose} />;

    return (
        <>
            <CollectionTable
                endpoint={`accounts/${id}/categories`}
                columns={columns}
                onClick={(item) => setSelectedItem({...item}) }
                reload={contentVersion}
            />
        </>
    );
}