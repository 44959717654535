import React from 'react';
import dayjs from "dayjs";
import {Space} from "antd";
import {localiseDate} from "../utils";

export default function HeaderTable({looping, currentDay, i18n}) {

    return (
        <table className="header-table">
            <thead className="ant-table-thead">
            <tr>
                <td className="ant-table-cell" key="Month" style={{width: 20}}>
                    <span>Date</span>
                </td>
                {looping.map((value, index) => {
                    let current = dayjs(currentDay)
                    let date = current.add(index, 'days')

                    return (
                        <th key={date.format('DD-MM-YYYY')} className="header-cell"
                            style={{width: '40px', textAlign: 'center'}}>
                            <Space direction="vertical">
                                <span>{localiseDate(i18n.language, date)}</span>
                            </Space>
                        </th>
                    )
                })}
            </tr>
            </thead>
        </table>
    )
}