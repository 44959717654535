import React, {useState} from "react";
import Input from "./Input";
import {useWidgetContext} from "../../providers/WidgetProvider";
import {ReactComponent as Email} from "../svg/email.svg";
import Loading from "./Loading";

export default function ForgetPassword({displayLogin}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, i18n} = widgetState
    const {post} = widgetDispatch;
    const [loading, setLoading] = useState(false)

    const [recoveryMail, setRecoveryMail] = useState('')
    const [message, setMessage] = useState({type: 'success', content: ''})


    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const response = await post('forget-password', {email: recoveryMail});

        if (!response.success)
            setMessage({type: 'error', content: i18n.gettext('Erreur lors de l\'envoie du mail, verifiez votre adresse')});
        else
            setMessage({type: 'success', content: i18n.gettext('Un mail vous a été envoyé avec votre nouveau mot de passe')});

        setLoading(false);
    }

    return (
        <Loading loading={loading}>
            <p>{i18n.gettext('Veuillez entrer votre adresse mail')} :</p>
            <Input
                required
                icon={<Email
                    style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                value={recoveryMail}
                onChange={e => setRecoveryMail(e.target.value)}
                placeholder="yourname@example.com"
            />
            <div style={{height: 20, opacity: 1, fontSize: 12, color: message.type === 'success' ? '#000' : 'red'}}>
                {message.content}
            </div>
            <div style={{textAlign: 'center', margin: '10px auto 16px'}}>
                <p
                    style={{opacity: '.45', marginBottom: '24px', cursor: "pointer"}}
                    onClick={displayLogin}
                >
                    {i18n.gettext('Afficher le formulaire de connexion')}
                </p>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <button
                    style={{
                        border: 'none',
                        borderRadius: 12,
                        padding: '10px 20px',
                        backgroundColor: widget.backgroundColor || '#2494D1',
                        color: '#fff',
                        cursor: 'pointer'
                    }}
                    onClick={handleSubmit}
                >
                    {i18n.gettext('Demander nouveau mot de passe')}
                </button>
            </div>
        </Loading>
    )
}