import React, {useContext} from 'react';
import {useImmer} from 'use-immer';
import Jed from "jed";

const entrypoint = process.env.API_ENTRYPOINT + '/api/public/';

const defaultState = {
    index: 0,
    visible: false,
    widget: {},
    bookingInfos: {},
    user: {},
    isDrawerVisible: false,
    debug: false,
    local: false,
    hasReserved: false,
    lng: "fr",
    i18n: {gettext: () => {}}
};

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const WidgetProvider = ({children}) => {
    const [state, dispatch] = useImmer({...defaultState});

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

function useStateContext() {
    const state = useContext(StateContext);

    if (state === undefined)
        throw new Error("Ut oh, where is my state?");

    return state;
}

function useDispatchContext() {
    const state = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    if (state === undefined)
        throw new Error("Ut oh, where is my state?");

    if (dispatch === undefined)
        throw new Error("Ut oh, where is my dispatch?");

    async function get(endpoint) {
        try {
            let response = await fetch(entrypoint + endpoint, {
                method: 'get',
                headers: {
                    'Accept': 'application/ld+json',
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        } catch (e) {
            return e.response.data;
        }
    }

    async function post(endpoint, data) {
        try {
            let response = await fetch(entrypoint + endpoint, {
                method: 'post',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/ld+json',
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        } catch (e) {
            return e.response.data;
        }
    }

    function goTo(index) {
        let maxStep = this.state.displayTags ? 7 : 6
        if (index < 0 || index > maxStep) return
        dispatch(draft => {
            draft.index = index
        })
    }

    function next() {
        dispatch(draft => {
            draft.index = state.index + 1
        })
    }

    function prev() {
        dispatch(draft => {
            draft.index = state.index - 1
        })
    }

    function setVisible(value) {
        dispatch(draft => {
            draft.visible = value;
        })
    }

    function setBookingInfos(value) {
        dispatch(draft => {
            draft.bookingInfos = value;
        })
    }

    function setter(key, value) {
        dispatch(draft => {
            draft[key] = value;
        })
    }

    function stringDateDMonthY(inputFormat) {
        var dAujourdhui = new Date(inputFormat);
        var options = {year: 'numeric', month: 'long', day: 'numeric'};
        return dAujourdhui.toLocaleDateString(state.lng, options);
    }

    function displayTime(time) {
        let timeArray = time.split('h')
        if (timeArray.length !== 2) return time

        let date = new Date()
        date.setHours(timeArray[0], timeArray[1])

        return date.toLocaleTimeString(state.lng, {hour: '2-digit', minute: '2-digit'})

    }

    function resetBook() {
        dispatch(draft => {
            draft.index = 1;
            draft.bookingInfos = {};
            draft.isDrawerVisible = false;
        })
    }

    function getLanguage() {
        const params = window.location.pathname.split('/').filter(params => params);
        const languages = ['fr', 'en'];

        if (params.length && typeof params === 'object') {
            const firstParam = params.shift();

            if (languages.includes(firstParam))
                return firstParam;
        }

        if (navigator?.languages) {
            const navigatorLng =  navigator.language.split('-').shift();

            if (languages.includes(navigatorLng))
                return navigatorLng;
        }

        return 'fr';
    }

    function loadJed(lng) {
        const locales = {
            'en': require("../widget/locale/en.json"),
            'fr': {},
        }

        const i18n = new Jed(locales[lng]);

        dispatch(draft => {
            draft.i18n = i18n;
        })
    }

    return {
        get,
        post,
        goTo,
        next,
        prev,
        setVisible,
        setBookingInfos,
        setter,
        stringDateDMonthY,
        resetBook,
        displayTime,
        getLanguage,
        loadJed
    };
}

const useWidgetContext = () => {
    return [useStateContext(), useDispatchContext()]
}

export {useWidgetContext, WidgetProvider, StateContext, DispatchContext};
