import React, {useState} from "react";
import {ReactComponent as ArrowLeft} from '../svg/arrow-left.svg'
import {ReactComponent as ArrowRight} from '../svg/arrow-right.svg'


export default function Carousel({images, ...props}) {

    const [index, setIndex] = useState(0);

    function prev() {
        if (index === 0)
            return setIndex(images.length - 1);

        setIndex(index - 1);
    }

    function next() {
        if (index < images.length - 1)
            return setIndex(index + 1);

        setIndex(0);
    }

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
            margin: 'auto',
            position: "relative",
            height: '100%'
        }}
        >
            <div style={{
                display: 'flex',
                width: 'auto',
                position: "relative",
                left: `Calc(100% * ${index * -1})`,
                transition: 'all .3s ease-out'
            }}>
                {images.map(img => (
                    <img
                        className="noselect"
                        key={img.uid}
                        style={{width: "100%", objectFit: 'cover', borderRadius: '0 0 12px 12px', minWidth: '100%'}}
                        src={img.display.native.md}
                    />
                ))}
            </div>
            {
                images.length > 1 &&
                <>
                    <div className="carousel-control" style={{
                        backgroundColor: props.bgColor,
                        left: '15px'
                    }}
                         onClick={prev}>
                        <ArrowLeft/>
                    </div>
                    <div
                        className="carousel-control" style={{
                        backgroundColor: props.bgColor,
                        right: '15px'
                    }}
                        onClick={next}
                    >
                        <ArrowRight/>
                    </div>
                </>
            }
        </div>
    )
}