import React, {useRef, useEffect, useState} from "react"
import {Table} from "antd";
import {useApiContext} from "../providers/ApiProvider";

export default function CollectionTable({endpoint, columns, filters, reload, selectedRowKeys = null, setSelectedRowKeys}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '25', '50', '100'],
        position: 'bottom',
    });
    const [loading, setLoading] = useState(false);
    const [needUpdate, setNeedUpdate ] = useState(0);
    const isMounted = useRef(null);

    useEffect(() => {

        isMounted.current = true;

        fetch();

        return () => {
            isMounted.current = false;
        }
    }, [needUpdate, filters, reload])

    function handleTableChange(table_pagination) {
        setPagination({...table_pagination});
        setNeedUpdate(needUpdate+1);
    }

    async function fetch() {
        setLoading(true)
        const params = {
            itemsPerPage: pagination.pageSize,
            page: pagination.current,
            filters: filters
        };

        const response = await apiFetchCollection(endpoint, params);

        if (isMounted.current) {


            pagination.total = response['hydra:totalItems'];
            setPagination({...pagination})
            setData(response["hydra:member"]);
            setLoading(false)
        }

    }

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    return (
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 'auto' }}
            rowSelection={selectedRowKeys ? rowSelection : null}
        />
    )
}