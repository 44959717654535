import React from 'react';
import background from '../images/inscription-pic.jpeg'
import {ReactComponent as Wave} from '../images/waves/inscription-mobile.svg';
import {useTranslation} from "react-i18next";
import FormSubscription from "../FormSubscription";

export default function SubscriptionMobile() {

    const {t} = useTranslation();

    return (
        <div className="subscription-mobile-container" style={{backgroundImage: `url(${background})`}} id="subscription">

            <div className="subscription-mobile-content">
                <div>
                    <h1>{t('Je m’inscris et teste le widget dès maintenant')}</h1>
                </div>


                <FormSubscription/>
            </div>

            <Wave/>
        </div>
    )
}