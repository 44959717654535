import React, {useRef, useState, useEffect} from "react";
import {Form, Modal, Select, Spin, Alert} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {displayTime} from "../utils";

const alertText = 'Des services à l\'heure sont liés à cette tranche horaire, toutes modifications invalideront les offres liées à ces services'

export function TimeslotForm({modal, onClose, item, visible = true}) {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch
    const [loading, setLoading] = useState(false)
    const {t, i18n} = useTranslation();
    const [shouldDisplay, setShouldDisplay] = useState(false)

    const formRef = useRef()

    useEffect(() => {
        if (item) {
            setShouldDisplay(displayWarning)
        } else {
            setShouldDisplay(false)
        }
    }, [visible])

    async function onFinish(values) {
        setLoading(true)

        if (item) {
            await update(values)
        } else {
            await postEntity(values)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(values) {
        let params = {
            start: values.start,
            end: values.end,
            account: accountState.account['@id']
        }
        let response = await apiPostEntity('time_slots', params)
    }

    async function update(values) {
        let params = {
            start: values.start,
            end: values.end,
        }
        let response = await apiUpdateEntity('time_slots', item.id, params)
    }

    function onFinishFailed(values) {
        console.log(values)
    }

    function handleOk() {
        formRef.current.submit()
    }

    function displayWarning() {
        console.log('display ?')
        let hasDurationCats = false;
        console.log(item.categories)

        for (let i = 0; i < item.categories.length; i++) {
            if (item.categories[i].type === 'duration') {
                hasDurationCats = true
                i = item.categories.length
            }
        }
        return hasDurationCats
    }

    const content = (
        <Spin spinning={loading}>
            <Form
                ref={formRef}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    start: item ? item.start : undefined,
                    end: item ? item.end : undefined
                }}
            >
                <Form.Item
                    label={t("heure début")}
                    name="start"
                    rules={[
                        {
                            required: true,
                            message: t('entrer une heure de début!'),
                        },
                    ]}
                >
                    <Select style={{width: '100px'}}>
                        {Array.from({length: 24}).map((value, index) => {
                                let hour = index < 10 ? `0${index}` : index.toString();
                                return [
                                    <Select.Option key={'start_' + index} value={`${hour}h00`}>
                                        {displayTime(i18n.language, `${hour}h00`)}
                                    </Select.Option>,
                                    <Select.Option key={'start_' + index + '_half'} value={`${hour}h30`}>
                                        {displayTime(i18n.language, `${hour}h30`)}
                                    </Select.Option>
                                ]
                            }
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t('heure fin')}
                    name="end"
                    rules={[
                        {
                            required: true,
                            message: t('entrer une heure de fin!'),
                        },
                    ]}
                >
                    <Select style={{width: '100px'}}>
                        {Array.from({length: 24}).map((value, index) => {
                                let hour = index < 10 ? `0${index}` : index.toString();
                                return [
                                    <Select.Option key={'start_' + index} value={`${hour}h00`}>
                                        {displayTime(i18n.language, `${hour}h00`)}
                                    </Select.Option>,
                                    <Select.Option key={'start_' + index + '_half'} value={`${hour}h30`}>
                                        {displayTime(i18n.language, `${hour}h30`)}
                                    </Select.Option>
                                ]
                            }
                        )}
                    </Select>
                </Form.Item>
            </Form>
            {
                shouldDisplay &&
                <Alert message={t(alertText)} type="warning" showIcon />
            }
        </Spin>
    )

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `${t('editer')} ${item.start}h - ${item.end}h` : t("créer une tranche horaire")}
                   onOk={handleOk}
                   okText={item ? t("modifier") : t("créer")}
                   onCancel={handleCancel}
                   cancelText={t("retour")}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}