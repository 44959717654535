import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";


i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        ns: ["translation"],
        defaultNS: "translation",
        nsSeparator:'::',
        keySeparator: false, // we do not use keys in form messages.welcome
        debug: false,
        saveMissing: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: process.env.API_ENTRYPOINT + '/translation/{{lng}}?type=admin',
            addPath: process.env.API_ENTRYPOINT + '/translation/add_missing/fr?type=admin',
            crossDomain: true,
        }
    }, (err, t) => {
        if (err) return console.error(err)
    });


export default i18n;
