import React, {useEffect, useState} from 'react'

function SelectableCell({item, timeSlots, category, bookings, showModal}) {

    const [books, setBooks] = useState([])
    const [booksLength, setBooksLength] = useState(0)

    useEffect(() => {
        if (bookings) {
            let matchingBookings = bookings.filter(booking => booking.offer === `/api/offers/${item.id}` && booking.status !== 'canceled');
            setBooks(matchingBookings)
            setBooksLength(matchingBookings.length)
        }
    }, [bookings, item])

    function openModal(e) {
        showModal(e, books)
    }


    return (
        <div className="relative">
            {booksLength ?
                <div
                    style={{left: 30}}
                    className="resaday_badge"
                    onClick={openModal}
                >
                    {booksLength}
                </div>
                : null}
            <div
                className={`cell d_${item.day} t_${timeSlots} c_${category.id}`}
                data_id={item.id}
                data_timeslot={timeSlots}
                data_category={category.id}
                data_day={item.day}
                data_status={item.status}
                data_quantity={item.quantity}
                data_price={item.price}
            >
                <div style={{
                    width: 20,
                    height: 20,
                    margin: "auto",
                    color: 'white',
                    borderRadius: '50%',
                    backgroundColor: item.status === 'available' ? 'green' : 'red'
                }}>
                    {item.quantity ? item.quantity - booksLength : item.quantity}
                </div>
                <div style={{fontSize: 12, lineHeight: 1.5}}>{item.price}</div>
            </div>
        </div>
    )
}

export default React.memo(SelectableCell)