import React, {useState} from 'react'
import {useWidgetContext} from "../../providers/WidgetProvider";
import SimpleNextStepBtn from "../component/SimpleNextStepBtn";
import TextArea from "../component/TextArea";
import Addons from "../component/Addons";
import CategoryOfferSelect from "../component/CategoryOfferSelect";
import NbPeople from "../component/NbPeople";

export default function ServiceStep() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, i18n} = widgetState;
    const {setBookingInfos} = widgetDispatch;
    const [comment, setComment] = useState(widgetState.bookingInfos.comment || "")

    const handleChange = (e) => {
        setComment(e.target.value)
    }

    const nextStep = () => {
        setBookingInfos({...widgetState.bookingInfos, comment: comment})
    }

    return (
        <div className="background-grey" style={{minHeight: '100%'}}>

            <div className="step_content flex column between">
                <div>
                    {
                        !!bookingInfos?.offer?.categoryTimeslot?.id &&
                        <CategoryOfferSelect/>
                    }

                    {
                        bookingInfos?.offer?.category?.isLimited &&
                        <NbPeople/>
                    }

                    {
                        !!widgetState.widget.account.addons.length &&
                        <Addons/>
                    }

                    <div className="confirm-tab" style={{marginTop: '28px'}}>
                        <div className="confirm-tab-title">
                            <div className="background-transparent"
                                 style={{backgroundColor: widget.backgroundColor || '#2494D1'}}/>
                            {i18n.gettext('Avez-vous des demandes particulières ?')}
                        </div>
                        <div className="confirm-tab-content">
                            <TextArea onChange={handleChange} value={comment}/>
                        </div>
                    </div>

                </div>
            </div>
            <SimpleNextStepBtn onClick={nextStep} disabled={bookingInfos.offer.firstSelection}/>
        </div>
    )

}