import React, {useState} from 'react'
import {Dropdown, Form, Input, Menu, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {ExclamationCircleOutlined} from '@ant-design/icons';

const {confirm} = Modal;

export default function DropdownStatus({infos, reload, updateBookingsStatus}) {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiUpdateEntity} = apiDispatch;

    const [form] = Form.useForm();


    const status = [
        {name: 'Booked', key: 'booked'},
        {name: 'Confirmed', key: 'confirmed'},
        {name: 'No show', key: 'noShow'},
        {name: 'Canceled', key: 'canceled'},
    ]

    function preUpdate(value, id) {

        if (value === 'canceled')
            showConfirm(value, id);
        else
            updateStatus(value, id);
    }

    async function updateStatus(value, id) {
        const formData = form.getFieldsValue();
        let data = {status: value};

        if (formData.comment) {
            data.comment = formData.comment;
            form.resetFields();
        }

        await apiUpdateEntity('bookings', id, data);
        reload();

        if (typeof updateBookingsStatus === "function")
            updateBookingsStatus(id, value);
    }

    function showConfirm(value, id) {
        confirm({
            title: t('Êtes vous sûrs de vouloir annuler cette reservation ?'),
            icon: <ExclamationCircleOutlined/>,
            okText: t('Valider'),
            cancelText: t('Retour'),
            content: (
                <Form
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        name="comment"
                        label={t('Commentaire')}
                    >
                        <Input.TextArea/>
                        </Form.Item>
                </Form>

            ),
            onOk() {
                updateStatus(value, id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const menu = (
        <Menu onClick={e => preUpdate(e.key, infos.id)}>
            {status.filter(item => item.key !== infos.name).map(item => (
                <Menu.Item key={item.key}>{item.name}</Menu.Item>
            ))}
        </Menu>
    )

    function getColor() {
        switch (infos.name) {
            case 'booked':
                return 'green';
            case 'confirmed':
                return 'blue';
            default:
                return 'red';
        }
    }

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <span
                className="badge_status"
                style={{backgroundColor: getColor()}}
            >
                {infos.name}
            </span>
        </Dropdown>
    )
}