import React from "react";
import {ReactComponent as Logo} from '../../../../public/logo-blanc.svg';
import {Dropdown, Menu} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

export default function NavbarMobile() {

    const {t} = useTranslation();
    const history = useHistory();

    function scrollInto(id) {
        window.scrollTo({top: document.getElementById(id).offsetTop});
    }

    function switchMenu(e) {
        switch (e.key) {
            case 'admin':
                history.push('/login-admin')
                break;
            case 'user':
                history.push('/login')
                break;
            default:
                break;
        }
    }

    const menu = (
        <Menu onClick={switchMenu}>
            <Menu.Item key="admin">
                {t('Je suis hôtelier')}
            </Menu.Item>
            <Menu.Item key="user">
                {t('Je suis voyageur')}
            </Menu.Item>
        </Menu>
    );


    return (
        <div className="navbar-container">
            <div className="logo"><Logo/></div>


            <div className="btn-container">
                <div className="btn-head-mobile" onClick={() => scrollInto('subscription')}>TESTER</div>

                <div>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <UserOutlined style={{fontSize: 20, color: "#fff"}}/>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}