import React from 'react'
import background from '../images/confidentialité-pic.jpg'
import {useTranslation} from "react-i18next";
import {ReactComponent as Wave} from '../images/waves/confidentialite.svg';
import image from '../images/confidentialité.png'

export default function Confidentiality() {

    const {t} = useTranslation();

    return (
        <div className="confidentiality-container" style={{backgroundImage: `url(${background})`}}>

            <div className="confidentiality-content">

                <div style={{width: 600, zIndex: 100}}>
                    <div><h1>{t('Confidentialité')}</h1></div>

                    <div className="divider"/>

                    <p>{t('Notre interface garantit une confidentialité modulable : vous choisissez le niveau de confidentialité requis selon vos prestations. Le paiement se fait directement à l\'hôtel.')}</p>

                </div>

                <Wave/>
            </div>

            <img src={image} alt=""/>
        </div>
    )
}