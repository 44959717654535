import React, {useEffect, useState} from "react";
import {Button, Empty, InputNumber, Select, Space, Spin} from "antd";
import {DatePicker} from "../index";
import dayjs from "dayjs";
import SelectableCell from "../SelectableCell";
import {displayTime, handleClass, localiseDate, resolveNamesLanguage, startSelection} from "../utils";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {PriceForm} from "./PriceForm";
import {useTranslation} from "react-i18next";
import ModalBookings from "../Modal/ModalBookings";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";

const looping = Array.from({length: 31}); // create an empty array with length 30 to loop
const today = dayjs().format('YYYYMMDD')


export default function PriceTable({}) {

    const {t, i18n} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiPostEntity} = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [nbSelected, setNbSelected] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selection, setSelection] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(null);
    const [currentDay, setCurrentDay] = useState(today);
    const [longVisible, setLongVisible] = useState(false);

    const [price, setPrice] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [status, setStatus] = useState(null);

    const [bookings, setBookings] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedBookings, setSelectedBookings] = useState([]);
    const history = useHistory();

    useEffect(() => {
        fetch()
        fetchBookings()
    }, [needUpdate, currentDay])

    useEffect(() => {
        if (!selection)
            setSelection(startSelection(onSelectionEnd))
    }, [])

    async function fetch() {
        clear()
        let firstDateNeeded = dayjs(currentDay).format('DD-MM-YYYY')
        let params = {
            id: accountState.account.id,
            filters: [
                {
                    name: 'firstDateNeeded', value: firstDateNeeded
                }
            ]
        }
        setLoading(true)
        let response = await apiFetchSubResource('accounts', params, 'categories')
        setCategories(response['hydra:member'])
        setLoading(false)
    }

    const onSelectionEnd = (selected) => {
        setNbSelected(selected.length)
    }

    function handleSelect(e, selector) {
        selection.resolveSelectables()

        if (!e.ctrlKey && !e.metaKey) {
            let selectedItems = selection.getSelection()
            handleClass(selectedItems, 'remove')
            selection.clearSelection();
        }

        selection.select(selector)
        selection.keepSelection();
        let selectedItems = selection.getSelection()
        handleClass(selectedItems, 'add')
        setNbSelected(selectedItems.length)
    }

    function clear() {
        if (selection) {
            let selectedItems = selection.getSelection()
            handleClass(selectedItems, 'remove')

            selection.clearSelection()
            setNbSelected(0)
        }
    }

    async function updatePrice() {
        let selectedItems = selection.getSelection();

        let offers = selectedItems.map(item => {
            const books = bookings.filter(booking => dayjs(booking.bookAt).format('DD-MM-YYYY') === item.getAttribute('data_day') && booking.offer === `/api/offers/${item.getAttribute('data_id')}`).length;

            return {
                id: item.getAttribute('data_id'),
                category: item.getAttribute('data_category'),
                timeSlot: item.getAttribute('data_timeslot'),
                day: item.getAttribute('data_day'),
                price: price !== null ? price : item.getAttribute('data_price'),
                status: status || item.getAttribute('data_status'),
                quantity: quantity !== null ? (quantity + books) : item.getAttribute('data_quantity')
            }
        })

        setLoading(true);
        let response = await apiPostEntity('update-offers', {offers});
        setNeedUpdate((new Date()).getTime());
        setPrice(null);
        setQuantity(null);
        setStatus(null);
    }

    function handleDateChange(value) {
        setCurrentDay(value.format('YYYYMMDD'))
    }

    function handleClose() {
        setLongVisible(false)
        setNeedUpdate(needUpdate + 1)
    }

    function getInterval() {
        let date = dayjs(currentDay);

        const after = date.format('YYYY-MM-DD');
        const before = date.add(30, 'day').format('YYYY-MM-DD');

        return {after, before};
    }

    async function fetchBookings() {
        const {after, before} = getInterval();

        const data = {
            id: accountState.account.id,
            filters: [
                {name: 'bookAt[after]', value: after},
                {name: 'bookAt[before]', value: before},
                {name: 'exists[categoryId]', value: false},
                {name: 'pagination', value: false}
            ]
        }
        const response = await apiFetchSubResource('accounts', data, 'bookings');
        setBookings(response['hydra:member']);
    }

    function showModal(event, books) {
        event.stopPropagation();

        setSelectedBookings(books);
        setVisible(true);
    }

    function prevMonth() {
        let prev = dayjs(currentDay).subtract(31, "days")
        let today = dayjs()
        if (today.isAfter(prev))
            prev = today
        handleDateChange(prev)
    }

    function nextMonth() {
        let next = dayjs(currentDay).add(31, "days")
        handleDateChange(next)
    }

    return (
        <Spin spinning={loading}>
            {
                !!categories.length ?
                    <>
                        <div className="price-table-options">
                            <Space direction="vertical">
                                <Space>
                                    <DatePicker onChange={handleDateChange} format={'DD-MM-YYYY'}
                                                allowClear={false}
                                                value={dayjs(currentDay)}/>
                                    {nbSelected > 0 && <Button onClick={clear}>{t('Tout désélectionner')}</Button>}
                                </Space>
                                <div style={{minHeight: '65px'}}>
                                    <Space direction="vertical" style={{display: nbSelected ? 'block' : 'none'}}>
                                        <div>
                                            {nbSelected} {t('prix sélectionné(s)')}, {t('vos prix sont en ')}
                                            {accountState.account.currency} ({getSymbolFromCurrency(accountState.account.currency)})
                                        </div>

                                        <Space>
                                            <InputNumber
                                                min={0}
                                                value={price}
                                                onChange={setPrice}
                                                onPressEnter={updatePrice}
                                                placeholder={t("prix")}
                                            />
                                            <InputNumber
                                                min={0}
                                                value={quantity}
                                                onChange={setQuantity}
                                                placeholder={t('quantité')}
                                                onPressEnter={updatePrice}
                                            />
                                            <Select
                                                style={{width: 200}}
                                                placeholder={t("statut")}
                                                value={status}
                                                onChange={setStatus}
                                            >
                                                <Select.Option value="available">{t('disponible')}</Select.Option>
                                                <Select.Option
                                                    value="notAvailable">{t('non disponible')}</Select.Option>
                                            </Select>
                                            <Button onClick={updatePrice}>{t('valider')}</Button>
                                        </Space>
                                    </Space>
                                </div>
                            </Space>
                            <Button onClick={() => setLongVisible(true)}
                                    type={'primary'}>{t('prix long terme')}</Button>
                        </div>
                        <PriceForm modal onClose={handleClose} visible={longVisible}/>

                        <div className={dayjs().isBefore(dayjs(currentDay)) ? "flex between" : "flex justify-end"}>
                            {
                                dayjs().isBefore(dayjs(currentDay)) &&
                                <Button onClick={prevMonth} icon={<LeftOutlined/>}
                                        title={t("Reculer d'un mois")}/>
                            }
                            <Button onClick={nextMonth} icon={<RightOutlined/>}
                                    title={t("Avancer d'un mois")}/>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <small>*{t('maintenez ctrl pour ajouter à la sélection')}</small>
                        </div>
                        <div className="selections">
                            <div>
                                <table className="header-table">
                                    <thead className="ant-table-thead">
                                    <tr>
                                        <td className="ant-table-cell" key="Month">
                                            <span>{localiseDate(i18n.language, dayjs(currentDay),
                                                {month: 'long', year: 'numeric'})}</span>
                                        </td>
                                        {
                                            looping.map((value, index) => {
                                                let current = dayjs(currentDay)
                                                let date = current.add(index, 'days')
                                                let books = bookings.filter(booking => dayjs(booking.bookAt).format('YYYYMMDD') === date.format('YYYYMMDD') && booking.status !== 'canceled' && !!booking.offer);
                                                return (
                                                    <th key={date.format('DD-MM-YYYY')} className="header-cell"
                                                        style={{width: '40px', textAlign: 'center'}}
                                                        onClick={(e) => handleSelect(e, `.d_${date.format('DD-MM-YYYY')}`)}>
                                                        <Space direction="vertical">
                                                            <span>{localiseDate(i18n.language, date,
                                                                {weekday: 'short'})?.[0].toUpperCase() || '?'}</span>
                                                            <span className="relative">
                                                        {date.format('DD')}
                                                                {books.length ?
                                                                    <div
                                                                        className="resaday_badge"
                                                                        onClick={e => showModal(e, books)}
                                                                    >
                                                                        {books.length}
                                                                    </div>
                                                                    : null}
                                                    </span>
                                                        </Space>
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            {categories.map(category => {
                                if (category.timeSlots.length === 0) return null
                                const isDuration = category.type === 'duration';

                                return (
                                    <div key={category.name}>
                                        <div onClick={(e) => handleSelect(e, `.c_${category.id}`)}
                                             className="category-cell">
                                            <span>{resolveNamesLanguage(i18n, accountState, category)}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                            {isDuration && <>
                                                <span>({displayTime(i18n.language, category.timeSlots[0].start)} - {displayTime(i18n.language, category.timeSlots[0].end)})</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <Link to={`/category/${category.id}`}>{t('Administrer')}</Link>
                                            </>}
                                        </div>
                                        {!isDuration &&
                                        <table className="header-table">
                                            <tbody>
                                            {
                                                category.timeSlots.map(time => {
                                                    return (
                                                        <tr key={`t_${time.id}`} className="table-row">
                                                            <td className="ant-table-cell"
                                                                onClick={(e) =>
                                                                    handleSelect(e, `.t_${time.id}.c_${category.id}`)}>
                                                                <small>{displayTime(i18n.language, time.start)} - {displayTime(i18n.language, time.end)}</small>
                                                            </td>
                                                            {
                                                                looping.map((value, index) => {
                                                                    let day = dayjs(currentDay).add(index, 'days').format('DD-MM-YYYY')
                                                                    let dayNumber = dayjs(currentDay).add(index, 'days').day()

                                                                    let offer = time.offers.find(offer => offer.day === day) || {
                                                                        day: day,
                                                                        id: null,
                                                                        price: 0
                                                                    }

                                                                    return (
                                                                        <td
                                                                            className={dayNumber > 4 || dayNumber === 0 ? 'table-cell weekend' : 'table-cell'}
                                                                            style={{
                                                                                padding: 0,
                                                                                width: 40,
                                                                                textAlign: 'center',
                                                                            }}
                                                                            key={time + day}
                                                                        >
                                                                            <SelectableCell
                                                                                bookings={bookings}
                                                                                category={category}
                                                                                timeSlots={time.id}
                                                                                item={offer}
                                                                                showModal={showModal}
                                                                            />
                                                                        </td>
                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                    :
                    <Empty
                        style={{padding:'50px'}}
                        description={
                            <span>
                                    {t('Il semblerais que vous n\'avez pas encore de services')}
                            </span>
                        }
                    >
                        <Button type="primary" onClick={() => history.push("/settings")}>{t('Configurer vos services maintenant')}</Button>
                    </Empty>
            }
            <ModalBookings
                visible={visible}
                setVisible={setVisible}
                bookings={selectedBookings}
                reload={() => setNeedUpdate(needUpdate + 1)}
                setBookings={setSelectedBookings}
            />
        </Spin>
    )
}