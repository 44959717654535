import React from 'react';
import dayjs from "dayjs";
import {Button} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

export default function NavigationDate({currentDay, setCurrentDay}) {

    function handleDateChange(value) {
        setCurrentDay(value.format('YYYYMMDD'));
    }

    function prevWeek() {
        let prev = dayjs(currentDay).subtract(7, "days");
        let today = dayjs();
        if (today.isAfter(prev))
            prev = today;

        handleDateChange(prev);
    }

    function nextWeek() {
        let next = dayjs(currentDay).add(7, "days");
        handleDateChange(next);
    }

    return (
        <div
            className={dayjs().isBefore(dayjs(currentDay)) ? "flex between" : "flex justify-end"}
            style={{padding: 10}}
        >
            {dayjs().isBefore(dayjs(currentDay)) &&
            <Button
                onClick={prevWeek}
                icon={<LeftOutlined/>}
                title={"Reculer d'un mois"}
            />}
            <Button
                onClick={nextWeek}
                icon={<RightOutlined/>}
                title={"Avancer d'un mois"}
            />
        </div>
    )
}