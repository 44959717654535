import React, {useState} from "react";
import Slider from "./Slider";
import '../../../styles/landing.scss';
import Navbar from "./Navbar";
import Ergonomy from "./Ergonomy";
import Autonomy from "./Autonomy";
import Confidentiality from "./Confidentiality";
import Offer from "./Offer";
import Subscription from "./Subscription";
import Footer from "./Footer";
import Video from "./Video";

export default function LandingDesktop({data}) {

    const [index, setIndex] = useState(0);

    return (
        <div className="landing-desktop">
            <Navbar index={index}/>
            <Slider index={index} setIndex={setIndex}/>
            <Video data={data}/>
            <Ergonomy/>
            <Autonomy/>
            <Confidentiality/>
            <Offer/>
            <Subscription/>
            <Footer/>
        </div>
    )
}