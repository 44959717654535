import React from "react";
import firstImage from "../images/slider-1.jpg";
import secondImage from "../images/slider-2.jpg";
import thirdImage from "../images/slider-3.jpg";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {ReactComponent as WhiteWave} from '../images/waves/header-blanc.svg';
import {ReactComponent as BlackWaves} from '../images/waves/header-noir.svg';
import logo from "../../../../public/logo-couleur.png";

export default function Slider({index, setIndex}) {

    function prev() {
        if (index === 0)
            return setIndex(2);

        setIndex(index - 1);
    }

    function next() {
        if (index < 2)
            return setIndex(index + 1);

        setIndex(0);
    }

    return (
        <div className="carousel-container">
            <div style={{left: `Calc(100% * ${index * -1})`}} className="carousel-container-block">

                <div
                    className="carousel-image"
                    style={{backgroundImage: 'url(' + firstImage + ')'}}
                >
                    <WhiteWave className="white-wave"/>

                    <div className="first-slide-content">
                        <div className="first-block">
                            <div>
                                <h1 className="header align-right">Optimisez vos revenus<br/>en journée avec</h1>
                            </div>

                            <div style={{marginTop: 100, display:"flex", justifyContent: "flex-end"}}>
                                <div style={{width: 420}}>
                                <h2 className="header align-center">Le widget permettant de vendre des<br/>services
                                    hôteliers en
                                    journée</h2>
                                </div>
                            </div>
                        </div>

                        <div className="second-block">
                            <img src={logo} alt="logo breaking day" className="logo-br"/>
                        </div>
                    </div>
                </div>

                <div
                    className="carousel-image"
                    style={{backgroundImage: 'url(' + secondImage + ')'}}
                >
                    <div className="second-slide-content">
                        <div className="first-block">
                            <h1 className="header align-center">Avec Breaking Day<br/>Développez la vente<br/>d'espaces
                                disponibles</h1>
                        </div>

                        <div className="second-block">
                            <h2 className="header">Le widget permettant de<br/>vendre des services<br/>hôteliers en
                                journée</h2>
                        </div>
                    </div>
                </div>

                <div
                    className="carousel-image"
                    style={{backgroundImage: 'url(' + thirdImage + ')'}}
                >
                    <BlackWaves style={{position: 'absolute', left: 0, height: '100vh'}}/>

                    <div className="third-slide-content">
                        <div className="first-block">
                            <h1 className="header">Avec breaking day<br/>
                                Augmentez votre chiffre d'affaire </h1>
                        </div>
                        <div className="second-block">
                            <h2 className="header">Grâce à un outil de réservation indépendant en<br/>
                                quelques clics et sans commission</h2>
                        </div>
                    </div>
                </div>


            </div>

            <div>
                <div
                    className="carousel-control"
                    style={{left: '50px'}}
                    onClick={prev}
                >
                    <LeftOutlined style={{fontSize: 30}}/>
                </div>
                <div
                    className="carousel-control"
                    style={{right: '50px'}}
                    onClick={next}
                >
                    <RightOutlined style={{fontSize: 30}}/>
                </div>
            </div>

        </div>
    )
}