import React from "react";

export default function FooterMobile() {
    return (
        <div className="footer-mobile-container">
            <p>
                MMCréation · Agence Internet Paris · 32, avenue de Wagram · 75008 Paris · <a href="tel:+33145747600">Tél : 01 45 74 76 00</a> ·
                &nbsp;<a href="mailto:info@mmcreation.com">info@mmcreation.com</a>
            </p>

            <p>
                © MMCréation.com 2021 - Mentions légales - CGV
            </p>
        </div>
    )
}