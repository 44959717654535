import React, {useEffect, useRef, useState} from 'react'
import {Button, Form, Input, Modal, Select, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fad} from '@fortawesome/pro-duotone-svg-icons'
import {fal} from '@fortawesome/pro-light-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import ServiceCard from "../Service/ServiceCard";
import {useTranslation} from "react-i18next";

const FaIcons = {solid: fas, brands: fab, duotone: fad, light: fal, regular: far}

export default function ModalIconWidget({visible, setVisible, setIconWidget}) {

    const [apiDispatch] = useApiContext()
    const {apiPostEntity, apiFetchCollection} = apiDispatch
    const [faResult, setFaResult] = useState([])
    const [flatResult, setFlatResult] = useState([])
    const [currentProvider, setCurrentProvider] = useState('fa')
    const [loading, setLoading] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState('')
    const form = useRef()
    const {t} = useTranslation();

    useEffect(() => {
        setSelectedIcon('')
        setFaResult([])
    }, [visible])

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };

    const onFinish = async (values) => {
        if (selectedIcon === '')
            return setVisible(false);


        setIconWidget(selectedIcon)

        setSelectedIcon('')
        form.current.resetFields()
        setVisible()
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const handleSubmit = () => {
        if (form.current)
            form.current.submit()
    }

    async function iconSearch(value, e) {
        e.preventDefault()
        let provider = currentProvider
        let query = value.trim()
        if (query.length === 0) return;

        setLoading(true)
        setNoResult(false)

        let params = {
            filters:
                [
                    {name: 'query', value: query.toLowerCase()},
                    {name: 'provider', value: provider}
                ]
        }
        // icon finder ajax request only work on https
        let response = await apiFetchCollection('searchIcon', params)

        handleFaResults(response, provider)
    }


    function handleFaResults(response, provider) {

        let data = response.success ? response.data : null

        setNoResult(data.length === 0)
        if (provider === 'flatIcon') {
            setFlatResult(data)
        } else {
            setFaResult(data)
        }
        setLoading(false)
    }

    function selectIcon(icon) {
        setSelectedIcon(getIconString(icon))
    }

    function getIconString(icon) {
        let str = `<svg width="100%" height="100%" viewBox="${"0 0 " + icon.icon[0] + " " + icon.icon[1]}">`

        if (typeof icon.icon[4] === 'string') {
            str += `<path d="${icon.icon[4]}" fill="currentColor"/>`
        } else {
            icon.icon[4].map((item, index) => {
                str += `<path d="${item}" fill="${index === 0 ? 'black' : 'lightgray'}"/>`
            })
        }
        str += "</svg>"

        return str;
    }

    const getFlatIcon = async (icon) => {
        setLoading(true)

        let response = await apiPostEntity('getIcon', {icon: icon})
        setSelectedIcon(response.code)
        setLoading(false)
    }

    return (
        <Modal
            visible={visible}
            destroyOnClose
            title={t('Choisir un nouvel icone')}
            onCancel={() => setVisible(false)}
            footer={[
                <Button key="cancel_create" onClick={() => setVisible(false)}>
                    {t('retour')}
                </Button>,
                <Button key="create" type="primary" htmlType="submit" onClick={handleSubmit}>
                    {t('Valider')}
                </Button>
            ]}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                ref={form}
                initialValues={{
                    name: '',
                    icon: selectedIcon
                }}
            >

                <p>Icone</p>
                <Space align="start">
                    <ServiceCard service={{name: 'preview', icon: selectedIcon}}/>

                    <Space direction="vertical">
                        {/* <Select onChange={(val) => setCurrentProvider(val)}
                                style={{width: "200px"}}
                                value={currentProvider}>
                            <Select.Option value={'fa'}>Font awesome</Select.Option>
                            <Select.Option value={'flatIcon'}>FlatIcon</Select.Option>
                        </Select> */}
                        <p>Rechercher une icone font awesome</p>
                        <Input.Search placeholder={t("rechercher un icone")}
                                      style={{width: "200px"}}
                                      loading={loading}
                                      onSearch={iconSearch}/>
                        {
                            noResult ?
                                <div>{t('aucun résultat pour votre recherche')}</div> : null
                        }

                    </Space>
                </Space>
                <Spin spinning={loading}>
                    <Space className="mt-20" wrap>
                        {
                            currentProvider === 'fa' && faResult.map(icon =>
                                icon.styles.map(style =>
                                    <div className="icon pointer" key={`fa_${style}_${icon.name}`}
                                         onClick={() => selectIcon(FaIcons[style][icon.name], icon.label)}>
                                        <svg width="70%" height="70%"
                                             viewBox={"0 0 " + FaIcons[style][icon.name].icon[0] + " " + FaIcons[style][icon.name].icon[1]}>
                                            {typeof FaIcons[style][icon.name].icon[4] === 'string' ?
                                                <path d={FaIcons[style][icon.name].icon[4]} fill="currentColor"/>
                                                :
                                                FaIcons[style][icon.name].icon[4].map((item, index) =>
                                                    <path key={'path_' + index} d={item}
                                                          fill={index === 0 ? 'black' : 'lightgray'}/>
                                                )
                                            }
                                        </svg>
                                    </div>
                                ))
                        }
                        {
                            currentProvider === 'flatIcon' && flatResult.map(icon =>
                                <div className="icon pointer" key={icon.id} onClick={() => getFlatIcon(icon)}>
                                    <img style={{width: '70%'}} src={icon.images.svg}/>
                                </div>
                            )
                        }
                    </Space>
                </Spin>
            </Form>
        </Modal>
    )
}


