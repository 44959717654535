import React, {useEffect, useState} from 'react';
import {useWidgetContext} from "../../providers/WidgetProvider";
import SimpleNextStepBtn from "../component/SimpleNextStepBtn";
import Loading from "../component/Loading";

export default function TagStep() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, lng, bookingInfos, i18n} = widgetState;
    const {post, setter} = widgetDispatch;
    const [loading, setLoading] = useState(true)
    const [tags, setTags] = useState(true)

    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        fetchTags()
    }, [])

    const fetchTags = async () => {
        setLoading(true)
        const data = {widgetId: widget.id}

        const response = await post('get-tags', data);
        if (response['hydra:member']) {
            setTags(response['hydra:member'].filter(item => item.categories.length));
        }
        setLoading(false)
    }

    const toggleTag = (iri) => {
        let tags = [...selectedTags];
        if (tags.includes(iri)) {
            tags = tags.filter(item => item !== iri)
        } else {
            tags.push(iri)
        }

        setSelectedTags(tags);
        setter('bookingInfos', {...bookingInfos, date: null})
    }

    const resolveImage = (tag) => {

        let link = ''
        if (tag?.image?.native) {
            link = tag.image?.native.sm
        } else {
            let i = 0;
            while (!link && i < tag.categories.length) {
                if (tag.categories[i].images && tag.categories[i].images.length > 0) {
                    link = tag.categories[i].images[0]?.display.native.sm
                    i = tag.categories.length
                }
                i++
            }
        }

        return link;
    }

    function onClick() {
        setter('widget', {...widget, tags: selectedTags});
    }

    return (
        <div className="full background-grey">
            <div className="step_content">
                <Loading loading={loading}>
                    <p>{i18n.gettext('Filtrer par un ou plusieurs type de service')}</p>
                    <div className="flex wrap offers-container">
                        {
                            loading ?
                                <div className="offer-container" key={`dummy`}>
                                    <div className="offer relative">
                                        <div className="overlay"/>
                                    </div>
                                </div>
                                :
                                tags.map(tag => {
                                        let link = resolveImage(tag)
                                        return (
                                            <div className="offer-container"
                                                 style={{width: tags.length <= 3 ? '100%' : '50%'}}
                                                 key={tag.id}>
                                                <div
                                                    className="offer relative"
                                                    onClick={() => toggleTag(`/api/cat_tags/${tag.id}`)}
                                                    style={selectedTags.includes(`/api/cat_tags/${tag.id}`) ?
                                                        {border: `3px solid ${widget.backgroundColor || '#2494D1'}`} : null}
                                                >
                                                    <img
                                                        src={link}
                                                        alt="tag"
                                                    />

                                                    <div className="overlay"/>

                                                    <div className="category">
                                                        {tag.names[lng] ? tag.names[lng] : tag.names[widget.account.defaultLanguage]}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                        }
                    </div>
                </Loading>
            </div>

            <SimpleNextStepBtn onClick={onClick}/>
        </div>
    )
}