import React, { useEffect, useRef, useState } from "react";
import { useWidgetContext } from "../../providers/WidgetProvider";
import { ReactComponent as Location } from '../svg/location.svg'
import { ReactComponent as Calendar } from '../svg/calendar.svg'
import { ReactComponent as Clock } from '../svg/clock.svg'
import { ReactComponent as Services } from '../svg/services.svg'
import { ReactComponent as Privacy } from "../svg/privacy.svg";
import { ReactComponent as CheckCircle } from "../svg/check--circle.svg";

import Button from "../component/Button";
import { ReactComponent as Eye } from "../svg/eye.svg";
import Drawer from "../component/Drawer";
import Authentification from "../component/Authentification";
import OrderDetails from "../component/OrderDetails";
import Input from "../component/Input";
import { ReactComponent as User } from "../svg/user.svg";
import { ReactComponent as Email } from "../svg/email.svg";
import Addons from "../component/Addons";
import Loading from "../component/Loading";
import Alert from "../component/Alert";
import StockErrorFooter from "../component/StockError";
import { ReactComponent as Phone } from "../svg/phone.svg";
import getSymbolFromCurrency from "currency-symbol-map";

export default function ConfirmStep() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { next, stringDateDMonthY, post, setter, setBookingInfos, displayTime } = widgetDispatch;
    const { widget, bookingInfos, user, lng, debug, i18n } = widgetState;
    const [visible, setVisible] = useState(false)
    const [seeDetails, setSeeDetails] = useState(false)
    const [addServices, setAddService] = useState(false)
    const [isSignInUp, setIsSignInUp] = useState(false)
    const [loading, setLoading] = useState(false)

    const [arrivalHour, setArrivalHour] = useState(widgetState.bookingInfos.arrivalHour || undefined)
    const [hours, setHours] = useState([]);

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [error, setError] = useState([])
    const [bookingError, setBookingError] = useState(false)
    const [stockError, setStockError] = useState(false)

    const logRef = useRef();
    const container = useRef();

    useEffect(() => {
        const start = Number(widgetState.bookingInfos.offer.timeSlot.start.split('h').shift());
        const end = Number(widgetState.bookingInfos.offer.timeSlot.end.split('h').shift());
        const diff = end - start;

        for (let i = 0; i < diff; i++) {
            hours.push(`${start + i}h00`);
            hours.push(`${start + i}h30`);
        }

        setHours([...hours]);
    }, [widgetState.bookingInfos.offer])


    const total = bookingInfos.addons ?
        bookingInfos.offer.price + bookingInfos.addons.map(addon => addon.price * addon.amount).reduce((a, b) => a + b, 0)
        : bookingInfos.offer.price;

    const handleConnection = (isSignUp) => {
        setIsSignInUp(isSignUp)
        setVisible(true)
    }

    const handleChange = (e, setter) => {
        setter(e.target.value)
    }

    const checkUser = () => {
        if (!user.id && (email.trim() === "")) {
            setError(i18n.gettext("Veuillez compléter vos coordonnées"))
            logRef.current.scrollIntoView({ behavior: 'smooth' })
            return false
        }

        return true
    }

    const checkForm = (data) => {
        if (user.id) return true;
        let failed = []
        if (!data.email) failed.push('email')
        if (!data.firstname) failed.push('firstname')
        if (!data.lastname) failed.push('lastname')
        if (!data.phone) failed.push('phone')
        if (failed.length) {
            logRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        setError(failed)
        return failed.length === 0

    }

    async function book() {
        let data = { firstname: firstName.trim(), lastname: lastName.trim(), email: email.trim(), phone: phone.trim() };
        if (!checkForm(data)) return
        checkForm(data)
        setLoading(true)
        data.user = user.id ? `/api/users/${user.id}` : null
        data.categoryName = bookingInfos.offer.category.name;
        data.categoryId = bookingInfos.offer.category.type === 'duration' ? bookingInfos.offer.category.id : null;
        data.timeSlotName = `${bookingInfos.offer.timeSlot.start} - ${bookingInfos.offer.timeSlot.end}`;
        data.bookAt = bookingInfos.offer.day;
        data.account = `/api/accounts/${widget.account.id}`;
        data.offer = `/api/offers/${bookingInfos.offer.id}`;
        data.addons = bookingInfos.addons ? bookingInfos.addons.map(({ name, price, amount }) => ({
            name,
            price,
            amount
        })) : [];
        data.price = total;
        data.arrivedAt = bookingInfos.arrivalHour;
        data.comment = bookingInfos.comment;
        data.bookOn = debug ? 'Preview' : 'Widget';
        data.isConfidential = bookingInfos.offer.category.isConfidential;
        data.currency = widget.account.currency;
        data.language = lng;

        if (bookingInfos.offer.category.isLimited && bookingInfos.nbPeople)
            data.nbPeople = bookingInfos.nbPeople;

        delete data.id

        const response = await post("bookings", data);

        if (response['@type'] === 'hydra:Error') {
            // send error message
            if (response.errorType === 'stock') {
                setStockError(true)
            } else {
                setBookingError(true)
            }
        } else {
            setter('bookingId', response.bookingId);

            if (!data.user) {
                setter('user', { email: data.email })
            }

            if (!debug) {
                post('post-statistic', { widgetId: widget.id, action: 'booked', bookingId: response.id });
                setter('hasReserved', true);
            }
            gtmTrigger()
            next();
        }
        setLoading(false)
    }

    // Trigger a click on an hidden button to make gtm able to detect a valid booking
    const gtmTrigger = () => {

        const btn = document.createElement('button');
        btn.id = `brd_book_${widget.id}`;
        btn.textContent = 'Booking validation'
        btn.style.display = 'none';
        container.current.append(btn);
        btn.click();
        container.current.removeChild(btn);
    }

    const handleHour = (e) => {
        setArrivalHour(e.target.value)
        setBookingInfos({ ...widgetState.bookingInfos, arrivalHour: e.target.value })
    }

    return (
        <div className="background-grey" style={{ minHeight: '100%' }} ref={container}>
            {
                stockError &&
                <Alert visible={stockError} title={i18n.gettext('L\'offre sélectionnée n\'est plus disponible')}
                    message={i18n.gettext("Malheureusement cette offre n'est plus en stock.\nVous allez être redirigé.")}
                    footer={<StockErrorFooter />} />
            }

            {
                bookingError &&
                <Alert visible={bookingError} close={() => setBookingError(false)} title={i18n.gettext('Il y a eu une erreur')}
                    message={i18n.gettext("La réservation n'a pas pu être effectuée. Si le problème persiste, contacter un administrateur.")}
                    footer={
                        <Button onClick={() => setBookingError(false)}
                            style={{
                                width: '100%',
                                color: 'white',
                                backgroundColor: widget.backgroundColor || '#2494D1'
                            }}>
                            {i18n.gettext('continuer')}
                        </Button>} />
            }

            <Drawer visible={visible} close={() => setVisible(false)}>
                <Authentification close={() => setVisible(false)} isSignInUp={isSignInUp} />
            </Drawer>
            <Drawer visible={seeDetails} close={() => setSeeDetails(false)}>
                <OrderDetails close={() => setSeeDetails(false)} />
            </Drawer>
            <Drawer visible={addServices} close={() => setAddService(false)}
                contentStyle={{ backgroundColor: "#ecedee" }}>
                <Addons close={() => setAddService(false)} />
            </Drawer>
            <Loading loading={loading}>
                <div className="step_content flex column between" style={{ overflow: 'auto' }}>
                    <div>
                        <div className="confirm-tab">
                            <div className="confirm-tab-title">
                                <div className="background-transparent"
                                    style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                {i18n.gettext('Votre réservation')} :
                            </div>
                            <div className="confirm-tab-content">
                                <div className="tab-line">
                                    {/* Hotel infos*/}
                                    <div className="tab-infos">
                                        <div className="icon-square">
                                            <div className="background-transparent"
                                                style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                            <div style={{ color: widget.backgroundColor || '#2494D1' }}>
                                                <Location />
                                            </div>
                                        </div>
                                        <div className="infos">
                                            <div className="infos-title">{i18n.gettext('Hôtel')}</div>
                                            <div>{widget.account.name}</div>
                                        </div>
                                    </div>

                                    {/* Date infos*/}
                                    <div className="tab-infos">
                                        <div className="icon-square">
                                            <div className="background-transparent"
                                                style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                            <div style={{ color: widget.backgroundColor || '#2494D1' }}>
                                                <Calendar />
                                            </div>
                                        </div>
                                        <div className="infos">
                                            <div className="infos-title">{i18n.gettext('Votre date')}</div>
                                            <div>{stringDateDMonthY(bookingInfos.offer.day)}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Room picture*/}

                                <div className="thumb">
                                    <img
                                        src={bookingInfos.offer.category?.images[0]?.display?.native?.sm}
                                        alt="photo de la catégorie"
                                    />

                                    <div className="overlay" />

                                    <div className="infos">
                                        {bookingInfos.offer.category.surface &&
                                            <div className="surface">
                                                {bookingInfos.offer.category.surface} m²
                                            </div>
                                        }
                                        <div>
                                            {bookingInfos.offer.category.names[lng] || bookingInfos.offer.category.names[widget.account.defaultLanguage]}
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-line">
                                    {/* Check in infos*/}
                                    <div className="tab-infos">
                                        <div className="icon-square">
                                            <div className="background-transparent"
                                                style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                            <div style={{ color: widget.backgroundColor || '#2494D1' }}>
                                                <Clock />
                                            </div>
                                        </div>
                                        <div className="infos">
                                            <div className="infos-title">Check-in</div>
                                            <div>{displayTime(bookingInfos.offer.timeSlot.start)}</div>
                                        </div>
                                    </div>

                                    {/* Check out infos*/}
                                    <div className="tab-infos">
                                        <div className="infos">
                                            <div className="infos-title">Check-out</div>
                                            <div>{displayTime(bookingInfos.offer.timeSlot.end)}</div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    bookingInfos.offer.category.addons.length > 0 &&
                                    <div className="tab-line">
                                        {/* Services infos*/}
                                        <div className="tab-infos" style={{ width: '100%', justifyContent: "space-between" }}>

                                            <div style={{ display: "flex" }}>
                                                <div className="icon-square">
                                                    <div className="background-transparent"
                                                        style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                                    <div style={{ color: widget.backgroundColor || '#2494D1' }}>
                                                        <Services
                                                            style={{ maxWidth: '20px' }} />
                                                    </div>
                                                </div>


                                                <div className="infos">
                                                    <div className="infos-title">{i18n.gettext("Services additionnels")}</div>
                                                    <div>
                                                        {
                                                            bookingInfos.addons && bookingInfos.addons.map(addon => (
                                                                <div key={addon.id}>
                                                                    {addon.amount} x {addon.names[lng] ? addon.names[lng] : addon.names[widget.account.defaultLanguage]}
                                                                </div>
                                                            ))
                                                        }
                                                        <div key="add-more-addons"
                                                            style={{
                                                                cursor: "pointer",
                                                                color: widget.textColor || '#2494D1'
                                                            }}
                                                            onClick={() => setAddService(true)}>
                                                            {i18n.gettext("Modifier les services")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {bookingInfos?.nbPeople &&
                                                <div>
                                                    {bookingInfos.nbPeople} {i18n.gettext("personnes")}
                                                </div>}

                                        </div>
                                    </div>
                                }
                                <div className="paiement-details" style={{ margin: 0 }}>
                                    {i18n.gettext("Le paiement de votre réservation se fera directement à l'hôtel, au moment de votre venue !")}
                                </div>
                            </div>

                        </div>

                        {
                            bookingInfos.offer.category.type !== 'duration' &&
                            <div className="confirm-tab">
                                <div className="confirm-tab-title">
                                    <div className="background-transparent"
                                        style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                    {i18n.gettext("Précisez l'heure de votre arrivée")} &nbsp; <small> {i18n.gettext("(optionnel)")}</small>
                                </div>
                                <div className="confirm-tab-content">
                                    <div className="tab-line">
                                        <div className="tab-infos" style={{ width: '100%' }}>
                                            <select className="select-input" onChange={handleHour} value={arrivalHour}>
                                                <option key={'default'} value={""}>{i18n.gettext("Horaire d'arrivée")}</option>
                                                {hours.map(hour => (
                                                    <option key={hour} value={hour}>{displayTime(hour)}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="confirm-tab" ref={logRef}>
                            {
                                !widgetState.user.id &&
                                <div className="confirm-tab-title">
                                    <div className="background-transparent"
                                        style={{ backgroundColor: widget.backgroundColor || '#2494D1' }} />
                                    {i18n.gettext("compléter vos coordonnées")}
                                </div>
                            }
                            <div className="confirm-tab-content">
                                {
                                    widgetState.user.id ?
                                        <div className="tab-line">
                                            <div className="tab-infos" style={{ width: '100%' }}>
                                                <div className="icon-square">
                                                    <div>
                                                        <CheckCircle
                                                            style={{
                                                                color: widget.backgroundColor || '#2494D1',
                                                                maxWidth: '20px'
                                                            }} />
                                                    </div>
                                                </div>
                                                <div className="infos" style={{ justifyContent: 'center' }}>
                                                    <div>{i18n.gettext("Connecté à votre compte Breaking Day.")}</div>
                                                    <div
                                                        style={{ fontSize: '11px' }}>{widgetState.user.firstName} {widgetState.user.lastName} ({widgetState.user.email})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <p style={{
                                                position: 'absolute', color: "red", top: 0, left: 0, textAlign: 'center', width: '100%'
                                            }}>
                                                <small>{error.length ? i18n.gettext("Veuillez compléter vos coordonnées") : ''}</small>
                                            </p>
                                            <div className="tab-line">
                                                <Button style={{
                                                    width: '49%',
                                                    color: widget.backgroundColor || '#2494D1',
                                                    border: `1px solid ${widget.backgroundColor || '#2494D1'}`
                                                }}
                                                    onClick={() => handleConnection(false)}>{i18n.gettext("Se connecter")}</Button>
                                                <Button style={{
                                                    width: '49%',
                                                    backgroundColor: widget.backgroundColor || '#2494D1',
                                                    color: 'white'
                                                }}
                                                    onClick={() => handleConnection(true)}
                                                >
                                                    {i18n.gettext("S'inscrire")}
                                                </Button>
                                            </div>
                                            <p style={{
                                                textTransform: 'uppercase',
                                                fontSize: '12px',
                                                color: widget.backgroundColor || '#2494D1'
                                            }}>
                                                {i18n.gettext("ou Réserver sans compte")}
                                            </p>
                                            <div>
                                                <Input required value={email} error={error.includes('email')}
                                                    placeholder={i18n.gettext("Votre email")}
                                                    icon={<Email style={{ fill: widget.backgroundColor || "#2494D1" }} />}
                                                    onChange={(e) => handleChange(e, setEmail)} />
                                                <Input required value={firstName} error={error.includes('firstname')}
                                                    placeholder={i18n.gettext("Votre prénom")}
                                                    icon={<User style={{ fill: widget.backgroundColor || "#2494D1" }} />}
                                                    onChange={(e) => handleChange(e, setFirstName)} />

                                                <Input required value={lastName} error={error.includes('lastname')}
                                                    placeholder={i18n.gettext("Votre Nom")}
                                                    icon={<User style={{ fill: widget.backgroundColor || "#2494D1" }} />}
                                                    onChange={(e) => handleChange(e, setLastName)} />

                                                <Input value={phone} required error={error.includes('phone')}
                                                    placeholder={i18n.gettext("Votre téléphone portable")}
                                                    icon={<Phone style={{ fill: widget.backgroundColor || "#2494D1" }} />}
                                                    onChange={(e) => handleChange(e, setPhone)} />
                                            </div>
                                        </>
                                }
                                {
                                    bookingInfos.offer.category.isConfidential &&
                                    <div className="tab-line">
                                        <div className="tab-infos" style={{ width: '100%' }}>
                                            <div className="icon-square">
                                                <div>
                                                    <Privacy
                                                        style={{
                                                            color: widget.backgroundColor || '#2494D1',
                                                            maxWidth: '20px'
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="infos" style={{ justifyContent: 'center' }}>
                                                <div className="paiement-details"
                                                    style={{ margin: 0, textAlign: "left" }}>
                                                    {i18n.gettext('Votre adresse email, et votre téléphone ne seront pas communiqués à l\'hôtel.')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="confirm-actions">
                    <Button style={{ width: '40%' }} className="total-button" onClick={() => setSeeDetails(true)}>
                        <div className="total">
                            <span>{i18n.gettext('Total dû')}</span>
                            <span className="price"
                                title={`prix en ${widget.account.currency}`}>{total} {getSymbolFromCurrency(widget.account.currency)}</span>
                        </div>
                        <div className="icon">
                            <Eye style={{ color: widget.backgroundColor || '#2494D1', width: '50%' }} />
                        </div>
                    </Button>
                    <Button
                        style={{
                            width: '58%',
                            backgroundColor: widget.backgroundColor || '#2494D1', color: 'white',
                            textTransform: 'uppercase'
                        }}
                        onClick={book}
                    >
                        {i18n.gettext('valider ma réservation')}
                    </Button>
                </div>
            </Loading>
        </div>
    )
}