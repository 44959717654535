import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import "./styles/theme.less";
import "./styles/App.scss";

import {AuthProvider} from "./providers/AuthProvider";
import {ApiProvider} from "./providers/ApiProvider";
import {AccountProvider} from "./providers/AccountProvider";

import Router from './components/Router.js';


function App() {
    return (
        <Suspense fallback="loading">
            <AuthProvider>
                <ApiProvider>
                    <AccountProvider>
                        <BrowserRouter>
                            <Router/>
                        </BrowserRouter>
                    </AccountProvider>
                </ApiProvider>
            </AuthProvider>
        </Suspense>
    );
}

export default App;

