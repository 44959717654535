import React from "react";
import background from '../images/ergonomie-pic.jpg'
import {ReactComponent as Wave} from '../images/waves/ergonomie.svg';
import phone from '../images/ergonomie.png';

export default function Ergonomy() {
    return (
        <div style={{backgroundImage: `url(${background})`}} className="ergonomy-container" id="ergonomy">

            <div className="ergonomy-content">
                <div className="ergonomy-text">
                    <div>
                        <h1>Ergonomie</h1>
                    </div>

                    <div className="divider"/>

                    <p>
                        Ce widget au design mobile, s’intègre<br/>agréablement à votre site internet et offre<br/>une
                        prise
                        en main
                        facile pour le client afin<br/>de permettre une réservation simple et<br/>accessible.
                    </p>
                </div>

                <Wave/>
            </div>

            <img src={phone} alt=""/>

        </div>
    )
}