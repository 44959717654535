import React from 'react'


export default function ServiceCard({service, i18n}) {

    return (
        <div className="service-card">
            <div className="service-icon" dangerouslySetInnerHTML={{__html: service.icon}}>

            </div>

            <div>{service?.names?.[i18n.language] ? service?.names?.[i18n.language] : service.name}</div>
        </div>
    )
}