import React from "react";
import background from '../images/ergonomie-pic.jpg'
import {ReactComponent as Wave} from '../images/waves/ergonomie-mobile.svg';
import phone from '../images/ergonomie.png';
import {useTranslation} from "react-i18next";

export default function ErgonomyMobile() {

    const {t} = useTranslation();

    return (
        <div className="ergonomy-mobile-container" style={{backgroundImage: `url(${background})`}}>

            <div className="ergonomy-content-mobile">
                <div className="ergonomy-text-mobile">
                    <div>
                        <h1>{t('Ergonomie')}</h1>
                    </div>

                    <div className="divider-mobile"/>

                    <p>
                        {t('Ce widget au design mobile, s’intègre agréablement à votre site internet et offre une prise en main facile pour le client afin de permettre une réservation simple et accessible.')}
                    </p>
                </div>

                <Wave/>
            </div>

            <img src={phone} alt=""/>

        </div>
    )
}