import React, {useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Menu} from 'antd';
import {Link} from "react-router-dom";
import {UserOutlined} from '@ant-design/icons';
import {useAuthContext} from "../../../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../../providers/ApiProvider";

export default function AvatarDropdown() {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const {t, i18n} = useTranslation();

    const [lngOptions, setLngOptions] = useState([{
        id: localStorage.getItem('i18nextLng').substring(0, 2),
        lng: localStorage.getItem('i18nextLng').substring(0, 2)
    }])

    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        fetchLng()
        return () => {
            isMounted.current = false;
        }
    }, [])

    const fetchLng = async () => {
        let params = {
            filters: [{name: 'type', value:'admin'}]
        }
        let response = await apiFetchCollection('translations', params)
        if (isMounted.current) {
            if (response['@type'] !== 'hydra:Error') {
                setLngOptions(response['hydra:member'])
            }
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to={`/modifier-mon-profil/${auth.id}`}>{t('mon profil')}</Link>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.SubMenu
                key={'lng'}
                title={t('langue')}
            >
                {
                    lngOptions.map(lng => <Menu.Item
                        style={{color: lng.lng === localStorage.getItem('i18nextLng').substring(0, 2) ? '#1890ff' : ''}}
                        key={'lng/' + lng.lng} onClick={() => i18n.changeLanguage(lng.lng)}>{lng.lng}</Menu.Item>)
                }
            </Menu.SubMenu>
            <Menu.Divider/>
            <Menu.Item key="logout">
                <Link to="/logout">{t('logout')}</Link>
            </Menu.Item>
        </Menu>
    )

    return (
            <Dropdown overlay={menu} trigger={['click']}>
                <Button size="large" icon={<UserOutlined/>} ghost/>
            </Dropdown>
    )
}