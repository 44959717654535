import React, {useEffect, useState} from "react";
import {Button, Form, Image, Input, Select, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {CloseCircleTwoTone, PlusOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../Translation/LanguageSelect";
import {resolveNamesLanguage} from "../utils";


export function CategoriesTagsForm({modal, onClose, item, visible = true}) {

    const {t, i18n} = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const {setHapidamKey} = accountDispatch
    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiFetchSubResource, apiUpdateEntity} = apiDispatch
    const [loading, setLoading] = useState(false)
    const [catLoading, setCatLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [image, setImage] = useState(item?.image ? item.image : null)
    const [hapidamClient, setHapidamClient] = useState(null);
    const [hapidamLoading, setHapidamLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [language, setLanguage] = useState('en')
    const [_names, setNames] = useState(item?.names ? item.names : {en: ''})

    const [form] = Form.useForm();

    useEffect(() => {
        fetchCategories()
    }, [search])

    useEffect(() => {
        setHapidamLoading(true)
        if (!hapidamClient) {
            setHapidamClient(new Hapidam())
        } else {
            let now = new Date().getTime()
            let isHapidamKeyWorking = accountState.hapidamKey.time ? accountState.hapidamKey.time + 3600000 > now : false

            if (accountState.hapidamKey.key && isHapidamKeyWorking) {
                hapidamClient.setToken(accountState.hapidamKey.key)
                setHapidamLoading(false)
            } else {
                fetchHapidamKey()
            }
        }
    }, [hapidamClient])

    async function fetchHapidamKey() {
        let data = {accountId: accountState.account.id}
        let response = await apiPostEntity('hapidam-connect', data)
        if (response.success) {
            setHapidamKey(response.publicKey)
            hapidamClient.setToken(response.publicKey)
        }

        setHapidamLoading(false)
    }

    async function fetchCategories() {
        setCatLoading(true)
        let params = {
            id: accountState.account.id,
            itemsPerPage: 30,
            filters: [{value: search, name: 'name'}]
        }
        let response = await apiFetchSubResource("accounts", params, 'categories')
        console.log(response)
        setCategories(response['hydra:member'])
        setCatLoading(false)
    }

    async function onFinish(values) {
        setLoading(true)

        let params = {
            name: language === accountState.account.defaultLanguage ? values.name.trim() : _names[accountState.account.defaultLanguage],
            names: {..._names, [language]: values.name.trim()},
            image: image?.display || null,
            categories: values.categories
        }


        if (item) {
            await update(params)
        } else {
            params.account = accountState.account['@id']
            await postEntity(params)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(params) {
        let response = await apiPostEntity('cat_tags', params)
    }

    async function update(params) {
        let response = await apiUpdateEntity('cat_tags', item.id, params)
    }

    function onFinishFailed(values) {
        console.log(values)
    }

    function handleOk() {
        form.submit()
    }

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const handleHapidam = () => {
        hapidamClient.on('select', handleResponse);
        hapidamClient.open();
    }

    const handleResponse = (response) => {
        console.log('response', response)
        hapidamClient.off('select', handleResponse);
        hapidamClient.close();
        if (response.display)
            setImage(response);
    }

    const deleteImage = () => {
        setImage(null)
    }

    function connect() {
        hapidamClient.on('select', handleConnectResponse);
        hapidamClient.connect()

    }

    function handleConnectResponse(response) {
        hapidamClient.off('select', handleResponse);
        setHapidamLoading(true)
        let params = {
            hapidamKey: response.token
        }
        updateAccount(params)
    }

    async function updateAccount(params) {
        let response = await apiUpdateEntity('accounts', accountState.account.id, params)
        fetchHapidamKey()
    }

    function handleChange(changedValues, values) {
        console.log(changedValues)
    }

    const [searchInterval, setSearchInterval] = useState(null)

    function handleSearch(value) {
        clearTimeout(searchInterval)
        setCatLoading(true)
        setSearchInterval(setTimeout(function () {
            setSearch(value)
        }, 200))

    }

    const handleLanguageChange = (newLanguage) => {

        let values = form.getFieldsValue()
        if (language) {
            setNames(prev => {
                return {...prev, [language]: values.name.trim()}
            })
        }
        form.setFieldsValue({
            name: _names[newLanguage] || '',
        })
        setLanguage(newLanguage)
    }
console.log('image', image)
    const content = (
        <Spin spinning={loading}>
            <LanguageSelect callback={handleLanguageChange} currentLng={language}/>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                onValuesChange={handleChange}
                initialValues={{
                    name: _names[language] ? _names[language] : '',
                    categories: item ? [...item.categories] : [],
                }}
            >
                <div className="flex between">
                    <div style={{width: '45%'}}>
                        <Space>
                            <Form.Item
                                label={t('nom')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('La langue par défaut est requise'),
                                        validator: (rule, value) => {
                                            if ((language === accountState.account.defaultLanguage && !value) && !_names[accountState.account.defaultLanguage])
                                                return Promise.reject(new Error('La langue anglaise est requise'))

                                            return Promise.resolve();
                                        }
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Space>
                    </div>

                    <div style={{width: '45%'}}>
                        <Form.Item
                            wrapperCol={{span: 24}}
                            label={t("Photos")}
                            name="pictures"
                            tooltip={t("Utilisera une photo d'un des services liés si aucune photo n'est sélectionnée")}
                        >
                            <Space wrap>
                                <Spin spinning={hapidamLoading}>
                                    {image  && image?.native &&
                                    <div style={{position: 'relative'}}>
                                        <CloseCircleTwoTone
                                            style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 10,
                                                zIndex: 1000,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => deleteImage()}
                                        />
                                        <Image
                                            style={{
                                                width: 100,
                                                height: 100,
                                                objectFit: 'contain',
                                                backgroundColor: '#ddd'
                                            }}
                                            src={image.native.th}
                                            preview={{src: image.native.lg}}
                                        />
                                    </div>
                                    }
                                    {
                                        hapidamClient?.token ?
                                            !image?.native &&
                                            <div className="ant-upload ant-upload-select-picture-card">
                                                <div className="ant-upload"
                                                     onClick={handleHapidam}
                                                     style={{flexDirection: 'column'}}>
                                                    <PlusOutlined/>
                                                    <div style={{marginTop: 8}}>{t('ajouter')}</div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p>{t('Votre compte n\'est pas encore lié à la photothèque')} Hapidam</p>
                                                <Button onClick={connect}>{t('Connexion')} Hapidam</Button>
                                            </div>
                                    }
                                </Spin>
                            </Space>

                        </Form.Item>
                    </div>
                </div>
                <div style={{width: '45%'}}>
                    <Form.Item label={t('Services')}
                               name="categories">
                        <Select
                            mode="multiple"
                            style={{minWidth: '250px', width: '100%'}}
                            placeholder={t("Rechercher un service")}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            showSearch
                            onSearch={handleSearch}
                            loading={catLoading}
                        >
                            {
                                categories.map(item =>
                                    <Select.Option key={`${item.id}`} value={item['@id']} label={
                                        resolveNamesLanguage(i18n, accountState, item)
                                    }
                                    >
                                        {
                                            resolveNamesLanguage(i18n, accountState, item)
                                        }
                                    </Select.Option>
                                )
                            }
                        </Select>
                    </Form.Item>
                </div>
                {
                    !modal &&
                    <Form.Item>
                        <Space>
                            <Button onClick={onClose}>{t('retour')}</Button>
                            <Button type="primary" onClick={handleOk}>{item ? t("modifier") : t("créer")}</Button>
                        </Space>
                    </Form.Item>
                }

            </Form>
        </Spin>
    )

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `${t('Editer')} ${resolveNamesLanguage(i18n, accountState, item)}` : t("créer un type de service")}
                   onOk={handleOk}
                   okText={item ? t("modifier") : t("créer")}
                   onCancel={onClose}
                   cancelText={t('retour')}
                   width={1300}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}

