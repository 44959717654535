import React, {useEffect, useState} from 'react'
import CollectionTable from "../CollectionTable";
import {EditOutlined} from "@ant-design/icons";
import {AddonsForm} from "./AddonsForm";
import {useTranslation} from "react-i18next";
import {resolveNamesLanguage} from "../utils";

export default function AddonsList({id, needUpdate}) {

    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const {t, i18n} = useTranslation();

    const [contentVersion, setContentVersion] = useState(0)

    useEffect(()=> {
        setContentVersion(needUpdate)
    }, [needUpdate])

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }

    const columns = [
        {
            title: t('nom'),
            dataIndex: 'names',
            search: true,
            sorter: true,
            render: (names, record) => resolveNamesLanguage(i18n, accountState, record)
        },
        {
            title: t('prix'),
            dataIndex: "price",
            render: price => <span>{price} euros</span>
        },
        {
            title: t('actions'),
            dataIndex: 'email',
            search: true,
            sorter: true,
            render: (email, record) => <EditOutlined onClick={() => setSelectedItem(record)}/>
        }
    ];

    if (creationMode || selectedItem)
        return <AddonsForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <>
            <CollectionTable
                endpoint={`accounts/${id}/addons`}
                columns={columns}
                onClick={(item) => setSelectedItem({...item})}
                reload={contentVersion}
            />
        </>
    );
}