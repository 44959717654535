import React from "react";
import background from '../images/inscription-pic.jpeg'
import {ReactComponent as Wave} from '../images/waves/inscription.svg';
import {useTranslation} from "react-i18next";
import FormSubscription from "../FormSubscription";

export default function Subscription() {

    const {t} = useTranslation();

    return (
        <div className="subscription-container" style={{backgroundImage: `url(${background})`}} id="subscription">

            <div className="first-block">
                <h1>{t('Je m’inscris et teste le widget dès maintenant')}</h1>
            </div>

            <div className="second-block">
                <FormSubscription/>
            </div>

            <Wave/>
        </div>
    )
}