import React, { useEffect, useRef, useState } from "react";
import Step1 from "./step/Step1";
import { useWidgetContext } from "../providers/WidgetProvider";
import EndStep from "./step/EndStep";
import BeginStep from "./step/BeginStep";
import Step2 from "./step/Step2";
import ConfirmStep from "./step/ConfirmStep";
import ServiceStep from "./step/ServiceStep";
import Header from "./step/Header";
import TagStep from "./step/TagStep";


export default function MyModal({ }) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const { index, visible, widget, debug, hasReserved, local } = widgetState;
    const { post, setVisible } = widgetDispatch;

    const initialOverlayStyle = { backgroundColor: 'rgba(0, 0, 0, 0)' };
    const [overlayStyle, setOverlayStyle] = useState(initialOverlayStyle);

    const containerRef = useRef()

    const steps = widget.displayTags ?
        [<BeginStep />, <TagStep />, <Step1 />, <Step2 />, <ServiceStep />, <ConfirmStep />, <EndStep />]
        : [<BeginStep />, <Step1 />, <Step2 />, <ServiceStep />, <ConfirmStep />, <EndStep />];

    const stepDetails = widget.displayTags ?
        ['Ecran d\'accueil', 'Choix du type de service', 'Choix de la date', 'Choix du service', 'Choix des Extras, commentaire', 'Étape de confirmation', 'Étape finale']
        : ['Ecran d\'accueil', 'Choix de la date', 'Choix du service', 'Choix des Extras, commentaire', 'Étape de confirmation', 'Étape finale'];

    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {

        if (!debug) {
            const onHideHandler = ({ type }) => {

                if (!visible || (!navigator.userAgent.includes('Mobile') && type === 'visibilitychange')) return;

                if (navigator.userAgent.includes('Mobile') && type === 'visibilitychange' && document.visibilityState === 'visible') {
                    post('post-statistic', { widgetId: widget.id, action: 'opened' });
                } else {
                    const data = {
                        widgetId: widget.id,
                        action: 'closed',
                        step: stepDetails[index],
                        hasReserved: hasReserved
                    }
                    let url = process.env.API_ENTRYPOINT + '/api/public/post-statistic'

                    navigator.sendBeacon(url, JSON.stringify(data))
                }
            }

            // add events to watch the page change or closing on mobile and desktop
            document.addEventListener('visibilitychange', onHideHandler);
            window.addEventListener('pagehide', onHideHandler);

            return () => {
                document.removeEventListener('visibilitychange', onHideHandler);
                window.removeEventListener('pagehide', onHideHandler);
            };
        }
    }, [local, widget, index, hasReserved, visible])


    useEffect(() => {
        if (visible) {
            updateOverlayStyle('backgroundColor', 'rgba(0, 0, 0, 0.45)');
            document.body.style.overflow = 'hidden';

            if (!debug)
                post('post-statistic', { widgetId: widget.id, action: 'opened' });
        } else {
            document.body.style.overflow = "initial";
            updateOverlayStyle('backgroundColor', 'rgba(0, 0, 0, 0)');

            if (isLoaded && !debug) {
                const data = {
                    widgetId: widget.id,
                    action: 'closed',
                    step: stepDetails[index],
                    hasReserved: hasReserved
                }
                post('post-statistic', data);
            }
        }

        if (!isLoaded)
            setIsLoaded(true);
    }, [visible])

    useEffect(() => {
        if (containerRef.current)
            containerRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }, [index])

    function updateOverlayStyle(key, value) {
        let copy = { ...overlayStyle };

        copy[key] = value;
        setOverlayStyle(copy);
    }

    function stopPropagation(e) {
        e.preventDefault();
        e.stopPropagation();
    }


    return (
        visible ?
            <div
                onClick={() => local ? null : setVisible(false)}
                className="__resaday_overlay_modal__"
                style={overlayStyle}
            >
                <div
                    className="__resaday_modal__"
                    onClick={stopPropagation}
                >
                    <Header />
                    <div
                        className="__resaday_modal_content__ full"
                        ref={containerRef}
                    >
                        {steps[index]}
                    </div>
                </div>
            </div> : null
    )
}