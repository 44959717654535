import React, {useEffect, useRef, useState} from 'react';
import ReactCountryFlag from "react-country-flag";
import {Divider, Spin} from "antd";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";

export default function LanguageSelect({currentLng, callback, forceLng, setAllLanguages = () => {}}) {

    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [accountState] = useAccountContext();
    const [lngOptions, setLngOptions] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (forceLng) return
        setLngOptions(accountState.account.widgetLanguages ? accountState.account.widgetLanguages : [accountState.account.defaultLanguage])
        callback(accountState.account.defaultLanguage)
    }, [accountState.account])

    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;
        if (!forceLng) return
        fetchLng()
        return () => {
            isMounted.current = false;
        }
    }, [])

    const fetchLng = async () => {
        setLoading(true)
        let params = {
            filters: [{name: 'type', value: 'admin'}]
        }
        let response = await apiFetchCollection('translations', params)
        if (isMounted.current) {
            console.log(response)
            if (response['@type'] !== 'hydra:Error') {
                let allLngs = parseLngs(response['hydra:member']);
                console.log(allLngs)
                setLngOptions(allLngs)
                setAllLanguages(allLngs)
            }
        }
        setLoading(false)
    }

    function parseLngs(lngs) {
        let result = []
        lngs.map(lng => {
            result.push(lng.lng)
        })
        return [...new Set(result)]
    }

    return (
        <Spin spinning={loading}>
            <div className="mb-1em">
                {
                    lngOptions.map((lng, index) =>
                        <React.Fragment key={'lngText/' + lng}>
                            <div className={currentLng === lng ? "flag-select selected-lng" : "flag-select"}
                                 onClick={() => callback(lng)}>
                                {
                                    !forceLng && accountState.account.defaultLanguage === lng &&
                                        <span className="default">*</span>
                                }
                                <ReactCountryFlag
                                    countryCode={lng === 'en' ? 'GB' : lng}
                                    svg
                                    title={lng}
                                />
                            </div>
                            {
                                index < lngOptions.length - 1 && <Divider type="vertical"/>
                            }
                        </React.Fragment>
                    )
                }
            </div>
        </Spin>
    )
}