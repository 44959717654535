import React, {useState} from 'react';
import {Redirect, useHistory} from 'react-router-dom'
import {Button, Card, Form, Input, message} from 'antd';
import {useAuthContext} from "../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../providers/ApiProvider";
import logo from "../../public/logobd.png";

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function CreateAccount() {

    const {t} = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const {authLogin, authIsLogged} = authDispatch;
    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic} = apiDispatch
    const history = useHistory();

    const [processing, setProcessing] = useState(false);

    const onFinish = async (values) => {
        setProcessing(true);
        const response = await apiPostEntityPublic('public/create-account', values);

        if (!response.success)
            return displayError(t(response.message));

        await authLogin(values.email, values.password, (response) => {
            if (response.error)
                displayError(response.error);
        });
    };

    const displayError = (content) => {
        message.error(content);
        setProcessing(false);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    if (authIsLogged())
        return authState.auth.roles.includes('ROLE_ADMIN') ? <Redirect to="/"/> : <Redirect to="/accueil"/>

    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                padding: '0 84px',
                height: '100%',
                display: "flex",
                minHeight: '100vh'
            }}
        >
            <Card className="card-style" style={{width: 700, padding: '0 2em', margin: 0}}>
                <div className="text-center mb-1em">
                    <img src={logo} alt="" style={{width: 150}}/>
                </div>
                <h2 style={{textAlign: 'center', fontSize: '1.8em'}}>{t('Inscription')}</h2>
                <Form
                    {...layout}
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        margin: '1em auto',
                        minWidth: "50%",
                        padding: "1em",
                    }}
                >
                    <Form.Item
                        label={t('Prénom')}
                        name="firstname"
                        rules={[
                            {
                                required: true,
                                message: t('entrer un prénom!'),
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Nom')}
                        name="lastname"
                        rules={[
                            {
                                required: true,
                                message: t('entrer un nom!'),
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: t('entrer un username!'),
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {required: true, message: t('email requis')},
                            {type: 'email', message: t('email non valide!')}
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Nom de votre hôtel')}
                        name="accountName"
                        rules={[
                            {required: true, message: t('Nom de votre hôtel obligatoire')},
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('URL de votre site')}
                        name="url"
                        rules={[
                            {required: true, message: t('https://www.yourwebsite.com')},
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label={t("mot de passe")}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t('entrer un mot de passe!'),
                            },
                        ]}
                    >
                        <Input.Password autoComplete="new-password"/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label={t("confirmer le mot de passe")}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value)
                                        return Promise.resolve();

                                    return Promise.reject(t('les deux mots de passe ne sont pas indentiques'));
                                }
                            })
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={processing}>
                            {t('Valider')}
                        </Button>
                    </Form.Item>
                </Form>

                <div className="flex between">
                    <Button type="link" onClick={() => history.push(('/login-admin'))}>
                        {t('J\'ai déjà un compte')}
                    </Button>
                </div>
            </Card>
        </div>
    );
};
