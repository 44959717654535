import React, {useEffect, useState} from "react";
import {ReactComponent as Close} from '../svg/close.svg'
import {useWidgetContext} from "../../providers/WidgetProvider";

export default function Alert({visible, close, contentStyle, iconStyle, title, message, footer}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget} = widgetState
    const {setter} = widgetDispatch
    const [showContent, setShowContent] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)

    useEffect(() => {
        if (visible) {
            setShowDrawer(true)
            setTimeout(() => {
                setShowContent(true)
            }, 100)
            setter('isDrawerVisible', true)
        } else {
            setShowContent(false)
            setTimeout(() => {
                setShowDrawer(false)
                setter('isDrawerVisible', false)
            }, 200)

        }
    }, [visible])

    const handleClose = () => {
        if (close) close()
    }

    return (
        <div style={{display: showDrawer ? 'block' : 'none'}}>
            <div className="drawer-overlay" onClick={handleClose}/>
            <div className={`alert-content ${showContent ? '' : 'hidden'}`} onClick={(e) => e.stopPropagation()} style={{ ...contentStyle}}>
                {
                    close &&
                    <div style={{position: "absolute", top: "8px", right: "14px", cursor: 'pointer', zIndex: '3000', height: '24px', borderRadius:'12px', ...iconStyle}}
                         onClick={handleClose}>
                        <Close style={{fill: widget.backgroundColor || "#2494D1"}}/>
                    </div>
                }
                <div className="error-content">
                    <div className="title" style={{marginBottom: '16px'}}>
                        <h2>{title}</h2>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <p>{message}</p>
                    </div>
                    <div>
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
}