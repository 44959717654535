import React from 'react'
import {useWidgetContext} from "../../providers/WidgetProvider";
import Button from "./Button";
import Carousel from "./Carousel";
import getSymbolFromCurrency from "currency-symbol-map";

export default function CategoryDetails({offer, close}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, lng, i18n} = widgetState;
    const {setBookingInfos, next, displayTime} = widgetDispatch;
    const isMobile = window.innerWidth < 768;

    const handleSelection = () => {
        setBookingInfos({...widgetState.bookingInfos, offer: offer})
        close()
        next()
    }

    if (!offer)
        return (<div/>)

    return (
        <div className="order-content">

            {
                offer.category.images.length > 0 &&
                <div style={{height: '280px', width: '100%'}}>
                    <Carousel images={offer.category.images.filter(item => item !== null)} bgColor={widget.backgroundColor || '#2494D1'}/>
                </div>
            }
            <div style={{  padding: '16px 20px'}}>

            <div className="category-title" style={{marginBottom: '16px'}}>
                <h2 style={{marginBottom: 0, textAlign: 'left'}}>
                    {offer.category.names[lng] ? offer.category.names[lng] : offer.category.names[widget.account.defaultLanguage]} &nbsp;
                    {
                        offer.category.surface &&
                        <small>({offer.category.surface} m²)</small>
                    }
                </h2>
                <p style={{color: widget.backgroundColor || '#2494D1', marginBottom: 0}}>
                    {
                        displayTime(offer?.categoryTimeslot?.id ? offer.category.timeSlots[0].start : offer.timeSlot.start)
                    } {i18n.gettext('A')} {displayTime(offer?.categoryTimeslot?.id ? offer.category.timeSlots[0].end : offer.timeSlot.end)} &nbsp;
                    {
                        !offer?.categoryTimeslot?.id && !!offer.price &&
                        <span style={{fontWeight: 'bold'}} title={'prix en ' + widget.account.currency}>/ {offer.price} {getSymbolFromCurrency(widget.account.currency)}</span>
                    }
                </p>
            </div>
            <div style={{color: isMobile ? 'black' : "#B9BACF", margin:"16px"}}>
                    {offer.category.descriptions[lng] ? offer.category.descriptions[lng] : offer.category.descriptions[widget.account.defaultLanguage]}
            </div>

            {
                !!offer.category.services.length &&
                <div>
                    <h2 style={{margin: '16px 0', textAlign: 'left'}}>{i18n.gettext('Equipements')}</h2>

                    <div className="confirm-tab" style={{backgroundColor: 'transparent'}}>
                        <div className="confirm-tab-content" style={{padding: '0 16px'}}>
                            <div className="tab-line" style={{justifyContent: 'flex-start'}}>
                                {
                                    offer.category.services.map(service => (
                                        <div className="service-infos" style={{marginBottom: '8px'}}
                                             key={service.id}>
                                            <div className="icon-square">
                                                <div className="background-transparent"
                                                     style={{backgroundColor: widget.backgroundColor || '#2494D1'}}/>
                                                <div style={{color: widget.backgroundColor || '#2494D1', fill: widget.backgroundColor || '#2494D1'}}
                                                     dangerouslySetInnerHTML={{__html: service.icon}}/>
                                            </div>
                                            <div className="infos" style={{justifyContent: 'center', color: isMobile ? 'black' : "#B9BACF"}}>
                                                <div className="service">{service.names[lng] ? service.names[lng] : service.names[widget.account.defaultLanguage]}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>


                        </div>
                    </div>
                </div>
            }

            <div className="flex between" style={{position: 'sticky', bottom: '20px'}}>
                <Button onClick={close}
                        style={{
                            width: '49%',
                            color: widget.backgroundColor || '#2494D1',
                            backgroundColor: 'white',
                            border: `1px solid ${widget.backgroundColor || '#2494D1'}`
                        }}>{i18n.gettext('Retour')}</Button>
                <Button onClick={handleSelection}
                        style={{width: '49%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}}>
                    {i18n.gettext('Choisir cette offre')}
                </Button>
            </div>
            </div>

        </div>
    )
}