import React, {useEffect, useState} from 'react'
import {Button, Dropdown, Menu} from 'antd';
import {useAccountContext} from "../../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../../providers/ApiProvider";
import Preview from "../../Preview";

export default function PreviewDropdown({}) {

    const [accountState] = useAccountContext()
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource} = apiDispatch;
    const {t} = useTranslation();

    const [previewId, setPreviewId] = useState(null);
    const [widgets, setWidgets] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {
        const response = await apiFetchSubResource('accounts', {id: accountState.account.id}, 'widgets');
        setWidgets(response['hydra:member']);
    }

    function handleClick(e) {
        setLoading(true);
        setPreviewId(e.key);
        setTimeout(() => setLoading(false), 2000);

    }

    const menu = (
        <Menu onClick={handleClick}>
            {widgets.map(widget => (
                <Menu.Item key={widget.id}>{widget.name}</Menu.Item>
            ))}
        </Menu>
    )

    return (
        <>
            {previewId ?
                <Button
                    size="large"
                    loading={loading}
                    ghost
                    onClick={() => setPreviewId(null)}
                >
                    {t('Arrêter l’aperçu')}
                </Button>
                :
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                        size="large"
                        loading={loading}
                        ghost
                    >
                        {t('Aperçu')}
                    </Button>
                </Dropdown>}

            {previewId ? <Preview id={previewId}/> : null}
        </>
    )
}