import React, {useEffect, useState} from "react";
import {Checkbox, Row, Col, InputNumber, message, Modal, Space, Spin, Tree, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import DatePicker from "../DatePicker";
import {useTranslation} from "react-i18next";
import {resolveNamesLanguage} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";

const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;


const options = [
    {label: 'Lundi', value: 1},
    {label: 'Mardi', value: 2},
    {label: 'Mercredi', value: 3},
    {label: 'Jeudi', value: 4},
    {label: 'Vendredi', value: 5},
    {label: 'Samedi', value: 6},
    {label: 'Dimanche', value: 7},
];

export function PriceForm({modal, onClose, visible = true}) {

    const {t, i18n} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection, apiPostEntity} = apiDispatch;

    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);

    const [dates, setDates] = useState([]);

    const [price, setPrice] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [status, setStatus] = useState(null);

    const [checkedList, setCheckedList] = useState(options.map(option => option.value));
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(true);


    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        const data = {
            filters: [{name: 'account.id', value: accountState.account.id}]
        }

        const response = await apiFetchCollection('categories', data);

        genTree(response['hydra:member']);
        setLoading(false);
    }

    function genTree(categories) {
        let tree = [];

        categories.map(category => {
            if (category.timeSlots.length === 0 || category.type === 'duration') return
            let rowC = {
                title: resolveNamesLanguage(i18n, accountState, category),
                key: String(category.id),
                children: [],
                disabled: !category.timeSlots.length,
                selectable: false
            };

            category.timeSlots.map(timeSlot => {
                let rowTS = {
                    title: `${timeSlot.start} - ${timeSlot.end}`,
                    key: `${category.id}-${timeSlot.id}`,
                    selectable: false
                }

                rowC.children.push(rowTS);
            })

            tree.push(rowC)
        })

        setExpandedKeys(tree.map(t => t.key))
        setTreeData(tree);
    }

    function onExpand(expandedKeysValue) {
        setExpandedKeys(expandedKeysValue);
    }

    function onCheck(checkedKeysValue) {
        setCheckedKeys(checkedKeysValue);
    }

    function onChange(list) {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    }

    function onCheckAllChange(e) {
        setCheckedList(e.target.checked ? options.map(option => option.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    async function submit() {
        if (!checkedKeys.length || dates.length !== 2 || !checkedList.length || (price === null && quantity === null && !status))
            return message.error(t('tous les champs sont obligatoires'));

        setLoadingBtn(true);
        const data = {
            ids: checkedKeys.filter(key => key.includes('-')),
            dates: dates,
            days: checkedList,
            price: price,
            quantity: quantity,
            status: status
        }

        const response = await apiPostEntity('update-offers-long-term', data);

        setLoadingBtn(false);
        if (typeof onClose === 'function')
            onClose();

        message.success(`${response.totalUpdated} ${t('prix mis à jour.')}`);
    }

    const content = (
        <Spin spinning={loading}>

            <Row gutter={[16, 16]}>
                <Col lg={12}>
                    <Space direction="vertical">
                        <h4>{t('Sélectionner services(s) et plage(s) horaire(s)')}</h4>

                        <Tree
                            checkable
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            treeData={treeData}
                        />
                    </Space>
                </Col>

                <Col lg={12}>
                    <Space direction="vertical">
                        <h4>{t('fixer un prix')}</h4>
                        <Space wrap>
                            <InputNumber min={0} value={price} onChange={setPrice} placeholder={t('prix')}/>
                            <InputNumber min={0} value={quantity} onChange={setQuantity} placeholder={t('quantité')}/>
                            <Select
                                placeholder={t("statut")}
                                value={status}
                                onChange={setStatus}
                                style={{width: 200}}
                            >
                                <Select.Option value="available">{t('disponible')}</Select.Option>
                                <Select.Option value="notAvailable">{t('non disponible')}</Select.Option>
                            </Select>
                        </Space>
                        <small>{t('vos prix sont en ')} {accountState.account.currency} ({getSymbolFromCurrency(accountState.account.currency)})</small>

                        <h4>{t('Choisir l’intervalle de dates et jours')}</h4>

                        <RangePicker onChange={(moments, string) => setDates(string)}/>

                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            {t('tous les jours')}
                        </Checkbox>
                        <div className="checkbox-group-vertical">
                            <CheckboxGroup options={options} value={checkedList} onChange={onChange}/>
                        </div>
                    </Space>
                </Col>
            </Row>

        </Spin>
    )

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal
                visible={visible}
                width={1200}
                destroyOnClose
                title={t(`modifier des prix`)}
                onOk={submit}
                okText={t('valider')}
                onCancel={handleCancel}
                cancelText={t('retour')}
                confirmLoading={loadingBtn}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}