import React, {useEffect, useRef, useState} from 'react'
import {Button, List, Modal, Popconfirm, Skeleton} from "antd";
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {DeleteTwoTone} from "@ant-design/icons";
import UserPermissionForm from "./UserPermissionForm";
import {useTranslation} from "react-i18next";

export default function UserPermissions({user, setUser, modal}) {

    const [authState, authDispatch] = useAuthContext();
    const {auth} = authState;
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource, apiDeleteEntity, apiPostEntity} = apiDispatch;
    const [permissions, setPermissions] = useState([])
    const [loading, setLoading] = useState(false)
    const [needUpdate, setNeedUpdate] = useState(0)
    const {t} = useTranslation();

    const [isAddingPermission, setIsAddingPermission] = useState(false)

    useEffect(() => {
        if (!!user.id) {
            setLoading(true)
            fetchPermissions()
        }
    }, [user.id, needUpdate, isAddingPermission])


    const fetchPermissions = async () => {
        const params = {
            id: user.id,
        }
        let response = await apiFetchSubResource('users', params, 'permissions')
        if (response['@type'] === "hydra:Collection") {
            setPermissions(response['hydra:member'])
        }
        setLoading(false)
    }

    const handleDelete = async (permission) => {
        setLoading(true)
        await apiDeleteEntity('permissions', permission.id)
        setNeedUpdate(needUpdate + 1)
    }


    const formRef = useRef()

    const handleAdd = async () => {
        if (!isAddingPermission)
            return setIsAddingPermission(true)


        if (formRef.current) {
            formRef.current.submit()
        }
    }

    const content = (
        isAddingPermission ?
            <UserPermissionForm formRef={formRef} apiPostEntity={apiPostEntity}
                            setIsAddingPermission={setIsAddingPermission} user={user}/>
            :
            <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={permissions}
                renderItem={item => (
                    <List.Item>
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <List.Item.Meta
                                title={<span>{item.account.name}</span>}
                            />
                            <div style={{textAlign: 'right'}}>
                                {
                                    item.user && item.user.id === auth.id ? null :
                                        <Popconfirm placement="top"
                                                    title={t('etes-vous sûr de vouloir supprimer cette permission ?')}
                                                    onConfirm={() => handleDelete(item)} okText={t('oui')}
                                                    cancelText={t("non")}>
                                            <DeleteTwoTone/>
                                        </Popconfirm>
                                }
                            </div>
                        </Skeleton>
                    </List.Item>
                )}
            />
    )
    if (modal)
        return (
            <Modal
                visible
                title={"Permissions : " + user.username}
                onCancel={() => setUser(null)}
                footer={[
                    <Button key="back" onClick={() => setUser(null)}>
                        {t('fermer')}
                    </Button>,
                    isAddingPermission ?
                        <Button key="cancel" danger onClick={() => setIsAddingPermission(false)}>
                            {t('annuler')}
                        </Button>
                        : null,
                    <Button type="primary" key="add" onClick={handleAdd}>
                        {!isAddingPermission ? t("ajouter une permission") : t("valider")}
                    </Button>
                ]}>
                {content}
            </Modal>
        )

    return (content)
}