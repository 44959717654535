import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {Alert, Button, Spin} from "antd";
import ModalBookings from "../Modal/ModalBookings";
import BookingModal from "../Booking/BookingModal";
import HeaderTable from "./HeaderTable";
import NavigationDate from "./NavigationDate";
import ClassicCategories from "./ClassicCategories";
import DurationCategory from "./DurationCategory";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useAccountContext} from "../../providers/AccountProvider";
import {useApiContext} from "../../providers/ApiProvider";

const today = dayjs().format('YYYYMMDD');
const looping = Array.from({length: 7});

export default function BookingCalendar() {

    const {t, i18n} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource} = apiDispatch;

    const [needUpdate, setNeedUpdate] = useState(null);
    const [currentDay, setCurrentDay] = useState(today);
    const [loading, setLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [oldBookings, setOldBookings] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectedBookings, setSelectedBookings] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [visibleBooking, setVisibleBooking] = useState(false);
    const [offerId, setOfferId] = useState(0);

    useEffect(() => {
        fetchCategories()
        fetchBookings();
    }, [needUpdate, currentDay])

    async function fetchCategories() {
        let params = {
            id: accountState.account.id,
            filters: [
                {name: 'firstDateNeeded', value: dayjs(currentDay).format('DD-MM-YYYY')},
                {name: 'days', value: 7}
            ]
        }
        setLoading(true);
        let response = await apiFetchSubResource('accounts', params, 'categories')
        setCategories(response['hydra:member'])

        setLoading(false);
    }

    function getInterval() {
        let date = dayjs(currentDay);

        const after = date.format('YYYY-MM-DD');
        const before = date.add(7, 'day').format('YYYY-MM-DD');

        return {after, before};
    }

    async function fetchBookings() {
        const {after, before} = getInterval();

        const data = {
            id: accountState.account.id,
            filters: [
                {name: 'bookAt[after]', value: after},
                {name: 'bookAt[before]', value: before},
                {name: 'pagination', value: false}
            ]
        }
        const response = await apiFetchSubResource('accounts', data, 'bookings');
        setOldBookings(response['hydra:member'].filter(item => !item.offer && item.status !== 'canceled'));
        setBookings(response['hydra:member']);
    }

    function showModal(event, books) {
        event.stopPropagation();
        setSelectedBookings(books);
        setVisible(true);
    }

    function showBookingModal(id) {
        setOfferId(id);
        setVisibleBooking(true);
    }

    function showOldBookings() {

        setSelectedBookings(selectedCategory ? oldBookings.filter(item => item.categoryId === selectedCategory.id) : oldBookings)
        setVisible(true)
    }

    return (
        <Spin spinning={loading}>
            {
                !!oldBookings.length &&
                <Alert
                    message="Attention"
                    description="Suite à une modification de vos réglages, certaines anciennes réservations ne correspondent peut être plus aux nouveaux horaires."
                    type="warning"
                    showIcon
                    action={
                        <Button onClick={showOldBookings}>
                            Voir ces réservations
                        </Button>
                    }
                />
            }
            {selectedCategory ?
                <Button
                    type="link"
                    size="small"
                    onClick={() => setSelectedCategory(null)}
                    icon={<ArrowLeftOutlined/>}
                >
                    {t('Retour au tableau principal')}
                </Button> : null}

            <NavigationDate currentDay={currentDay} setCurrentDay={setCurrentDay}/>

            <div className="booking-tab">
                <HeaderTable looping={looping} currentDay={currentDay} i18n={i18n}/>

                {
                    !selectedCategory &&
                    <ClassicCategories
                        looping={looping}
                        currentDay={currentDay}
                        categories={categories}
                        showModal={showModal}
                        showBookingModal={showBookingModal}
                        setSelectedCategory={setSelectedCategory}
                        bookings={bookings}
                    />
                }
                {
                    selectedCategory && selectedCategory.type === 'duration' &&
                    <DurationCategory
                        looping={looping}
                        needUpdate={needUpdate}
                        currentDay={currentDay}
                        setLoading={setLoading}
                        showModal={showModal}
                        showBookingModal={showBookingModal}
                        setSelectedCategory={setSelectedCategory}
                        category={selectedCategory}
                        bookings={bookings.filter(item => item.categoryId === selectedCategory.id)}
                    />
                }
            </div>


            <ModalBookings
                visible={visible}
                setVisible={setVisible}
                bookings={selectedBookings}
                reload={() => setNeedUpdate(needUpdate + 1)}
                setBookings={setSelectedBookings}
            />

            {
                visibleBooking &&
                <BookingModal
                    visible={visibleBooking}
                    setVisible={setVisibleBooking}
                    offerId={offerId}
                    refresh={() => setNeedUpdate(needUpdate + 1)}
                />}
        </Spin>
    )
}