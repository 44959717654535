import React from 'react'

export default function ({children, style, className = "", onClick, type, disabled, id = ""}) {

    function handleClick() {
        if (onClick)
            onClick()
    }

    return (
        <button
            style={{...style}}
            className={'widget-button ' + className}
            onClick={handleClick}
            type={type}
            disabled={!!disabled}
            id={id}
        >
            {disabled ? <div className="disabled"/> : null}
            {children}
        </button>
    )
}