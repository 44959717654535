import SelectionArea from "@simonwep/selection-js";
import dayjs from "dayjs";


export function startSelection(onSelectionEnd = () => {}) {

    const selection = new SelectionArea({

        // Query selectors for elements which can be selected.
        selectables: ['.cell'],

        // Query selectors for elements from where a selection can be started from.
        startareas: ['.selections'],

        // Query selectors for elements which will be used as boundaries for the selection.
        boundaries: ['.selections'],

        // Specifies what should be done if already selected elements get selected again.
        //   invert: Invert selection for elements which were already selected
        //   keep: Make stored elements (by keepSelectio()) 'fix'
        //   drop: Remove stored elements after they have been touched
        overlap: 'keep',

    }).on('start', ({store, event}) => {

        // Remove class if the user isn't pressing the control key or ⌘ key
        if (!event.ctrlKey && !event.metaKey) {
            // Unselect all elements
            handleClass(store.stored, 'remove')

            // Clear previous selection
            selection.clearSelection();
        }

    }).on('move', ({event, store: {changed: {added, removed}}}) => {

        //Selection can now handle vertical scroll of the page
        let body = window.document.body
        let windowHeight = window.innerHeight

        if (event) {
            if (event.y >= windowHeight - 100 && body.scrollHeight > windowHeight + window.scrollY) {
                window.scrollTo(window.scrollX, window.scrollY + 10)
            } else if (event.y <= 100 && window.scrollY > 0) {
                window.scrollTo(window.scrollX, window.scrollY - 10)
            }
        }
        // Add a custom class to the elements that where selected.
        handleClass(added, 'add')

        // Remove the class from elements that where removed
        // since the last selection
        handleClass(removed, 'remove')


    }).on('stop', ({store}) => {
        selection.keepSelection();
        let selected = selection.getSelection()
        handleClass(selected, 'add')
        onSelectionEnd(selected)
    });

    return selection
}


export function handleClass(elements, action) {
    if (!Array.isArray(elements)) return
    if (action === 'add') {
        elements.map(el => {
            el.classList.add('selected');
        })
    } else {
        elements.map(el => {
            el.classList.remove('selected');
        })
    }

}

export const days = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
export const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aôut', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

export function getTimeSlots(start, end, interval, breaktime = 0) {
    const [hourStart, minuteStart] = start.split('h');
    let currentDate = dayjs().hour(hourStart).minute(minuteStart);
    const [hourEnd, minuteEnd] = end.split('h');
    let endDate = dayjs().hour(hourEnd).minute(minuteEnd);
    const [hourInterval, minuteInterval] = interval.split('h');
    let array = [];

    if (currentDate.isAfter(endDate))
        endDate = endDate.add(1, 'day')


    while (currentDate.isBefore(endDate)) {
        if (!!array.length) {
            currentDate = currentDate.add(breaktime, 'minute');
        }
        let timeSlot = `${currentDate.format('HH:mm')}-`;

        currentDate = currentDate.add(hourInterval, 'hour').add(minuteInterval, 'minute');

        timeSlot += currentDate.format('HH:mm');
        if (currentDate.isAfter(endDate)) continue
        array.push(timeSlot.replaceAll(':', 'h'));

    }

    return array;
}

export function resolveNamesLanguage(i18n, accountState, item) {
    return item.names[i18n.language] || item.names[accountState.account.defaultLanguage] || item.name
}

export function displayTime(lng = 'fr', time) {
    let timeArray = time.split('h')
    if (timeArray.length !== 2 ) return time

    let date = new Date()
    date.setHours(timeArray[0], timeArray[1])

    return date.toLocaleTimeString(lng, { hour: '2-digit', minute: '2-digit' })

}

export function localiseDate(lng, inputFormat, resultFormat = {year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'}) {
    var dAujourdhui = new Date(inputFormat);
    return dAujourdhui.toLocaleDateString(lng, resultFormat);
}

export function sortTimeSlot(a,b) {
    let aDate = dayjs(getDateObj(a.start))
    let bDate = dayjs(getDateObj(b.start))
    if (aDate.isBefore(bDate)) return -1;
    if (aDate.isAfter(bDate)) return 1
    return 0; 
}
function getDateObj(time) {
    let timeArray = time.split('h')
    if (timeArray.length !== 2 ) return time

    let date = new Date()
    date.setHours(timeArray[0], timeArray[1])
    return date;
}