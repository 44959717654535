import React, {useEffect, useState} from "react";
import {useWidgetContext} from "../../providers/WidgetProvider";
import Loading from "./Loading";

export default function NbPeople() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, i18n} = widgetState;
    const {setter} = widgetDispatch;

    const [values, setValues] = useState([]);

    useEffect(() => {
        const [min, max] = bookingInfos.offer.category.rangePeople;
        setNbPeople(min);

        let array = [];
        for (let i = min; i <= max; i++) {
            array.push(i);
        }

        setValues(array);
    }, [])

    function handleChange(e) {
        setNbPeople(e.target.value);
    }

    function setNbPeople(nbPeople) {
        let copy = {...bookingInfos};
        copy.nbPeople = Number(nbPeople);
        setter('bookingInfos', copy);
    }

    return (
        <Loading>
            <div className="confirm-tab">
                <div className="confirm-tab-title">
                    <div className="background-transparent"
                         style={{backgroundColor: widget.backgroundColor || '#2494D1'}}/>
                    {i18n.gettext('Pour combien de personnes souhaitez-vous réserver ?')}
                    <span style={{color: 'red'}}>*</span>
                </div>
                <div className="confirm-tab-content">
                    <div className="tab-line">
                        <div className="tab-infos" style={{width: '100%'}}>
                            <select className="select-input" value={bookingInfos.nbPeople} onChange={handleChange}>
                                {values.map(value => (
                                    <option key={value} value={value}>{value} {i18n.gettext("personnes")}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    )
}