import React from 'react'
import {useTranslation} from "react-i18next";
import {ReactComponent as Location} from "../../widget/svg/location.svg";
import {ReactComponent as Calendar} from "../../widget/svg/calendar.svg";
import {ReactComponent as Clock} from "../../widget/svg/clock.svg";
import {ReactComponent as Services} from "../../widget/svg/services.svg";
import {displayTime, localiseDate, resolveNamesLanguage} from "../utils";

const dateFormat = {year: 'numeric', month: 'long', day: 'numeric'};


export default function BookingRecap({offer, addons, user, account}) {

    const {t, i18n} = useTranslation();

    const total = addons ?
        offer.price + addons.map(addon => addon.price*addon.amount).reduce((a, b) => a + b, 0)
        : offer.price;

    return (
        <div>
                <div>
                    <div className="confirm-tab">
                        <div className="confirm-tab-title">
                            <div className="background-transparent"
                                 style={{backgroundColor: '#2494D1'}}/>
                            {t('Votre réservation')} :
                        </div>
                        <div className="confirm-tab-content">
                            <div className="tab-line">
                                {/* Hotel infos*/}
                                <div className="tab-infos">
                                    <div className="icon-square">
                                        <div className="background-transparent"
                                             style={{backgroundColor: '#2494D1'}}/>
                                        <div style={{color: '#2494D1'}}>
                                            <Location/>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="infos-title">{t('Hôtel')}</div>
                                        <div>{account.name}</div>
                                    </div>
                                </div>

                                {/* Date infos*/}
                                <div className="tab-infos">
                                    <div className="icon-square">
                                        <div className="background-transparent"
                                             style={{backgroundColor: '#2494D1'}}/>
                                        <div style={{color: '#2494D1'}}>
                                            <Calendar/>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="infos-title">{t('Votre date')}</div>
                                        <div>{localiseDate(i18n.language, offer.day, dateFormat)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-line">
                                {/* Check in infos*/}
                                <div className="tab-infos">
                                    <div className="icon-square">
                                        <div className="background-transparent"
                                             style={{backgroundColor: '#2494D1'}}/>
                                        <div style={{color: '#2494D1'}}>
                                            <Clock/>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="infos-title">{t('Check-in')}</div>
                                        <div>{displayTime(i18n.language, offer.timeSlot.start)}</div>
                                    </div>
                                </div>

                                {/* Check out infos*/}
                                <div className="tab-infos">
                                    <div className="infos">
                                        <div className="infos-title">{t('Check-out')}</div>
                                        <div>{displayTime(i18n.language,offer.timeSlot.end)}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-line">
                                {/* Services infos*/}
                                <div className="tab-infos" style={{width: '100%'}}>
                                    <div className="icon-square">
                                        <div className="background-transparent"
                                             style={{backgroundColor: '#2494D1'}}/>
                                        <div style={{color: '#2494D1'}}>
                                            <Services
                                                style={{maxWidth: '20px'}}/>
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <div className="infos-title">{t('Client')}</div>
                                        <div>
                                            <div>
                                                {user.firstname} {user.lastname}
                                            </div>
                                            <div >
                                                {user.email} - {user.phone}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            <div className="order-content">
                <div className="confirm-tab">
                    <div className="confirm-tab-title">
                        <div className="background-transparent"
                             style={{backgroundColor: '#2494D1'}}/>
                        {t('Détails tarifaire')} :
                    </div>
                    <div className="confirm-tab-content">
                        <div>
                                <div className="order-items">
                                    <div className="flex between" style={{marginBottom: '11px'}}>
                                            <span>{offer.category.names[i18n.language] ? offer.category.names[i18n.language] : offer.category.names[account.defaultLanguage]}</span>
                                        <span className="price" style={{color:  '#2494D1'}}>{offer.price} {account.currency}</span>
                                    </div>
                                    {/*map on the addons*/}
                                    {addons ? addons.map(addon => (
                                        <div className="flex between" key={addon.id}>
                                            <span>{addon.names[i18n.language] ? addon.names[i18n.language] : addon.names[account.defaultLanguage]}</span>
                                            <div>
                                                <span className="price" style={{color: '#2494D1'}}>{addon.amount} x </span>
                                                <span className="price" style={{color: '#2494D1'}}>{addon.price} {account.currency}</span>

                                            </div>
                                        </div>
                                    )) : null}

                                </div>
                                <div className="flex between">
                                    <span className="total">{t('Total dû')}</span>
                                    <span className="total" style={{color:  '#2494D1'}}>{total} {account.currency}</span>
                                </div>

                            </div>
                            <div className="paiement-details">
                                {t('Le paiement de la réservation se fera directement à l\'hôtel, au moment de votre venue !')}
                            </div>
                        </div>

                    </div>
                </div>


        </div>
    )
}
