import React, {useEffect, useState} from 'react'
import {Layout, Menu, Space} from 'antd';
import {Link, useLocation} from "react-router-dom";
import {useAccountContext} from "../../../providers/AccountProvider";
import AvatarDropdown from "./AvatarDropdown";
import {useTranslation} from "react-i18next";
import Preview from "../../Preview";
import {DownOutlined} from "@ant-design/icons";
import PreviewDropdown from "./PreviewDropdown";

const {Header, Content} = Layout;

export default function MainLayout({children}) {

    const [accountState] = useAccountContext();
    const {t} = useTranslation();
    const location = useLocation();

    const [seePreview, setSeePreview] = useState(false);

    useEffect(() => {
        if (seePreview)
            setSeePreview(false);
    }, [location.pathname])

    return (
        <Layout className="mainLayout">
            <Header className="header align-center">
                    <div className="logo"/>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]} overflowedIndicator={<DownOutlined />} style={{width: '100%'}}>
                        <Menu.Item key="/">
                            <Link to="/home">Home</Link>
                        </Menu.Item>
                        {accountState.account ?
                            <>
                                <Menu.Item key="/dashboard">
                                    <Link to="/dashboard">{t('dashboard')}</Link>
                                </Menu.Item>
                                <Menu.Item key="/prix">
                                    <Link to="/prix">{t('prix')}</Link>
                                </Menu.Item>
                                <Menu.Item key="/reservations">
                                    <Link to="/reservations">{t('réservations')}</Link>
                                </Menu.Item>
                                <Menu.Item key="/settings">
                                    <Link to="/settings">{t('configuration')}</Link>
                                </Menu.Item>
                            </>
                            : null}
                    </Menu>
                <Space>
                    {accountState.account ?
                        <PreviewDropdown/> : null}
                    <AvatarDropdown/>
                </Space>
            </Header>
            <Content className="flex" style={{padding: '0 50px'}}>
                <Layout className="site-layout-background" style={{padding: '24px 0', maxWidth: '100%'}}>
                    <Content style={{padding: '0 24px', minHeight: 280}}>
                        {children}
                        {seePreview ? <Preview/> : null}
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}