import React, {useRef, useState} from "react";
import {Form, Input, Modal, Select} from "antd";
import {useTranslation} from 'react-i18next';
import {useApiContext} from "../../providers/ApiProvider";

export default function TranslationForm({visible, modal, item, onClose}) {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch
    const formRef = useRef()
    const [loading, setLoading] = useState(false)


    async function onFinish(values) {
        setLoading(true)
        console.log(values)
        await postEntity(values)

        setLoading(false)
        onClose()
    }

    async function postEntity(values) {
        let params = {
            lng: values.lng,
        }
        let response = await apiPostEntity('translations', params)

    }

    const content = (
        <Form layout="vertical"
              ref={formRef}
              onFinish={onFinish}
        >
            <Form.Item label={t('nom')}
                       name="lng"
                       rules={[
                           {
                               required: true,
                               message: t('ce champs est obligatoire!'),
                           },
                       ]}>
                <Input/>
            </Form.Item>
        </Form>
    )

    function handleOk() {
        console.log(formRef)
        formRef.current.submit()
    }

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `${t('editer')} ${item.lng}` : t("créer une langue")}
                   onOk={handleOk}
                   okText={item ? t('modifier') : t("créer")}
                   onCancel={handleCancel}
                   cancelText={t("retour")}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }

};

