import React, {useEffect, useState} from 'react'
import Login from "./Login";
import SignUp from "./SignUp";
import {ReactComponent as CheckCircle} from "../svg/check--circle.svg";
import Button from "./Button";
import {useWidgetContext} from "../../providers/WidgetProvider";

export default function Authentification({close, isSignInUp}) {

    const [widgetState] = useWidgetContext();
    const {widget, i18n} = widgetState
    const [isSignIn, setIsSignIn] = useState(isSignInUp)

    useEffect(() => {
        console.log(isSignInUp)
        setIsSignIn(isSignInUp)
    }, [isSignInUp])

    function switchMode() {
        setIsSignIn(!isSignIn)
    }

    return (
        <div>
            {
                !widgetState.user.id ?
                    !isSignIn ?
                        <Login switchMode={switchMode}/> : <SignUp switchMode={switchMode}/>
                    :
                    <div style={{textAlign: "center", color: "black"}}>
                        <div style={{marginBottom: '16px'}}>
                            <CheckCircle style={{maxWidth:"55px"}}/>
                        </div>
                        <p style={{marginBottom: '16px', fontWeight: 'bold'}}>
                            {widgetState.user.type === 'connection' ? 'Connexion réussie !' : 'Inscription validée !'}
                        </p>
                        {
                            widgetState.user.type === 'connection' &&
                            <p className="greetings">
                                {i18n.gettext('Heureux de vous revoir')}, {widgetState.user.firstName} ! 👋🏻
                            </p>
                        }
                        <p style={{marginBottom: '63px'}}>
                            {i18n.gettext('Vous pouvez fermer cette pop-in et revenir à l’écran de confirmation de votre réservation.')}
                            😁
                        </p>
                        <Button 
                            style={{width: '100%', color: 'white', backgroundColor: widget.backgroundColor || '#2494D1'}} 
                            onClick={close}>
                            {i18n.gettext('Valider ma réservation')}
                        </Button>
                    </div>
            }
        </div>

    )
}