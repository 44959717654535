import React, {useEffect, useState} from 'react'
import {PageHeader, Tabs} from "antd";
import AccountList from "../components/Account/AccountList";
import {Redirect, useLocation} from "react-router-dom";
import {useAuthContext} from "../providers/AuthProvider";
import UserList from "../components/User/UserList";
import ServiceList from "../components/Service/ServiceList";
import TranslationDash from "../components/Translation/TranslationDash";
import {useTranslation} from "react-i18next";
import AdminSetting from "../components/AdminSetting";

export default function Admin({}) {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const {t} = useTranslation();

    const [activeTab, setActiveTab] = useState('account')
    const params = new URLSearchParams(useLocation().search)

    useEffect(() => {
        // console.log('pa', params.get("tab"))
        let tab = params.get("tab") ?? 'account'
        setActiveTab(tab)
    }, [params.get("tab")])


    if (!auth.roles.includes('ROLE_SUPER_ADMIN')) {
        return <Redirect to={{
            pathname: "/erreur",
            state: {status: '403', subtitle: 'Vous n\'êtes pas autorisé à accéder à cette page'}
        }}/>
    }

    return (
        <>
            <PageHeader
                title={<h1>Admin</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />
            <Tabs activeKey={activeTab} onTabClick={(key) => setActiveTab(key)}>
                <Tabs.TabPane tab={t('comptes')} key="account">
                    <AccountList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('utilisateurs')} key="users">
                    <UserList role="isAdmin"/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('Équipements')} key="services">
                    <ServiceList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('traduction')} key="traduction">
                    <TranslationDash/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('client')} key="client">
                    <UserList role="isClient"/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('réglages')} key="settings">
                    <AdminSetting/>
                </Tabs.TabPane>
            </Tabs>

        </>
    )
}