import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Image, Input, InputNumber, Modal, Radio, Select, Slider, Space, Spin, Switch} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {Editor} from "@tinymce/tinymce-react";
import {ReactComponent as Logo} from "../../widget/svg/logo.svg";
import ColorInput from "../ColorInput";
import ModalIconWidget from "../Modal/ModalIconWidget";
import LanguageSelect from "../Translation/LanguageSelect";
import TagSelect from "./TagSelect";
import {CloseCircleTwoTone, PlusOutlined} from "@ant-design/icons";

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export function WidgetForm({modal, onClose, item, visible = true}) {

    const [accountState, accountDispatch] = useAccountContext();
    const {account} = accountState;
    const {setHapidamKey} = accountDispatch
    const [apiDispatch] = useApiContext();
    const {apiPostEntity, apiUpdateEntity} = apiDispatch
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation();

    const formRef = useRef();

    const [description, setDescription] = useState(item ? item.description : {fr: null});
    const [absolutePosition, setAbsolutePosition] = useState(item ? item.absolutePosition : {bottom: 20, right: 20});

    const [textButton, setTextButton] = useState(item && item.textButton ? item.textButton : {fr: null});
    const [textNoAvailable, setTextNoAvailable] = useState(item && item.textNoAvailable ? item.textNoAvailable : {fr: null});
    const [icon, setIcon] = useState(item ? item.icon : '');
    const [backgroundColor, setBackgroundColor] = useState(item ? item.backgroundColor : 'blue');

    const [visibleIcon, setVisibleIcon] = useState(false);
    const [language, setLanguage] = useState(account.defaultLanguage ? account.defaultLanguage : 'en');
    const [isTrigger, setIsTrigger] = useState(item ? item.isTrigger : false);
    const [selectedTags, setSelectedTags] = useState(item ? item.tags : [])

    const [hapidamClient, setHapidamClient] = useState(null);
    const [hapidamLoading, setHapidamLoading] = useState(false);
    const [image, setImage] = useState(item?.image ? item.image : null)

    const [enableMobilStyle, setEnableMobileStyle] = useState(item?.enableMobileStyle ? item.enableMobileStyle : null)
    const [mobileAbsolutePosition, setMobileAbsolutePosition] = useState(item ? item.mobileAbsolutePosition : {
        bottom: 20,
        right: 20
    });

    useEffect(() => {
        setHapidamLoading(true)
        if (!hapidamClient) {
            setHapidamClient(new Hapidam())
        } else {
            let now = new Date().getTime()
            let isHapidamKeyWorking = accountState.hapidamKey.time ? accountState.hapidamKey.time + 3600000 > now : false

            if (accountState.hapidamKey.key && isHapidamKeyWorking) {
                hapidamClient.setToken(accountState.hapidamKey.key)
                setHapidamLoading(false)
            } else {
                fetchHapidamKey()
            }
        }
    }, [hapidamClient])

    async function fetchHapidamKey() {
        let data = {accountId: accountState.account.id}
        let response = await apiPostEntity('hapidam-connect', data)
        if (response.success) {
            setHapidamKey(response.publicKey)
            hapidamClient.setToken(response.publicKey)
        }

        setHapidamLoading(false)
    }

    const handleHapidam = () => {
        hapidamClient.on('select', handleResponse);
        hapidamClient.open();
    }

    const handleResponse = (response) => {
        hapidamClient.off('select', handleResponse);
        hapidamClient.close();
        if (response.display)
            setImage(response);
    }


    const deleteImage = () => {
        setImage(null)
    }

    function connect() {
        hapidamClient.on('select', handleConnectResponse);
        hapidamClient.connect()

    }

    function handleConnectResponse(response) {
        hapidamClient.off('select', handleResponse);
        setHapidamLoading(true)
        let params = {
            hapidamKey: response.token
        }
        updateAccount(params)
    }

    async function onFinish(values) {
        setLoading(true);

        const params = {
            name: values.name,
            description: description,
            position: values.position,
            mobilePosition: values.mobilePosition,
            absolutePosition: absolutePosition,
            mobileAbsolutePosition: mobileAbsolutePosition,
            textButton: textButton,
            icon: icon,
            backgroundColor: backgroundColor,
            triggerId: values.triggerId,
            isTrigger: isTrigger,
            tags: selectedTags,
            displayTags: values.displayTags,
            textNoAvailable: textNoAvailable,
            noAvailableOption: values.noAvailableOption || 'empty',
            image: image,
            enableMobileStyle: values.enableMobileStyle,
            mobileAdditionalStyle: {transform: `scale(0.${values.mobileRatio})`}
            // mobileAdditionalStyle: JSON.parse(values.mobileAdditionalStyle)
        }

        if (item) {
            await update(params);
        } else {
            await postEntity(params);
        }

        setLoading(false);
        onClose(true);
    }

    async function postEntity(params) {
        params.account = accountState.account['@id']
        await apiPostEntity('widgets', params);
    }

    async function update(params) {
        await apiUpdateEntity('widgets', item.id, params);
    }

    function onFinishFailed(values) {
        console.log(values);
    }

    function handleOk() {
        formRef.current.submit();
    }

    function updatePosition(key, value, device) {
        switch (device) {
            case "mobile":
                mobileAbsolutePosition[key] = value;
                setMobileAbsolutePosition({...mobileAbsolutePosition});
                break;
            default:
                absolutePosition[key] = value;
                setAbsolutePosition({...absolutePosition});
                break;
        }
    }

    function onPositionChange(value, device) {
        const [y, x] = value.split('_');
        const yValue = y === 'top' ? 100 : 20;

        switch (device) {
            case "mobile":
                setMobileAbsolutePosition({[x]: 20, [y]: yValue});
                break;
            default:
                setAbsolutePosition({[x]: 20, [y]: yValue});
                break;
        }
    }

    function handleTextWidgetChange(e) {
        textButton[language] = e.target.value;
        setTextButton({...textButton});
    }

    function handleNoAvailableChange(e) {
        textNoAvailable[language] = e.target.value;
        setTextNoAvailable({...textNoAvailable});
    }

    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage)
    }

    const content = (
        <Spin spinning={loading}>
            <LanguageSelect
                callback={handleLanguageChange}
                currentLng={language}
                account={account}
            />

            <Form
                {...layout}
                ref={formRef}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    name: item ? item.name : undefined,
                    description: item ? description[language] : undefined,
                    position: item ? item.position : 'bottom_right',
                    mobilePosition: item ? item.mobilePosition : 'bottom_right',
                    triggerId: item ? item.triggerId : null,
                    displayTags: item?.displayTags ? item.displayTags : false,
                    noAvailableOption: item?.noAvailableOption ? item.noAvailableOption : 'empty',
                    enableMobileStyle: item?.enableMobileStyle ? item?.enableMobileStyle : false,
                    //mobileAdditionalStyle: item?.mobileAdditionalStyle ? JSON.stringify(item.mobileAdditionalStyle) : false,
                    mobileRatio: item?.mobileAdditionalStyle?.transform ? Number(item.mobileAdditionalStyle.transform.match(/\(([^)]+)\)/)[1]) * 100 : 70
                }}
            >
                <div className="flex between">
                    <div style={{width: '45%'}}>
                        <Form.Item
                            label={t("nom")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t('entrer un nom!'),
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={t('description')}
                        >
                            <Editor
                                apiKey="gbvvbpfr94ka54sjradg08gdap68at2811ruttkx5ktlpzkd"
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | bold italic underline link | \
                                        alignleft aligncenter alignright alignjustify | help'
                                }}
                                onEditorChange={content => setDescription({...description, [language]: content})}
                                value={description[language]}
                            />
                        </Form.Item>

                        <Form.Item label={t("Couleurs du fond")} name="backgroundColor"
                                   tooltip={t("Sélectionner la couleur du fond")}>
                            <Space direction="vertical">
                                <div className="transparent-bg radius-5 color-container">
                                    <ColorInput setColor={(color) => setBackgroundColor(color)}
                                                color={backgroundColor}/>
                                </div>
                            </Space>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{span: 24}}
                            label={t("Photos")}
                            name="pictures"
                            tooltip={t("Utilisera une photo d'un des services lié si aucune photo n'est sélectionnée")}
                        >
                            <Space wrap>
                                <Spin spinning={hapidamLoading}>
                                    {image &&
                                    <div style={{position: 'relative'}}>
                                        <CloseCircleTwoTone
                                            style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 10,
                                                zIndex: 1000,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => deleteImage()}
                                        />
                                        <Image
                                            style={{
                                                width: 100,
                                                height: 100,
                                                objectFit: 'contain',
                                                backgroundColor: '#ddd'
                                            }}
                                            src={image.display?.native.th}
                                            preview={{
                                                src: image.display?.native.lg,
                                            }}
                                        />
                                    </div>
                                    }
                                    {
                                        hapidamClient?.token ?
                                            !image &&
                                            <div className="ant-upload ant-upload-select-picture-card">
                                                <div className="ant-upload"
                                                     onClick={handleHapidam}
                                                     style={{flexDirection: 'column'}}>
                                                    <PlusOutlined/>
                                                    <div style={{marginTop: 8}}>{t('ajouter')}</div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p>{t('Votre compte n\'est pas encore lié à la phototèque')} Hapidam</p>
                                                <Button onClick={connect}>{t('Connexion')} Hapidam</Button>
                                            </div>
                                    }
                                </Spin>
                            </Space>

                        </Form.Item>
                    </div>

                    <div style={{width: '45%'}}>
                        <Form.Item>
                            <Form.Item>
                                <Radio.Group value={isTrigger} onChange={e => setIsTrigger(e.target.value)}>
                                    <Radio value={false}>{t('Ouverture automatique du widget')}</Radio>
                                    <Radio value={true}>{t('Au clic sur un bouton')}</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {!isTrigger ?
                                <>
                                    <Form.Item label={t("Position du widget")}>
                                        <Form.Item
                                            name="position"
                                            tooltip={t("Le widget apparaîtra a cette position sur la page")}
                                        >
                                            <Select
                                                style={{width: 250}}
                                                onChange={value => onPositionChange(value, 'desktop')}
                                            >
                                                <Select.Option
                                                    value="bottom_right">{t('En bas à droite')}</Select.Option>
                                                <Select.Option
                                                    value="bottom_left">{t('En bas à gauche')}</Select.Option>
                                                <Select.Option
                                                    value="top_right">{t('En haut à droite')}</Select.Option>
                                                <Select.Option
                                                    value="top_left">{t('En haut à gauche')}</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            tooltip={t('Le widget apparaîtra à cette position sur la page')}
                                        >
                                            <Space>
                                                {absolutePosition.top !== undefined ?
                                                    <div>{t('haut')}&nbsp;
                                                        <InputNumber
                                                            value={absolutePosition.top}
                                                            onChange={value => updatePosition('top', value, 'desktop')}
                                                        />
                                                    </div> : null}
                                                {absolutePosition.right !== undefined ?
                                                    <div>{t('droite')}&nbsp;
                                                        <InputNumber
                                                            value={absolutePosition.right}
                                                            onChange={value => updatePosition('right', value, 'desktop')}
                                                        />
                                                    </div> : null}
                                                {absolutePosition.bottom !== undefined ?
                                                    <div>{t('bas')}&nbsp;
                                                        <InputNumber
                                                            value={absolutePosition.bottom}
                                                            onChange={value => updatePosition('bottom', value, 'desktop')}
                                                        />
                                                    </div> : null}
                                                {absolutePosition.left !== undefined ?
                                                    <div>{t('gauche')}&nbsp;
                                                        <InputNumber
                                                            value={absolutePosition.left}
                                                            onChange={value => updatePosition('left', value, 'desktop')}
                                                        />
                                                    </div> : null}
                                            </Space>
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item
                                        label={t('Texte bouton d\'ouverture')}
                                        tooltip={t('Texte afficher sur le bouton d\'ouverture du widget')}
                                    >
                                        <Input
                                            placeholder={t('Réserver en journée')}
                                            value={textButton[language]}
                                            onChange={handleTextWidgetChange}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={t('Icone du widget')}
                                        tooltip={t('Icone qui apparait sur le bouton d\'ouverture du widget')}
                                    >
                                        <Space>
                                            {icon ?
                                                <div
                                                    className="icon-widget-btn"
                                                    style={{
                                                        color: backgroundColor || '#2494D1',
                                                        fill: backgroundColor || '#2494D1'
                                                    }}
                                                    dangerouslySetInnerHTML={{__html: icon}}
                                                /> :
                                                <Logo style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    color: backgroundColor || '#2494D1',
                                                }}/>}
                                            <Button onClick={() => setVisibleIcon(true)}>
                                                {t('Changer')}
                                            </Button>
                                        </Space>
                                    </Form.Item>

                                    <Form.Item
                                        label={t('Style spécifique sur mobile')}
                                        name="enableMobileStyle"
                                        valuePropName="checked"
                                    >
                                        <Switch onChange={setEnableMobileStyle}/>
                                    </Form.Item>

                                    {enableMobilStyle ?
                                        <>
                                            <Form.Item label={t("Position du widget sur mobile")}>
                                                <Form.Item
                                                    name="mobilePosition"
                                                    tooltip={t("Le widget apparaîtra a cette position sur la page en mobile")}
                                                >
                                                    <Select
                                                        style={{width: 250}}
                                                        onChange={value => onPositionChange(value, 'mobile')}
                                                    >
                                                        <Select.Option
                                                            value="bottom_right">{t('En bas à droite')}</Select.Option>
                                                        <Select.Option
                                                            value="bottom_left">{t('En bas à gauche')}</Select.Option>
                                                        <Select.Option
                                                            value="top_right">{t('En haut à droite')}</Select.Option>
                                                        <Select.Option
                                                            value="top_left">{t('En haut à gauche')}</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    tooltip={t('Le widget apparaîtra à cette position sur la page en mobile')}
                                                >
                                                    <Space>
                                                        {mobileAbsolutePosition.top !== undefined ?
                                                            <div>{t('haut')}&nbsp;
                                                                <InputNumber
                                                                    value={mobileAbsolutePosition.top}
                                                                    onChange={value => updatePosition('top', value, 'mobile')}
                                                                />
                                                            </div> : null}
                                                        {mobileAbsolutePosition.right !== undefined ?
                                                            <div>{t('droite')}&nbsp;
                                                                <InputNumber
                                                                    value={mobileAbsolutePosition.right}
                                                                    onChange={value => updatePosition('right', value, 'mobile')}
                                                                />
                                                            </div> : null}
                                                        {mobileAbsolutePosition.bottom !== undefined ?
                                                            <div>{t('bas')}&nbsp;
                                                                <InputNumber
                                                                    value={mobileAbsolutePosition.bottom}
                                                                    onChange={value => updatePosition('bottom', value, 'mobile')}
                                                                />
                                                            </div> : null}
                                                        {mobileAbsolutePosition.left !== undefined ?
                                                            <div>{t('gauche')}&nbsp;
                                                                <InputNumber
                                                                    value={mobileAbsolutePosition.left}
                                                                    onChange={value => updatePosition('left', value, 'mobile')}
                                                                />
                                                            </div> : null}
                                                    </Space>
                                                </Form.Item>

                                            </Form.Item>
                                            {/*<Form.Item*/}
                                            {/*    name="mobileAdditionalStyle"*/}
                                            {/*    label={t('Style additionnel pour mobile')}*/}
                                            {/*    tooltip={t('Format JavaScript (camelCase)')}*/}
                                            {/*>*/}
                                            {/*    <Input/>*/}
                                            {/*</Form.Item>*/}
                                            <Form.Item
                                                label={t('Taille du bouton d\'ouverture sur mobile')}
                                                tooltip={t('Ratio en pourcentage, 100% = taille normal, 70% = 70% de sa taille normal')}
                                                name="mobileRatio"
                                            >
                                                <Slider defaultValue={30} tipFormatter={value => `${value} %`}/>
                                            </Form.Item>
                                        </> : null
                                    }
                                </>
                                : null}

                            {isTrigger ?
                                <Form.Item
                                    name="triggerId"
                                    label={t('Id du bouton')}
                                >
                                    <Input/>
                                </Form.Item> : null}
                        </Form.Item>


                        <Form.Item>
                            <Form.Item
                                label={t('Filter par tags')}
                                tooltip={t('Afficher uniquement les services liées a ces tags')}
                                name="tags"
                            >
                                <TagSelect selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                            </Form.Item>

                            <Form.Item
                                label={t('Étape de sélection par tag')}
                                tooltip={t('Rajoute une étape avant le calendrier, en donnant le choix d\'une catégorie de service')}
                                name="displayTags"
                            >
                                <Radio.Group>
                                    <Radio value={true}>{t('Afficher la sélection par tag')}</Radio>
                                    <Radio value={false}>{t('Ne pas l\'afficher')}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item
                            label={t('Texte en cas de non disponibilité')}
                            tooltip={t('Texte afficher en cas de non disponibilité')}
                        >
                            <Input.TextArea
                                placeholder={t('Nous sommes désolé, nous n\'avons plus de disponibilité')}
                                value={textNoAvailable[language]}
                                onChange={handleNoAvailableChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('Afficher d\'autres offres en cas de non disponibilité')}
                            tooltip={t('Affiche les offres selectionnées en cas de non disponibilité')}
                            name="noAvailableOption"
                        >
                            <Radio.Group>
                                <Radio value="displayTags">{t('Affiché les types de services')}</Radio>
                                <Radio value="empty">{t('Ne rien afficher')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                </div>
            </Form>

            <ModalIconWidget visible={visibleIcon} setVisible={setVisibleIcon} setIconWidget={setIcon}/>
        </Spin>
    )

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal visible={visible}
                   destroyOnClose
                   title={item ? `${t('editer')} ${item.name}` : t("Créer un nouveau widget")}
                   onOk={handleOk}
                   okText={item ? t("modifier") : t("créer")}
                   onCancel={handleCancel}
                   cancelText={t("retour")}
                   width={1300}
                   style={{top: '20px'}}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}