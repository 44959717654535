import React, {useEffect, useState} from "react";
import {Checkbox, Row, Col, InputNumber, message, Modal, Space, Spin, Select} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {useAccountContext} from "../../providers/AccountProvider";
import DatePicker from "../DatePicker";
import {useTranslation} from "react-i18next";
import {getTimeSlots} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";

const {RangePicker} = DatePicker;
const CheckboxGroup = Checkbox.Group;


const options = [
    {label: 'Lundi', value: 1},
    {label: 'Mardi', value: 2},
    {label: 'Mercredi', value: 3},
    {label: 'Jeudi', value: 4},
    {label: 'Vendredi', value: 5},
    {label: 'Samedi', value: 6},
    {label: 'Dimanche', value: 7},
];

export function PriceFormDuration({modal, onClose, category, visible = true}) {

    const {t} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiPostEntity} = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const [timeSlots, setTimeSlots] = useState([]);
    const [checkedTimes, setCheckedTimes] = useState([]);
    const [allTimesChecked, setAllTimesChecked] = useState(false);
    const [indeterminateTimes, setIndeterminateTimes] = useState(false);

    const [dates, setDates] = useState([]);

    const [price, setPrice] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [status, setStatus] = useState(null);

    const [checkedList, setCheckedList] = useState(options.map(option => option.value));
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(true);


    useEffect(() => {
        if (!category.timeSlots) return
        setLoading(true)
        let array = getTimeSlots(category.timeSlots[0].start, category.timeSlots[0].end, category.duration, category.breaktime);

        setTimeSlots(formatTimeData(array));
        setLoading(false)

    }, [category])

    function onChange(list, target) {

        if (target === 'days') {
            setCheckedList(list);
            setIndeterminate(!!list.length && list.length < options.length);
            setCheckAll(list.length === options.length);
        } else {
            setCheckedTimes(list);
            setIndeterminateTimes(!!list.length && list.length < timeSlots.length);
            setAllTimesChecked(list.length === timeSlots.length);
        }

    }

    function onCheckAllChange(e, target) {
        if (target === 'days') {
            setCheckedList(e.target.checked ? options.map(option => option.value) : []);
            setIndeterminate(false);
            setCheckAll(e.target.checked);
        } else {
            setCheckedTimes(e.target.checked ? timeSlots.map(option => option.value) : []);
            setIndeterminateTimes(false);
            setAllTimesChecked(e.target.checked);
        }

    }

    async function submit() {
        if (!checkedTimes.length || dates.length !== 2 || !checkedList.length || (price === null && quantity === null && !status))
            return message.error(t('Tous les champs sont obligatoires'));

        setLoadingBtn(true);
        const data = {
            ids: checkedTimes.filter(key => key.includes('-')),
            dates: dates,
            days: checkedList,
            price: price,
            quantity: quantity,
            status: status,
            isActivity: true,
            categoryId: category.id
        }

        const response = await apiPostEntity('update-offers-long-term', data);

        setLoadingBtn(false);
        if (typeof onClose === 'function')
            onClose();

        message.success(`${response.totalUpdated} ${t('prix mis à jour.')}`);
    }

    function formatTimeData(times) {
        let data = []

        times.map(time => data.push({label: time, value: time}))

        return data;
    }

    const content = (
        <Spin spinning={loading}>

            <Row gutter={[16, 16]}>
                <Col lg={12}>
                    <Space direction="vertical">
                        <h4>{t('Sélectionner les plages horaires')}</h4>

                        <Checkbox indeterminate={indeterminateTimes} onChange={(e) => onCheckAllChange(e, 'times')} checked={allTimesChecked}>
                            {t('Tous les horaires')}
                        </Checkbox>
                        <div className="checkbox-group-vertical">
                            <CheckboxGroup options={timeSlots} value={checkedTimes} onChange={(list) => onChange(list, 'times')}/>
                        </div>
                    </Space>
                </Col>

                <Col lg={12}>
                    <Space direction="vertical">
                        <h4>{t('fixer un prix')}</h4>
                        <Space wrap>
                            <InputNumber min={0} value={price} onChange={setPrice} placeholder={t('prix')}/>
                            <InputNumber min={0} value={quantity} onChange={setQuantity} placeholder={t('quantité')}/>
                            <Select
                                placeholder={t("statut")}
                                value={status}
                                onChange={setStatus}
                                style={{width: 200}}
                            >
                                <Select.Option value="available">{t('disponible')}</Select.Option>
                                <Select.Option value="notAvailable">{t('non disponible')}</Select.Option>
                            </Select>
                        </Space>
                        <small>{t('vos prix sont en ')} {accountState.account.currency} ({getSymbolFromCurrency(accountState.account.currency)})</small>

                        <h4>{t('Choisir l’intervalle de dates et jours')}</h4>

                        <RangePicker onChange={(moments, string) => setDates(string)}/>

                        <Checkbox indeterminate={indeterminate} onChange={(e) => onCheckAllChange(e, 'days')} checked={checkAll}>
                            {t('tous les jours')}
                        </Checkbox>
                        <div className="checkbox-group-vertical">
                            <CheckboxGroup options={options} value={checkedList} onChange={(list) => onChange(list, 'days')}/>
                        </div>
                    </Space>
                </Col>
            </Row>

        </Spin>
    )

    const handleCancel = () => {
        onClose();
    }

    if (modal) {
        return (
            <Modal
                visible={visible}
                width={1200}
                destroyOnClose
                title={t(`modifier des prix`)}
                onOk={submit}
                okText={t('valider')}
                onCancel={handleCancel}
                cancelText={t('retour')}
                confirmLoading={loadingBtn}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}