import React, {useState} from 'react';
import {Link} from 'react-router-dom'

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import dayjs from "dayjs";
import {Badge, Button} from "antd";
import {CommentOutlined} from "@ant-design/icons";
import CommentDrawer from "../Drawer/CommentDrawer";
import {useTranslation} from "react-i18next";
import DropdownStatus from "./DropdownStatus";
import {displayTime, resolveNamesLanguage} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";

const ReservationDash = ({id, endpoint, cardTitle, ...rest}) => {

    const [selectedItem, setSelectedItem] = useState(null)
    const [contentVersion, setContentVersion] = useState(0)
    const {t, i18n} = useTranslation();

    const initialFilters = [{name: 'bookAt[after]', value: dayjs().format('YYYY-MM-DD')}, {
        name: 'status',
        value: 'booked'
    }];
    const [filters, setFilters] = useState(initialFilters);

    const [visible, setVisible] = useState(false);
    const [bookingSelected, setBookingSelected] = useState({});

    const columns = [
        {
            title: t('client'),
            dataIndex: 'firstname',
            fixed: 'left',
            render: (firstname, record) => <>
                <div>
                    {firstname} {record.lastname}
                </div>
                {
                    !record.isConfidential && <div><small>{record.email} - {record.phone} </small></div>
                }
            </>
        },
        {
            title: t('service'),
            dataIndex: 'categoryName',
            render: categoryName => <small>{categoryName}</small>
        },
        {
            title: `${('Date')} / ${t('horaire')}`,
            dataIndex: 'bookAt',
            render: (bookAt, record) => <span>
                {dayjs(bookAt).format('DD/MM/YYYY')} <br/>
                <small>{displayTime(i18n.language, record.timeSlotName.split('-')[0])}-{displayTime(i18n.language, record.timeSlotName.split('-')[1])}</small>
            </span>
        },
        {
            title: t('heure d\'arrivée'),
            dataIndex: 'arrivedAt',
            render: arrivedAt => arrivedAt ? displayTime(i18n.language, arrivedAt) : '-'
        },
        {
            title: t('nombre de personnes'),
            dataIndex: 'nbPeople',
            render: nbPeople => nbPeople || '-'
        },
        {
            title: t('extras'),
            dataIndex: 'addons',
            render: (addons, record) => <small>
                {addons && addons.length ? addons.map(addon => (
                    <p key={`${record.id}_${addon.name}`}>
                        <span>{addon.amount} x {addon.name} {addon.price} {getSymbolFromCurrency(record.currency)}</span><br/>
                    </p>
                )) : '-'}
            </small>
        },
        {
            title: t('statut'),
            dataIndex: 'status',
            render: (status, record) => (
                <DropdownStatus
                    infos={{name: status, id: record.id}}
                    reload={() => setContentVersion(contentVersion + 1)}
                />
            )
        },
        {
            title: t('N° et heure de réservation'),
            dataIndex: 'bookingId',
            render: (bookingId, record) => (
                <span>
                    <span>{bookingId}</span><br/>
                    <small>{dayjs(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
                </span>)
        },
        {
            title: t('prix'),
            dataIndex: 'price',
            render: (price, record) => <small>{price} {getSymbolFromCurrency(record.currency)}</small>
        },
        {
            title: t('commentaire'),
            dataIndex: 'comment',
            render: (comment, record) => (
                <Badge dot={comment && comment.length}>
                    <Button
                        icon={<CommentOutlined/>}
                        onClick={() => openDrawer(record)}
                    />
                </Badge>
            )
        }
    ];

    function openDrawer(record) {
        setBookingSelected(record);
        setVisible(true);
    }

    return (
        <>
            <ApiCollectionLatest
                title={(currentPageData) => <React.Fragment>
                    <b>{t('dernières Reservations')}</b>
                    <Link style={{float: "right"}} to={"/reservations"}>{t('voir tout')}</Link>
                </React.Fragment>}
                fetch_endpoint={`accounts/${id}/bookings`}
                columns={columns}
                pageSize={5}
                onClick={(item) => setSelectedItem({...item})}
                contentVersion={contentVersion}
                showHeader
                filters={filters}
                {...rest}
            />

            <CommentDrawer visible={visible} setVisible={setVisible} booking={bookingSelected}/>
        </>
    );
};

export default ReservationDash;
