import React, {useState} from "react";
import Button from "./Button";
import {ReactComponent as User} from '../svg/user.svg'
import {ReactComponent as Key} from '../svg/key.svg'
import Input from "./Input";
import {useWidgetContext} from "../../providers/WidgetProvider";
import Loading from "./Loading";
import ForgetPassword from "./ForgetPassword";

export default function Login({switchMode}) {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, i18n} = widgetState
    const {post, setter} = widgetDispatch;
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const [displayForgetPassword, setDisplayForgetPassword] = useState(false)

    const handleChange = (e, valueSetter) => {
        valueSetter(e.target.value)
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault()
        let params = {
            email: email.trim(),
            password: password.trim()
        }
        let response = await post('login', params)
        if (response.success === 0) {
            setError(i18n.gettext('La connexion a échoué'));
            setLoading(false)
            return console.log('login fail')
        }
        response.type = 'connection'
        setter('user', response)
        setLoading(false)
    }

    return (
        <div className="order-content">
            <Loading loading={loading}>
                <div className="title" style={{marginBottom: '0'}}>
                    <h2>{i18n.gettext("Connexion")}</h2>
                </div>
                <div style={{height: '35px'}}>
                    <p style={{color: "red", textAlign: 'center'}}>
                        <small>{error}</small>
                    </p>
                </div>

                {displayForgetPassword ?
                    <ForgetPassword displayLogin={() => setDisplayForgetPassword(false)}/> :
                    <form onSubmit={handleSubmit}>

                        <Input
                            required value={email} type="email"
                            placeholder={i18n.gettext("Votre adresse mail")}
                            icon={<User style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                            onChange={(e) => handleChange(e, setEmail)}
                        />
                        <Input
                            required value={password} type="password"
                            placeholder={i18n.gettext("Votre mot de passe")}
                            icon={<Key style={{fill: widget.backgroundColor || "#2494D1"}}/>}
                            onChange={(e) => handleChange(e, setPassword)}
                        />

                        <div style={{textAlign: 'center', margin: '10px auto 16px'}}>
                            <p
                                style={{opacity: '.45', marginBottom: '24px', cursor: "pointer"}}
                                onClick={() => setDisplayForgetPassword(true)}
                            >
                                {i18n.gettext("Mot de passe oublié ?")}
                            </p>
                            <p style={{marginBottom: '5px', fontWeight: 'bold'}}>{i18n.gettext("Pas encore de compte ?")}</p>
                            <p style={{
                                color: widget.backgroundColor || "#2494D1",
                                textDecoration: 'underline',
                                cursor: "pointer"
                            }} onClick={switchMode}>{i18n.gettext("Inscrivez-vous !")}</p>
                        </div>

                        <div>
                            <Button type={"submit"} 
                                    id={`brd_login_${widget.id}`}
                                    style={{
                                        width: '100%',
                                        color: 'white',
                                        backgroundColor: widget.backgroundColor || '#2494D1'
                                    }}>
                                        {i18n.gettext("Valider")}
                            </Button>
                        </div>
                    </form>}

            </Loading>
        </div>
    )
}