import React from "react";
import firstImage from "../images/slider-1.jpg";
import {ReactComponent as Logo} from '../../../../public/logo-blanc.svg';


export default function SliderMobile() {
    return (
        <div
            className="carousel-container"
            style={{backgroundImage: 'url(' + firstImage + ')'}}
        >
            <div className="first-block">
                <h1 className="header">Optimisez vos revenus<br/>en journée avec</h1>
            </div>

            <div className="second-block"><Logo className="header"/></div>

            <div className="third-block">
                <h2 className="header">Le widget permettant de<br/>vendre des services hôteliers<br/>en journée</h2>
            </div>
        </div>
    )
}