import React, { useState } from 'react'
import { Button, Input, message, PageHeader, Select, Space, Switch, Tag, Tooltip } from "antd";
import { DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined, MailOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import CollectionTable from "../CollectionTable";
import { useApiContext } from "../../providers/ApiProvider";
import UserPermissions from "./UserPermissions";
import { UserForm } from "./UserForm";
import Modal from "antd/es/modal/Modal";
import { useTranslation } from "react-i18next";

export default function UserList({ role }) {

    const [apiDispatch] = useApiContext();
    const { apiDeleteEntity, apiUpdateEntity, apiPostEntity } = apiDispatch
    const [needUpdate, setNeedUpdate] = useState(0)
    const initialFilters = [{ name: "granted", value: role }]
    const [filters, setFilters] = useState(initialFilters)
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const [showPermission, setShowPermission] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [switchTimeout, setSwitchTimeout] = useState(null)
    const [searchField, setSearchField] = useState('email')
    const { t } = useTranslation();

    function editAccount(user) {
        setSelectedUser(user)
        setIsCreateVisible(true)
    }

    async function deleteUser(id) {
        let response = await apiDeleteEntity('users', id)
        setNeedUpdate((new Date()).getTime())
    }

    async function onChange(value, id) {
        clearTimeout(switchTimeout)

        setSwitchTimeout(setTimeout(async () => {
            let data = {
                roles: value ? ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'] : ['ROLE_ADMIN']
            }
            let response = await apiUpdateEntity('users', id, data)

            if (response['@type'] === 'hydra:Error') {
                message.error(t('il y a eu un soucis'))
                setNeedUpdate((new Date()).getTime())
            }
        }, 200))
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: `${t('supprimer')} ${item.username}`,
            icon: <ExclamationCircleOutlined />,
            content: t('cet utilisateur n\'aura plus acces a la plateforme.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteUser(item.id),
        });
    }

    async function sendMail(userId) {
        const data = { userId: userId };
        const response = await apiPostEntity('send-mail-user-login', data);

        if (response.success)
            return message.success(t('Message envoyé avec succès'));

        return message.error(t('Une erreur est survenue, contacter les developpeurs'));
    }

    const columns = [
        {
            title: 'Username',
            dataIndex: "username",
            fixed: 'left',
            render: (username, record) => (
                <span>
                    {username}
                </span>)
        },
        {
            title: 'Email',
            dataIndex: "email",
            render: (email) => (
                <span>
                    {email}
                </span>)
        },
        {
            title: 'Admin',
            dataIndex: "roles",
            render: (roles, record) => (
                <span>
                    <Switch defaultChecked={roles.includes('ROLE_SUPER_ADMIN')} size="small"
                        onChange={(value) => onChange(value, record.id)} />
                </span>)
        },
        {
            title: 'Actions',
            dataIndex: "id",
            render: (id, record) => (
                <Space>
                    <Tag onClick={() => setShowPermission(record)} className="pointer">{t('permissions')}</Tag>
                    <EditTwoTone onClick={() => editAccount(record)} />
                    <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)} />
                    <Tooltip title={t('Renvoyer mail avec password')}>
                        <SendOutlined
                            style={{ color: 'green', cursor: 'pointer' }}
                            onClick={() => sendMail(id)}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ]

    const [searchInterval, setSearchInterval] = useState(null)

    function handleSearch(e) {
        clearTimeout(searchInterval)
        console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setFilters([...initialFilters, {name: searchField, value: e.target.value.trim()}])
        }, 500))
    }

    const handleCreationFormClose = () => {
        setIsCreateVisible(false);
        setSelectedUser(null)
        setNeedUpdate((new Date).getTime())
    }

    return (
        <>
            <PageHeader
                title={t("utilisateurs")}
                extra={[
                    <Input.Group compact key="user_search">
                        <Select value={searchField} onChange={val => setSearchField(val)}>
                            <Select.Option value="email"><MailOutlined /></Select.Option>
                            <Select.Option value="username"><UserOutlined /></Select.Option>
                        </Select>
                        <Input onChange={handleSearch} style={{ width: '200px' }} placeholder={t("rechercher par nom")}
                        key="user_search" />
                    </Input.Group>,
                    <Button type="primary"
                        key="user_create"
                        onClick={() => setIsCreateVisible(true)}>{t('créer')}</Button>,
                ]}
            />

            <CollectionTable
                columns={columns}
                filters={filters}
                endpoint="users"
                reload={needUpdate}
            />

            {
                showPermission &&
                <UserPermissions user={showPermission} setUser={setShowPermission} modal />
            }
            <UserForm onClose={handleCreationFormClose} visible={isCreateVisible} modal item={selectedUser} />
        </>
    )

}