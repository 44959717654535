import React from "react";
import {useTranslation} from "react-i18next";
import image from '../images/offer-mobile.png'

export default function OfferMobile() {

    const {t} = useTranslation();

    return (
        <div className="offer-mobile-container">

            <div>
                <div>
                    <h1>{t('Offre')}</h1>
                    <h1 className="blue">{t('sur mesure')}</h1>
                </div>

                <div className="divider-mobile"/>

                <p>{t('Une interface conviviale vous permettant de définir notamment : Les types de prestations à commercialiser, la gestion des stocks, les plages horaires et les créneaux (heure/ demi heure..) et les prix')}</p>
            </div>

            <div>
                <img src={image} alt=""/>
            </div>
        </div>
    )
}