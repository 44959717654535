import React, {useEffect, useState} from "react";
import {useAccountContext} from "../../providers/AccountProvider";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../providers/ApiProvider";
import {Card, Col, Row, Statistic} from "antd";
import getSymbolFromCurrency from "currency-symbol-map";
import dayjs from "dayjs";

export default function AccountStatistic() {

    const [accountState] = useAccountContext();
    const {account} = accountState;
    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchEntity} = apiDispatch;

    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        fetch();
    }, [])

    async function fetch() {
        const response = await apiFetchEntity('account_with_stats', account.id);

        setStatistics(response);
    }

    return (
        <div>
            <Card bodyStyle={{padding: 20}}>
                <Row gutter={16}>
                    <Col span={8} style={{padding: 20}}>
                        <Statistic title={t('Nombre total de réservation')} value={statistics.totalBookings}/>
                    </Col>
                    <Col span={8} style={{padding: 20}}>
                        <Statistic title={t('Chiffre d\'affaire total')}
                                   value={`${statistics.totalCa} ${getSymbolFromCurrency(account.currency)}`}/>
                    </Col>
                    <Col span={8} style={{padding: 20}}>
                        <Statistic title={t('Première réservation')}
                                   value={statistics.firstBooking?.status ? dayjs(statistics.firstBooking.date).format('DD-MM-YYYY') : t('Pas de réservation')}/>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={8} style={{padding: 20}}>
                        <Statistic title={t('Nombre total de réservation ce mois-ci')} value={statistics.monthBookings}/>
                    </Col>
                    <Col span={8} style={{padding: 20}}>
                        <Statistic title={t('Chiffre d\'affaire ce mois-ci')}
                                   value={`${statistics.monthCa} ${getSymbolFromCurrency(account.currency)}`}/>
                    </Col>
                </Row>

            </Card>
        </div>
    )
}