import React, {useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Menu} from 'antd';
import {Link} from "react-router-dom";
import {UserOutlined} from '@ant-design/icons';
import {useAuthContext} from "../../../providers/AuthProvider";
import {useAccountContext} from "../../../providers/AccountProvider";
import AccountForm from "../../Account/AccountForm";
import {useTranslation} from "react-i18next";
import {useApiContext} from "../../../providers/ApiProvider";


export default function AvatarDropdown() {

    const [authState] = useAuthContext();
    const {auth} = authState;
    const isSuper = auth.roles.includes('ROLE_SUPER_ADMIN');
    const [accountState] = useAccountContext()
    const [apiDispatch] = useApiContext();
    const {apiFetchCollection} = apiDispatch;
    const [isCreateVisible, setIsCreateVisible] = useState(false)
    const {t, i18n} = useTranslation();
    const [lngOptions, setLngOptions] = useState([{
        id: localStorage.getItem('i18nextLng').substring(0, 2),
        lng: localStorage.getItem('i18nextLng').substring(0, 2)
    }])

    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        fetchLng()
        return () => {
            isMounted.current = false;
        }
    }, [])

    const fetchLng = async () => {
        let params = {
            filters: [{name: 'type', value:'admin'}]
        }
        let response = await apiFetchCollection('translations', params)
        if (isMounted.current) {
            if (response['@type'] !== 'hydra:Error') {
                setLngOptions(response['hydra:member'])
            }
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to={`/profile/${auth.id}`}>{t('mon profil')}</Link>
            </Menu.Item>
            <Menu.Divider/>
            {
                accountState.account &&
                <>
                    <Menu.Item key="settings">
                        <Link to="/settings">{t('configuration')}</Link>
                    </Menu.Item>
                    <Menu.Divider/>
                </>
            }
            {
                isSuper &&
                <>
                    <Menu.Item key="admin">
                        <Link to="/admin">{t('administration')}</Link>
                    </Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item key="createAccount">
                        <span onClick={() => setIsCreateVisible(true)}>{t('créer un compte')}</span>
                    </Menu.Item>
                    <Menu.Divider/>
                </>
            }
            <Menu.SubMenu
                key={'lng'}
                title={t('langue')}
            >
                {
                    lngOptions.map(lng => <Menu.Item
                        style={{color: lng.lng === localStorage.getItem('i18nextLng').substring(0, 2) ? '#1890ff' : ''}}
                        key={'lng/' + lng.lng} onClick={() => i18n.changeLanguage(lng.lng)}>{lng.lng}</Menu.Item>)
                }
            </Menu.SubMenu>
            <Menu.Divider/>
            <Menu.Item key="logout">
                <Link to="/logout">{t('logout')}</Link>
            </Menu.Item>
        </Menu>
    )

    function handleCreate() {
        setIsCreateVisible(false)
        window.location.reload();
    }

    return (
        <>
            <Dropdown overlay={menu} trigger={['click']}>
                <Button size="large" icon={<UserOutlined/>} ghost/>
            </Dropdown>

            <AccountForm callback={handleCreate} isVisible={isCreateVisible} modal/>
        </>
    )
}