import React, {useEffect, useRef, useState} from 'react';
import {Skeleton, Table} from 'antd';
import {useApiContext} from "../../providers/ApiProvider";
import dayjs from "dayjs";
import 'dayjs/locale/fr'
import DatePicker from "../DatePicker";
import {useTranslation} from "react-i18next";
import {resolveNamesLanguage, sortTimeSlot} from "../utils";
import {useAccountContext} from "../../providers/AccountProvider";
import getSymbolFromCurrency from "currency-symbol-map";

dayjs.locale('fr')

const today = dayjs()


const PriceDash = ({id}) => {

    const [accountState] = useAccountContext();
    const {t, i18n} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchSubResource} = apiDispatch
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState(today)

    const [pricesAvailable, setPricesAvailable] = useState(0)
    const [categories, setCategories] = useState([])
    const isMounted = useRef(null);

    useEffect(() => {
        isMounted.current = true;

        fetchPrice()

        return () => {
            isMounted.current = false;
        }

    }, [selectedDate])

    async function fetchPrice() {
        setLoading(true)
        setCategories(null)
        let firstDateNeeded = dayjs(selectedDate).format('DD-MM-YYYY')

        let params = {
            id: id,
            filters: [
                { name: 'firstDateNeeded', value: firstDateNeeded },
                { name: 'days', value: 0 }
            ]
        }
        let response = await apiFetchSubResource('accounts', params, 'categories')

        if (isMounted.current) {
            setCategories(filterCategories(response['hydra:member']))
            setLoading(false)
        }
    }

    function filterCategories(categories) {
        let result = []
        let nbPrices = 0
        categories.map(category => {
            let addCat = false
            // if there is timeSlot set we keep the category
            if (category.timeSlots.length > 0) {
                addCat = true

                category.timeSlots.map(time => {
                    if (time.offers.length > 0) {
                        if (time.offers[0].price > 0) {
                            nbPrices++
                        }
                    }
                })
                if (addCat) result.push(category)
            }
        })

        setPricesAvailable(nbPrices)
        return result;
    }

    const columns = [
        {
            title: t('nom'),
            dataIndex: 'name',
            width: '80%',
            render: (name, record) => (<span style={{color:'#9e9ea7'}}>{record.start} - {record.end}</span>)
        },
        {
            title: t('prix'),
            dataIndex: 'offers',
            width: '20%',
            render: (offers) =>
                offers.length > 0 ?
                    <span style={{color: '#00E540'}}>{offers[0].price} {getSymbolFromCurrency(accountState.account.currency)}</span> :
                    <span style={{color: '#FF9900'}}>0 {getSymbolFromCurrency(accountState.account.currency)}</span>

        },
    ];

    function onChange(value) {
        setSelectedDate(value)
    }

    return (
        <div className="site-calendar-customize-header-wrapper" style={{marginBottom: '1em'}}>

            <div className="flex between align-baseline">
            <p><strong>{t('service disponibles')}</strong></p>
                <DatePicker size="small" onChange={onChange} value={selectedDate} format='DD-MM-YYYY'/>
            </div>


            {
                loading ?
                    <Skeleton active /> :
                    <>
                        <p>{pricesAvailable} {t('prix disponibles pour le')} {selectedDate.format('DD-MM-YYYY')}</p>
                        {
                            categories && categories.map((category, index) =>
                                category.timeSlots.length > 0 && category.type !== 'duration' &&
                                <Table columns={columns}
                                       key={category.name}
                                       style={{marginBottom:'1em'}}
                                       bordered
                                       pagination={false}
                                       rowKey={obj => obj.id}
                                       title={()=>(<span style={{color: '#2494D1', fontWeight:'bold'}}>{resolveNamesLanguage(i18n, accountState, category)}</span>)}
                                       dataSource={category.timeSlots.sort(sortTimeSlot)}
                                       size="small" showHeader={false}/>
                            )
                        }
                    </>
            }
        </div>
    );
};

export default PriceDash;
