import React, {useEffect, useState} from 'react';
import {useWidgetContext} from "../../providers/WidgetProvider";
import Calendar from "react-calendar";
import SimpleNextStepBtn from "../component/SimpleNextStepBtn";
import {ReactComponent as Prev} from '../svg/prev.svg'
import {ReactComponent as Next} from '../svg/next.svg'
import dayjs from "dayjs";
import Loading from "../component/Loading";
import TagStepLight from "../component/TagStepLight";
import Button from "../component/Button";

export default function Step1() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, lng, i18n} = widgetState;
    const {setBookingInfos, post, setter} = widgetDispatch;
    const [loading, setLoading] = useState(true)

    const now = new Date();
    const [activeStartDate, setActiveStartDate] = useState(bookingInfos?.date ? new Date(dayjs(bookingInfos.date).startOf('month')) : new Date(dayjs().startOf('month')))
    const [value, setValue] = useState(bookingInfos?.date ? new Date(bookingInfos.date) : null)
    const [offers, setoffers] = useState({});

    const [noAvailable, setNoAvailable] = useState(true);
    const [needUpdate, setNeedUpdate] = useState(0);
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        let date = value || now
        fetchAvailabilty(date, !bookingInfos?.date)
    }, [needUpdate])

    const fetchAvailabilty = async (date, firstCheck) => {
        setLoading(true)
        //debug 1 allow you to get all offeres from db instead of boolean, to be used only in dev
        let data = {
            accountId: widget.account.id,
            date: dayjs(date).format('YYYY-MM-DD'),
            firstCheck: firstCheck,
            // debug: 1
        }

        if (widget.tags && widget.tags.length)
            data.tags = widget.tags.map(tag => tag.split('/').pop())

        const response = await post('get-availability', data);
        setoffers(prev => {
            return {...prev, ...response.offers}
        })

        if (response.firstDayAvailable && firstCheck) {
            setValue(new Date(response.firstDayAvailable))
            setActiveStartDate(new Date(dayjs(response.firstDayAvailable).startOf('month')))
        }

        if (response.firstDayAvailable) {
            setNoAvailable(false);
        }

        setLoading(false)
    }

    const isDisabled = ({date}) => {
        let current = dayjs(date)
        if (current.isBefore(dayjs(now).startOf('day'))) return false
        return !offers[current.format('YYYY-MM-DD')]
    }

    const handleActiveStart = ({activeStartDate}) => {
        setActiveStartDate(activeStartDate)
        fetchAvailabilty(activeStartDate)
    }

    const newRequest = () => {
        setter('widget', {...widget, tags: selectedTags});
        setNeedUpdate(needUpdate + 1);
        setSelectedTags([]);
    }

    function resolveText() {
        if (widget.textNoAvailable?.[lng])
            return widget.textNoAvailable[lng];

        if (widget.textNoAvailable?.[widget.account.defaultLanguage])
            return widget.textNoAvailable[widget.account.defaultLanguage];

        return i18n.gettext('Nous sommes désolé, nous n\'avons plus de disponibilité');
    }

    return (
        <div className="full background-grey">
            <div className="step_content" style={{backgroundColor: '#ecedee'}}>
                {
                    loading ?
                        <Loading loading={loading}>
                            <Calendar
                                locale={lng}
                                minDate={new Date()}
                                activeStartDate={activeStartDate}
                                prevLabel={<Prev/>}
                                nextLabel={<Next/>}
                                next2Label={null}
                                prev2Label={null}
                                value={value}
                                view="month"
                            />
                        </Loading>
                        :
                        <Calendar
                            locale={lng}
                            activeStartDate={activeStartDate}
                            minDate={now}
                            prevLabel={<Prev/>}
                            nextLabel={<Next/>}
                            next2Label={null}
                            prev2Label={null}
                            value={value}
                            onChange={setValue}
                            view="month"
                            tileDisabled={isDisabled}
                            onActiveStartDateChange={handleActiveStart}
                        />
                }

                {!loading && noAvailable ?
                    <div style={{marginTop: 20}}>
                        <div>{resolveText()}</div>

                        {widget.noAvailableOption === 'displayTags' &&
                        <TagStepLight
                            selectedTags={selectedTags}
                            setSelectedTags={setSelectedTags}
                        />}
                    </div> : null}

            </div>

            {selectedTags.length ?
                <Button
                    className="simple_next_step_btn"
                    style={{
                        backgroundColor: widget.backgroundColor || "#2494D1",
                        color: '#fff',
                    }}
                    onClick={newRequest}
                >
                    {i18n.gettext('Choisir ce type de service')}
                </Button> :
                <SimpleNextStepBtn
                    onClick={() => setBookingInfos({date: dayjs(value).format('YYYY-MM-DD')})}
                    disabled={noAvailable}
                />}
        </div>
    )
}