import React from 'react';
import {useTranslation} from "react-i18next";
import {PageHeader, Tabs} from "antd";
import EditMail from "../components/Emails/EditMail";

const {TabPane} = Tabs;

export default function Emails() {

    const {t} = useTranslation();

    return (
        <div>
            <PageHeader
                title={<h1>{t('Emails')}</h1>}
                style={{borderBottom: '2px solid #2494D1'}}
            />

            <Tabs defaultActiveKey="1">
                <TabPane tab={t("Email de reservation")} key="booking">
                    <EditMail type="booking"/>
                </TabPane>


                <TabPane tab={t("Email d'annulation")} key="cancel">
                    <EditMail type="cancel"/>
                </TabPane>
            </Tabs>
        </div>
    )
}