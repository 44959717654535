import React, {useEffect, useState} from 'react';
import {Button, Space, Tag} from 'antd';
import ApiCollectionLatest from '../ApiCollectionLatest.js';
import {CategoriesForm} from "./CategoriesForm";
import {useApiContext} from "../../providers/ApiProvider";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import Modal from "antd/es/modal/Modal";
import {useTranslation} from "react-i18next";
import ModalFreemium from "../Modal/ModalFreemium";
import {useAccountContext} from "../../providers/AccountProvider";
import {displayTime, resolveNamesLanguage, sortTimeSlot} from "../utils";
import dayjs from 'dayjs';

const CategoriesDash = ({id, endpoint, cardTitle, ...rest}) => {

    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity, apiFetchSubResource} = apiDispatch
    const {t, i18n} = useTranslation();
    const {account} = accountState;

    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)
    const [contentVersion, setContentVersion] = useState(0)

    const [canCreate, setCanCreate] = useState(true);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (!account.isPremium && !creationMode)
            fetch()
    }, [contentVersion, creationMode])

    async function fetch() {
        const response = await apiFetchSubResource('accounts', {id: id}, 'categories');

        if (response['hydra:totalItems'])
            setCanCreate(false)
        else
            setCanCreate(true)
    }



    const columns = [
        {
            title: t('nom'),
            dataIndex: 'names',
            fixed: 'left',
            render: (names, record) => resolveNamesLanguage(i18n, accountState, record)
        },
        {
            title: t('horaires'),
            dataIndex: "timeSlots",
            render: (timeSlots) => (
                <Space>
                    {timeSlots.length === 0 && <small style={{color: '#E64D3D'}}>{t('cette catégorie ne sera pas affiché lors de la réservation')}</small>}
                    {timeSlots.sort(sortTimeSlot).map(item => <Tag key={`time_${item.id}`}>{displayTime(i18n.language, item.start)} - {displayTime(i18n.language, item.end)}</Tag>)}
                </Space>
            )
        },
        {
            title: t('email'),
            dataIndex: 'email',
            width: '50px',
            render: (email, record) =>
                <div style={{textAlign: "right"}}>
                    <Space>
                        <EditTwoTone onClick={() => setSelectedItem(record)}/>
                        <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)}/>
                    </Space>
                </div>
        },
    ];

    const handleCreationFormClose = () => {
        setCreationMode(false);
        setSelectedItem(null)
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: t('confirmer la suppression'),
            icon: <ExclamationCircleOutlined/>,
            content: t('cette catégorie ne pourra plus être réserver mais les réservations en cours reste valide.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item),
        });
    }

    const deleteItem = async (item) => {
        let response = await apiDeleteEntity('categories', item.id)
        setContentVersion((new Date).getTime())
    }

    function wantCreate() {
        if (canCreate)
            setCreationMode(true)
        else
            setVisible(true)
    }

    if (creationMode || selectedItem)
        return <CategoriesForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <>
            <ApiCollectionLatest
                title={() => <div className="flex between">
                    <b>{t('Services')}</b>
                    <Space>
                        <Button
                            size="small"
                            onClick={wantCreate}
                        >
                            +
                        </Button>
                    </Space>
                </div>
                }
                fetch_endpoint={`accounts/${id}/categories`}
                columns={columns}
                pageSize={5}
                contentVersion={contentVersion}
                {...rest}
            />
            <ModalFreemium
                visible={visible}
                setVisible={setVisible}
            />
        </>
    );
};

export default CategoriesDash;
