import React, {useState} from "react"
import {Badge, Button, Modal, Space, Table, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {CommentOutlined, WarningOutlined} from "@ant-design/icons";
import CommentDrawer from "../Drawer/CommentDrawer";
import DropdownStatus from "../Reservation/DropdownStatus";
import {displayTime} from "../utils";
import getSymbolFromCurrency from "currency-symbol-map";

export default function ModalBookings({visible, setVisible, bookings, reload, setBookings}) {

    const {t, i18n} = useTranslation();

    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [bookingSelected, setBookingSelected] = useState({});

    function updateBookingsStatus(id, value) {
        const index = bookings.findIndex(booking => booking.id === id);
        bookings[index].status = value;
        setBookings([...bookings]);
    }

    const columns = [
        {
            title: t('client'),
            dataIndex: 'firstname',
            render: (firstname, record) => <>
                <div>
                    {firstname} {record.lastname}
                </div>
                {
                    !record.isConfidential && <div><small>{record.email} - {record.phone} </small></div>
                }
            </>
        },
        {
            title: t('service'),
            dataIndex: 'categoryName',
            render: categoryName => <small>{categoryName}</small>
        },
        {
            title: `${('date')} / ${t('horaire')}`,
            dataIndex: 'bookAt',
            render: (bookAt, record) => <span>
                {dayjs(bookAt).format('DD/MM/YYYY')} <br/>
                <small>{displayTime(i18n.language, record.timeSlotName.split('-')[0])}-{displayTime(i18n.language, record.timeSlotName.split('-')[1])}</small>
            </span>
        },
        {
            title: t('heure d\'arrivée'),
            dataIndex: 'arrivedAt',
            render: arrivedAt => arrivedAt ? displayTime(i18n.language, arrivedAt) : '-'
        },
        {
            title: t('nombre de personnes'),
            dataIndex: 'nbPeople',
            render: nbPeople => nbPeople || '-'
        },
        {
            title: t('extras'),
            dataIndex: 'addons',
            render: (addons, record) => <small>
                {addons && addons.length ? addons.map(addon => (
                    <p key={`${record.id}_${addon.name}`}>
                        <span>{addon.amount} x {addon.name} {addon.price} {getSymbolFromCurrency(record.currency)}</span><br/>
                    </p>
                )) : t('aucun service')}
            </small>
        },
        {
            title: t('statut'),
            dataIndex: 'status',
            render: (status, record) => (
                <DropdownStatus
                    infos={{name: status, id: record.id}}
                    reload={reload}
                    updateBookingsStatus={updateBookingsStatus}
                />
            )
        },
        {
            title: t('N° de réservation'),
            dataIndex: 'bookingId',
            render: bookingId => bookingId
        },
        {
            title: t('prix'),
            dataIndex: 'price',
            render: (price, record) => <small>{price} {getSymbolFromCurrency(record.currency)}</small>
        },
        {
            title: t('commentaire'),
            dataIndex: 'comment',
            render: (comment, record) => (
                <Space>
                    <Badge dot={comment && comment.length}>
                        <Button
                            icon={<CommentOutlined/>}
                            onClick={() => openDrawer(record)}
                        />
                    </Badge>
                    {
                        !record.offer && record.status !== 'canceled' &&
                        <Tooltip title="Cette réservation était lié aux anciens horaires, une action de votre part est peut être nécessaire" color="#FF9900">
                            <WarningOutlined style={{color:"#FF9900"}}/>
                        </Tooltip>
                    }

                </Space>

            )
        }
    ];

    function openDrawer(record) {
        setBookingSelected(record);
        setVisibleDrawer(true);
    }

    return (
        <>
            <Modal
                width={1200}
                title={t("réservations")}
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={() => setVisible(false)}
            >
                <div>
                    <Table
                        columns={columns}
                        dataSource={bookings}
                        rowKey={record => record.id}
                    />
                </div>

            </Modal>

            <CommentDrawer visible={visibleDrawer} setVisible={setVisibleDrawer} booking={bookingSelected}/>
        </>
    )
}