import React from "react";

export default function Footer() {
    return (
        <div className="footer-container">
            <p className="small">MMCréation · Agence Internet Paris · 32, avenue de Wagram · 75008 Paris · Tél : 01 45 74 76 00 · <a
                href="mailto:info@mmcreation.com">info@mmcreation.com</a></p>
            <p className="small">© MMCréation.com 2021 - Mentions légales - CGV</p>
        </div>
    )
}