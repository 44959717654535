import React, { useEffect } from "react";
import { useLocation, Switch, Redirect } from 'react-router-dom';
import { useAccountContext } from "../providers/AccountProvider";

import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";

import Admin from '../pages/Admin'
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Categories from "../pages/Categories";
import TimeSlots from "../pages/TimeSlots";
import Dashboard from "../pages/Dashboard";
import AccountSettings from "../pages/AccountSettings";
import Settings from "../pages/Settings";
import Prices from "../pages/Prices";
import Profile from "../pages/Profile";
import Reservations from "../pages/Reservations";
import Booking from "../pages/Booking";
import Addons from "../pages/Addons";
import ClientRoute from "./Routes/ClientRoute";
import ClientHome from "../pages/ClientHome";
import Category from "../pages/Category";
import CreateAccount from "../pages/CreateAccount";
import Widgets from "../pages/Widgets";
import LandingPage from "../pages/LandingPage";
import Emails from "../pages/Emails";
import AnalyticsGuide from "../pages/AnalyticsGuide";

export default function Router() {

    const location = useLocation();
    const [accountState, accountDispatch] = useAccountContext();
    const { resetState } = accountDispatch;

    useEffect(() => {
        const regex = /\/(emails|dashboard|reservations|services|settings|prix|account|category|widgets).*/gm

        if (location.pathname === "/" || !location.pathname.match(regex))
            resetState();
    }, [location.pathname])

    return (
        <Switch>
            <PrivateRoute exact path="/admin" component={Admin} />
            <PrivateRoute exact path="/categories" component={Categories} />
            <PrivateRoute exact path="/horaires" component={TimeSlots} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/reservations" component={Reservations} />
            <PrivateRoute exact path="/services" component={Addons} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/prix" component={Prices} />
            <PrivateRoute exact path="/profile/:id" component={Profile} />
            <PrivateRoute exact path="/account" component={AccountSettings} />
            <PrivateRoute exact path="/category/:id" component={Category} />
            <PrivateRoute exact path="/widgets" component={Widgets} />
            <PrivateRoute exact path="/emails" component={Emails} />

            <ClientRoute exact path="/modifier-mon-profil/:id" component={Profile} />
            <ClientRoute exact path="/accueil" component={ClientHome} />

            <PublicRoute exact path="/" component={LandingPage} />
            <PublicRoute exact path="/landing-page" component={LandingPage} />
            <PublicRoute exact path="/create-account" component={CreateAccount} />
            <PublicRoute exact path="/gtm" component={AnalyticsGuide} />
            <PublicRoute exact path="/login-admin" component={Login} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/logout" component={Logout} />
            <PublicRoute exact path="/:account/:accountId/:widgetId" component={Booking} />

            <Redirect to="/" />
        </Switch>
    )
}
