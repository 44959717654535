import React, {useEffect, useState} from 'react'
import {useWidgetContext} from "../../providers/WidgetProvider";
import dayjs from "dayjs";
import Loading from "./Loading";
import getSymbolFromCurrency from "currency-symbol-map";

export default function CategoryOfferSelect() {

    const [widgetState, widgetDispatch] = useWidgetContext();
    const {widget, bookingInfos, index, i18n} = widgetState;
    const {setter, post, displayTime} = widgetDispatch;
    const [selected, setSelected] = useState(widgetState.bookingInfos.offer.firstSelection ? undefined : widgetState.bookingInfos.offer.id)
    const [loading, setLoading] = useState(false)
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        if (bookingInfos.offer.categoryTimeslot) {
            fetch();
        }
    }, [index])

    async function fetch() {
        setLoading(true)
        const data = {
            categoryId: bookingInfos.offer.category.id,
            date: bookingInfos.date || dayjs().format('YYYY-MM-DD'),
        }
        const response = await post('get-offers-by-category', data);


        setOffers(sortOffers(response['hydra:member']));

        setLoading(false)
    }

    const sortOffers = (offers) => {
        return offers.sort((a, b) => a.timeSlot.start === b.timeSlot.start ? a.timeSlot.end.localeCompare(b.timeSlot.end) : a.timeSlot.start.localeCompare(b.timeSlot.start));
    }

    const handleHour = (e) => {
        if (e.target.value === "") return
        let copy = {...bookingInfos};
        let offer = offers.find(offer => offer.id == e.target.value);
        setSelected(offer.id)
        copy.offer = offer
        setter('bookingInfos', copy);
    }

    return (
        <Loading loading={loading}>
            <div className="confirm-tab">
                <div className="confirm-tab-title">
                    <div className="background-transparent"
                         style={{backgroundColor: widget.backgroundColor || '#2494D1'}}/>
                    {i18n.gettext('a quelle heure souhaitez-vous réserver ?')}
                    <span style={{color: 'red'}}>*</span>
                </div>
                <div className="confirm-tab-content">
                    <div className="tab-line">
                        <div className="tab-infos" style={{width: '100%'}}>
                            <select className="select-input" onChange={handleHour} value={selected}>
                                <option key={'default'} value={""}>{i18n.gettext('Heure de réservation')}</option>
                                {offers.map(offer => (
                                    <option key={offer.id}
                                            value={offer.id}>
                                        {displayTime(offer.timeSlot.start)} {i18n.gettext('A')} {displayTime(offer.timeSlot.end)} - {offer.price} {getSymbolFromCurrency(widget.account.currency)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    )

}