import React, {useEffect, useState} from "react";
import LandingDesktop from "../components/LandingPage/desktop/LandingDesktop";
import LandingMobile from "../components/LandingPage/mobile/LandingMobile";
import {useApiContext} from "../providers/ApiProvider";

export default function LandingPage() {

    const [apiDispatch] = useApiContext();
    const {apiFetchEntityPublic} = apiDispatch;

    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [data, setData] = useState({loadingData: true})

    useEffect(() => {
        window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setInnerWidth(window.innerWidth));
    }, [])

    useEffect(() => {
        fetch()
    }, [])

    async function fetch() {
        const response = await apiFetchEntityPublic("public/landing", 1);

        data.loadingData = false;
        data.youtubeId = response.youtubeId;
        setData({...data});
    }

    return innerWidth < 800 ? <LandingMobile data={data}/> : <LandingDesktop data={data}/>;
}