import React, {useEffect, useRef, useState} from 'react'
import {useWidgetContext} from "../../providers/WidgetProvider";

export default function TextArea({value, onChange}) {

    const [widgetState] = useWidgetContext();
    const {i18n} = widgetState;

    const [height, setHeight] = useState(100)
    const [parentHeight, setParentHeight] = useState("auto");
    const [text, setText] = useState(value);
    const textAreaRef = useRef()

    useEffect(() => {
        setParentHeight(`${textAreaRef.current?.scrollHeight}px`);
        setHeight(`${textAreaRef.current?.scrollHeight}px`);
    }, [text]);


    const handleChange = (e) => {

        setHeight('auto')
        setParentHeight(`${textAreaRef.current?.scrollHeight}px`);

        setText(e.target.value)
        if (onChange) {
            onChange(e);
        }
    }

    return (
        <div style={{
            minHeight: parentHeight,
        }}>
        <textarea className="textArea" value={text}
                  style={{height: height}}
                  rows={1}
                  onChange={handleChange} ref={textAreaRef}
                  placeholder={i18n.gettext("Nombre de personnes, précisions et autres demandes...")}/>
        </div>
    )
}