import React, {useEffect, useRef, useState} from "react";
import {Button, message, Modal, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import Services from './Services'
import User from "./User";
import BookingRecap from "./BookingRecap";
import {ReactComponent as CheckCircle} from '../../widget/svg/check--circle.svg'
import {useTranslation} from "react-i18next";
import {useAccountContext} from "../../providers/AccountProvider";

export default function BookingModal({visible, setVisible, offerId, refresh}) {

    const {t} = useTranslation();
    const [accountState] = useAccountContext();
    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiPostEntityPublic} = apiDispatch;

    const [loading, setLoading] = useState(false);
    const [addonsSelected, setAddonsSelected] = useState([]);
    const [arrivalHour, setArrivalHour] = useState(undefined);
    const [comment, setComment] = useState("")

    const [bookingId, setBookingId] = useState("")
    const [offer, setOffer] = useState({});
    const [currentStep, setCurrentStep] = useState(0)
    const [user, setUser] = useState({})
    const formRef = useRef()

    useEffect(() => {
        fetch()
    }, [])


    async function fetch() {
        setLoading(true)
        const response = await apiFetchEntity('offers', offerId);
        setOffer(response)
        setLoading(false)
    }

    const lastStep = (
        <div style={{
            minHeight: '100%',
            padding: '57px 20px 20px',
            textAlign: 'center',
        }}>
            <div style={{marginBottom: '39px'}}>
                <CheckCircle style={{maxWidth: "55px", fill: '#2494D1'}}/>
            </div>
            <div style={{fontWeight: "bold", padding: '0 30px', fontSize: '16px'}}>
                <p style={{marginBottom: '16px'}}>{t('Merci !')}</p>
                <p style={{marginBottom: '16px'}}>
                    {t('Réservation effectué avec succes.')}
                    <br/>
                    {t('Un mail a été envoyé au client.')}
                </p>
                <p>{t('Votre numéro de réservation est le')} <span id="__resaday_id_resa__">{bookingId}</span>.</p>
            </div>

        </div>
    )

    const [nbPeople, setNbPeople] = useState(null);

    const steps = [

        {
            title: 'Options',
            content: <Services addons={offer?.category?.addons || []}
                               selectedAddons={addonsSelected}
                               setSelectedAddons={setAddonsSelected}
                               setComment={setComment}
                               comment={comment}
                               account={accountState.account}
                               category={offer?.category}
                               nbPeople={nbPeople}
                               setNbPeople={setNbPeople}
            />,
        },
        {
            title: 'Information client',
            content: <User timeslot={offer?.timeSlot} user={user} setUser={setUser} ref={formRef}
                           arrivalHour={arrivalHour} offer={offer}
                           setArrivalHour={setArrivalHour} setCurrentStep={setCurrentStep}/>,
        },
        {
            title: 'Resume',
            content: <BookingRecap offer={offer} addons={addonsSelected} user={user} account={accountState.account}/>,
        },
        {
            title: 'Confirmation',
            content: lastStep,
        },
    ];


    function close() {
        setVisible(false)
    }

    async function book(current) {
        setLoading(true)
        let data = {...user};
        data.categoryName = offer.category.name;
        data.timeSlotName = `${offer.timeSlot.start}h - ${offer.timeSlot.end}h`;
        data.bookAt = offer.day;
        data.account = offer.category.account;
        data.offer = `/api/offers/${offer.id}`;
        data.addons = addonsSelected.map(({name, price, amount}) => ({name, price, amount}));
        data.price = addonsSelected ?
            offer.price + addonsSelected.map(addon => addon.price * addon.amount).reduce((a, b) => a + b, 0)
            : offer.price;
        data.arrivedAt = arrivalHour;
        data.comment = comment || null;
        data.categoryId = offer.category.type === 'duration' ? offer.category.id : null;
        data.bookOn = 'Back office'
        data.isConfidential = false

        if (offer.category.isLimited && nbPeople)
            data.nbPeople = nbPeople;

        const response = await apiPostEntityPublic("public/bookings", data);
        if (response['@type'] === "hydra:Error") {
            message.error(response.error ? response.error : t('Il y a eu une erreur'))
        } else {
            setBookingId(response.bookingId);
            setCurrentStep(current);
            refresh();
        }
        setLoading(false)
    }

    function onStepChange(current) {
        if (current === 2) {
            formRef.current.submit()
            return
        }
        if (current === 3) {
            //make reservation
            return book(current)
        }
        setCurrentStep(current)

    }

    function renderFooter() {
        if (currentStep < 3) {
            return (
                <Space>
                    {
                        currentStep !== 0 &&
                        <Button type="primary" onClick={() => onStepChange(currentStep - 1)}>{t('Retour')}</Button>
                    }
                    <Button type="primary"
                            onClick={() => onStepChange(currentStep + 1)}>{currentStep === 2 ? t('Reserver') : t('Continuer')}</Button>
                </Space>
            )
        }

        return (
            <Button type="primary" onClick={close}>{t('terminer')}</Button>
        )

    }

    return (
        <Modal
            title={t("Reservation manuelle")}
            visible={visible}
            onCancel={close}
            footer={null}
            width={450}
            bodyStyle={{backgroundColor: "rgb(245, 245, 245)", borderRadius: '8px'}}
        >
            <Spin spinning={loading}>
                <div className="steps-content widget">{steps[currentStep].content}</div>
                <div className="flex justify-end">
                    {
                        renderFooter()
                    }
                </div>
            </Spin>
        </Modal>
    )
}