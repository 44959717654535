import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Input, message} from "antd";
import {useAuthContext} from "../../providers/AuthProvider";
import {useApiContext} from "../../providers/ApiProvider";
import {useHistory} from "react-router-dom";

export default function FormSubscription() {

    const {t} = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const {authLogin} = authDispatch;
    const [apiDispatch] = useApiContext();
    const {apiPostEntityPublic} = apiDispatch
    const history = useHistory();

    const [processing, setProcessing] = useState(false);

    const onFinish = async (values) => {
        setProcessing(true);
        const response = await apiPostEntityPublic('public/create-account', values);

        if (!response.success)
            return displayError(t(response.message));

        await authLogin(values.email, values.password, (response) => {
            if (response.error)
                displayError(response.error);
            else
                history.push('/');
        });
    };

    const displayError = (content) => {
        message.error(content);
        setProcessing(false);
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const rules = [
        {required: true, message: t('ce champ est obligatoire')}
    ]

    return (

        <div className="block-form">
            <div className="block-form-content">
                <h2>{t('Tester  le widget gratuitement')}</h2>


                <Form
                    id="form-subscription"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="firstname"
                        rules={rules}
                    >
                        <Input placeholder={t('Prénom')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="lastname"
                        rules={rules}
                    >
                        <Input placeholder={t('Nom')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="username"
                        rules={rules}
                    >
                        <Input placeholder={t('Nom d\'utilisateur')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={rules}
                    >
                        <Input placeholder={t('Email')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="accountName"
                        rules={rules}
                    >
                        <Input placeholder={t('Nom de votre hôtel')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="url"
                        rules={rules}
                    >
                        <Input placeholder={t('Url de votre site')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={rules}
                        hasFeedback
                    >
                        <Input placeholder={t('Mot de passe')} className="input"/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            ({getFieldValue}) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value)
                                        return Promise.resolve();

                                    return Promise.reject(t('les deux mots de passe ne sont pas indentiques'));
                                }
                            })
                        ]}
                    >
                        <Input placeholder={t('Confirmer le mot de passe')} className="input"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{width: '100%'}} className="confirm-btn"
                                loading={processing}>
                            {t('TESTER IMMEDIATEMENT ')}
                        </Button>
                    </Form.Item>
                </Form>


            </div>

            <div className="block-form-duplicate"/>
        </div>
    )
}