import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Image,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    Spin,
    Modal,
    Checkbox,
    Alert,
    Slider, Switch
} from "antd";
import { useApiContext } from "../../providers/ApiProvider";
import { useAccountContext } from "../../providers/AccountProvider";
import { CheckOutlined, CloseCircleTwoTone, CloseOutlined, PlusOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'
import TimePicker from "../TimePicker";
import LanguageSelect from "../Translation/LanguageSelect";
import { displayTime, resolveNamesLanguage } from "../utils";

const durations = [
    { minutes: 30, label: '00h30' }, { minutes: 60, label: '01h00' },
    { minutes: 90, label: '01h30' }, { minutes: 120, label: '02h00' },
    { minutes: 150, label: '02h30' },
]

export function CategoriesForm({ modal, onClose, item, visible = true }) {

    const { t, i18n } = useTranslation();
    const [accountState, accountDispatch] = useAccountContext();
    const { setHapidamKey } = accountDispatch
    const [apiDispatch] = useApiContext();
    const { apiPostEntity, apiFetchCollection, apiUpdateEntity, apiFetchSubResource } = apiDispatch
    const [loading, setLoading] = useState(false)
    const [servicesLoading, setServicesLoading] = useState(false)
    const [timeData, setTimeData] = useState([])
    const [services, setServices] = useState([])
    const [images, setImages] = useState(item ? item.images : [])
    const [hapidamClient, setHapidamClient] = useState(null);
    const [hapidamLoading, setHapidamLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [language, setLanguage] = useState('en')
    const [_names, setNames] = useState(item?.names ? item.names : { en: '' })
    const [_descriptions, setDescriptions] = useState(item?.descriptions ? item.descriptions : { en: '' })

    const [hapidamRequest, setHapidamRequest] = useState(item?.hapidamRequest || {})

    const [form] = Form.useForm();

    useEffect(() => {
        fetchTime()
    }, [])

    useEffect(() => {
        fetchServices()
    }, [search])

    useEffect(() => {
        setHapidamLoading(true)
        if (!hapidamClient) {
            setHapidamClient(new Hapidam())
        } else {
            let now = new Date().getTime()
            let isHapidamKeyWorking = accountState.hapidamKey.time ? accountState.hapidamKey.time + 3600000 > now : false

            if (accountState.hapidamKey.key && isHapidamKeyWorking) {
                hapidamClient.setToken(accountState.hapidamKey.key)
                setHapidamLoading(false)
            } else {
                fetchHapidamKey()
            }
        }
    }, [hapidamClient])

    async function fetchHapidamKey() {
        let data = { accountId: accountState.account.id }
        let response = await apiPostEntity('hapidam-connect', data)
        if (response.success) {
            setHapidamKey(response.publicKey)
            hapidamClient.setToken(response.publicKey)
        }

        setHapidamLoading(false)
    }

    async function fetchTime() {
        setLoading(true);
        let params = {
            id: accountState.account.id,
            filters: [{ name: 'type', value: 'standard' }, {name: 'pagination', value: false}]
        }
        let response = await apiFetchSubResource('accounts', params, 'time_slots');

        setTimeData(response['hydra:member']);
        setLoading(false)

    }

    async function fetchServices() {
        setServicesLoading(true)
        let params = {
            itemsPerPage: 30,
            filters: [{ value: search, name: 'name' }, {name: 'pagination', value: false}]
        }
        let response = await apiFetchCollection('services', params)
        setServices(response['hydra:member'])
        setServicesLoading(false)
    }

    async function onFinish(values) {
        setLoading(true)

        let params = {
            name: language === accountState.account.defaultLanguage ? values.name.trim() : _names[accountState.account.defaultLanguage],
            names: { ..._names, [language]: values.name.trim() },
            description: _descriptions.en ? _descriptions.en.trim() : null,
            descriptions: { ..._descriptions, [language]: values.description.trim() },
            timeSlots: typeof values.timeSlots === 'string' ? [values.timeSlots] : values.timeSlots,
            services: values.services,
            surface: values.surface,
            images: typeof images === 'object' ? images.filter(item => item !== null) : images,
            hapidamRequest: hapidamRequest,
            addons: values.addons,
            type: type,
            duration: type === 'duration' && values.duration ? values.duration.format('HH:mm').replace(':', 'h') : null,
            addCatTags: values.addCatTags,
            breaktime: values.breaktime,
            isConfidential: values.isConfidential,
            isLimited: values.isLimited,
            rangePeople: values.rangePeople
        }

        if (item) {
            await update(params)
        } else {
            params.account = accountState.account['@id']
            await postEntity(params)
        }

        setLoading(false)
        onClose()
    }

    async function postEntity(params) {
        let response = await apiPostEntity('categories', params)
    }

    async function update(params) {
        let response = await apiUpdateEntity('categories', item.id, params)
    }

    function onFinishFailed(values) {
        console.log(values)
    }

    function handleOk() {
        form.submit()
    }

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const handleHapidam = () => {
        hapidamClient.on('select', handleResponse);

        if (hapidamRequest.id)
            hapidamClient.openBuilder(JSON.stringify(hapidamRequest.query), { switched: 'on', limit: 5, multiple: true });
        else
            hapidamClient.open({ switched: 'on', limit: 5, multiple: true });
    }

    const handleResponse = async (response) => {
        hapidamClient.off('select', handleResponse);
        hapidamClient.close();

        if (response.display) {
            setImages(prev => [...prev, response]);
            setHapidamRequest({})
        } else if (typeof response === "object" && response.length) {
            if (response[0].uid) {
                setImages(prev => {
                    let next = [...prev, ...response]
                    next.length = 5;
                    // console.log(next);
                    return next;
                })
            } else {
                // console.log('is request')
                const query = { id: Date.now(), query: response };
                const body = { queries: [query] }

                const data = {
                    token: accountState.hapidamKey.key,
                    body: body
                }

                const responseDam = await apiPostEntity('hapidam-smart-request', data)
                setImages(responseDam.results[0].results);
                setHapidamRequest(query)
            }
        }
    }

    const deleteImage = (image) => {
        setImages(prev => prev.filter(item => item.uid !== image.uid))
        setHapidamRequest({});
    }

    function connect() {
        hapidamClient.on('select', handleConnectResponse);
        hapidamClient.connect()
    }

    function handleConnectResponse(response) {
        hapidamClient.off('select', handleResponse);
        setHapidamLoading(true)
        let params = {
            hapidamKey: response.token
        }
        updateAccount(params)
    }

    async function updateAccount(params) {
        let response = await apiUpdateEntity('accounts', accountState.account.id, params)
        fetchHapidamKey()
    }

    const [addons, setAddons] = useState([]);
    const [selectedAddons, setSelectedAddons] = useState(item ? item.addons : []);
    useEffect(() => {
        fetchAddons();
    }, [])

    async function fetchAddons() {
        const response = await apiFetchSubResource('accounts', { id: accountState.account.id }, 'addons');

        setAddons(response['hydra:member']);
    }

    const [tags, setTags] = useState([]);
    useEffect(() => {
        fetchTags();
    }, [])

    async function fetchTags() {
        const response = await apiFetchSubResource('accounts', { id: accountState.account.id }, 'tags');

        setTags(response['hydra:member']);
    }

    function onTagChange(value) {
        const index = tags.findIndex(tag => tag.id === value);
        const toAdd = tags[index].addons;
        const newValues = [...new Set([...selectedAddons, ...toAdd])];

        form.setFieldsValue({ addons: newValues });
    }

    const [searchInterval, setSearchInterval] = useState(null)

    function handleSearch(value) {
        clearTimeout(searchInterval)
        // console.log(e.target.value)
        setSearchInterval(setTimeout(function () {
            setSearch(value)
        }, 200))

    }

    const [servicesSelected, setServicesSelected] = useState(item ? [...item.services.map(item => item['@id'])] : [])
    const [type, setType] = useState(item ? item.type : 'timeslot')
    const [selectedTimeSlots, setSelectedTimeSlots] = useState(item ? item.timeSlots : [])
    const [possibleDuration, setPossibleDuration] = useState([])

    function handleChange(changedValues, values) {

        // console.log(changedValues)
        // console.log(type)
        setSelectedTimeSlots(typeof values.timeSlots === 'string' ? [values.timeSlots] : values.timeSlots)

        if (changedValues.services) {
            setServicesSelected([...changedValues.services])
        } else if ('type' in changedValues) {
            setType(changedValues.type ? 'duration' : 'timeslot')
            form.setFieldsValue({ timeSlots: [...values.timeSlots] })
            form.setFieldsValue({ duration: undefined })
        } else if (changedValues.timeSlots && type === 'duration') {
            form.setFieldsValue({ duration: undefined })
            resolveDuration(changedValues.timeSlots)
        }
    }

    function resolveDuration(iri) {

        let timeslot = timeData.find(item => item['@id'] === iri)

        let start = dayjs().hour(timeslot.start.split('h')[0]).minute(timeslot.start.split('h')[1])
        let end = dayjs().hour(timeslot.end.split('h')[0]).minute(timeslot.end.split('h')[1])

        let minuteDiff = end.diff(start, 'minutes')
        let possibleDur = []
        durations.map(item => {
            if (minuteDiff % item.minutes === 0) {
                possibleDur.push(item.label)
            }
        })
        setPossibleDuration(possibleDur)
    }

    const [loadingCatTags, setLoadingCatTags] = useState(true);
    const [catTags, setCatTags] = useState([]);
    useEffect(() => {
        fetchCatTags();
    }, [])

    async function fetchCatTags() {
        const response = await apiFetchSubResource('accounts', { id: accountState.account.id }, 'cat_tags');
        setCatTags(response['hydra:member']);
        setLoadingCatTags(false);
    }

    const handleLanguageChange = (newLanguage) => {

        let values = form.getFieldsValue()

        if (language) {
            setNames(prev => {
                return { ...prev, [language]: values.name.trim() }
            })
            setDescriptions(prev => {
                return { ...prev, [language]: values.description.trim() }
            })
        }
        form.setFieldsValue({
            name: _names[newLanguage] || '',
            description: _descriptions[newLanguage] || ''
        })
        setLanguage(newLanguage)
    }

    // check if this category has bookings
    const [hasBookings, setHasBookings] = useState(false);
    const [displayWarning, setDisplayWarning] = useState(false);
    useEffect(() => {
        if (item) {
            fetchBookings();
        }
    }, [])

    async function fetchBookings() {
        const response = await apiPostEntity('check-category-bookings', { id: item.id });

        if (response.success)
            setHasBookings(response.bookings > 0);
    }

    // check if has bookings when change timeSlot and display warning
    function onTimeSlotsChange() {
        if (hasBookings)
            setDisplayWarning(true);
    }

    const [disabledRange, setDisabledRange] = useState(!(item && item.isLimited));
    const marks = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10'
    };

    const content = (
        <Spin spinning={loading}>
            <LanguageSelect callback={handleLanguageChange} currentLng={language} />
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                onValuesChange={handleChange}
                initialValues={{
                    name: _names[language] ? _names[language] : '',
                    description: _descriptions[language] ? _descriptions[language] : '',
                    timeSlots: item ? [...item.timeSlots.map(time => time['@id'])] : [],
                    services: item ? [...item.services.map(service => service['@id'])] : [],
                    surface: item ? item.surface : null,
                    addons: item ? item.addons : [],
                    type: item?.type === 'duration',
                    duration: item?.duration ? dayjs(item.duration, 'HH:mm') : null,
                    addCatTags: item ? item.catTags.map(catTag => catTag.name) : [],
                    breaktime: item?.breaktime ? item.breaktime : 0,
                    isConfidential: item ? item.isConfidential : true,
                    isLimited: item?.isLimited ? item.isLimited : false,
                    rangePeople: item?.rangePeople ? item.rangePeople : [2, 5]
                }}
            >
                <div className="flex between">
                    <div style={{ width: '45%' }}>
                        <Space>
                            <Form.Item
                                label={t('nom')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('La langue par défault est requise'),
                                        validator: (rule, value) => {
                                            if ((language === accountState.account.defaultLanguage && !value) && !_names[accountState.account.defaultLanguage])
                                                return Promise.reject(new Error('La langue anglaise est requise'))

                                            return Promise.resolve();
                                        }
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={t('superficie')}
                                name="surface"
                            >
                                <InputNumber />
                            </Form.Item>
                        </Space>
                        <Form.Item
                            label={t("description courte")}
                            name="description"
                        >
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </div>

                    <div style={{ width: '45%' }}>
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            label={t("Photos")}
                            name="pictures"
                            tooltip={hapidamRequest ?
                                t('Cette selection provient d\'une requête si vous en supprimez une le mode requête sera annulé')
                                : t('5 photos maximum. Si vous en sélectionnez plus, le surplus ne sera pas enregistré')}
                        >
                            <Space wrap>
                                <Spin spinning={hapidamLoading}>
                                    <Image.PreviewGroup>
                                        <Space wrap>
                                            {images.map((image, index) =>
                                                image ?
                                                    <div key={index} style={{ position: 'relative' }}>
                                                        <CloseCircleTwoTone
                                                            style={{
                                                                position: 'absolute',
                                                                right: 10,
                                                                top: 10,
                                                                zIndex: 1000,
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => deleteImage(image)}
                                                        />
                                                        <Image
                                                            style={{
                                                                width: 100,
                                                                height: 100,
                                                                objectFit: 'contain',
                                                                backgroundColor: '#ddd'
                                                            }}
                                                            src={image.display.native.th}
                                                            preview={{
                                                                src: image.display.native.lg,
                                                            }}
                                                        />
                                                    </div>
                                                    :null
                                            )}
                                            {
                                                hapidamClient?.token ?
                                                    (images.length < 5 || hapidamRequest.id) &&
                                                    <div className="ant-upload ant-upload-select-picture-card">
                                                        <div className="ant-upload"
                                                            onClick={handleHapidam}
                                                            style={{ flexDirection: 'column' }}>
                                                            <PlusOutlined />
                                                            <div style={{ marginTop: 8 }}>{t('ajouter')}</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <p>{t('Votre compte n\'est pas encore lié à la phototèque')} Hapidam</p>
                                                        <Button onClick={connect}>{t('Connexion')} Hapidam</Button>
                                                    </div>
                                            }

                                        </Space>
                                    </Image.PreviewGroup>

                                </Spin>
                            </Space>

                        </Form.Item>
                    </div>
                </div>

                <div className="flex between">
                    <div style={{ width: '45%' }}>
                        {displayWarning && <Alert
                            message={t('Attention')}
                            description={t('Des réservations sont liées avec ces créneaux horraires, elles n\'apparaîtront plus dans le calendrier classique mais dans un tableau séparé')}
                            type="warning"
                            showIcon
                            closable
                        />}

                        <Form.Item label={t('Tranche(s) horaire(s)')}
                            tooltip={t('Un service ayant une seule tranche horaire peut être divisé en horaire plus court espacé d\'une pause.')}
                            name="timeSlots">
                            <Select
                                mode={type === 'duration' ? null : "multiple"}
                                style={{ minWidth: '250px', width: '100%' }}
                                placeholder={t('Sélectionner la/les tranche(s) horaire(s)')}
                                optionLabelProp="label"
                                onChange={onTimeSlotsChange}
                            >
                                {
                                    timeData.map(item =>
                                        <Select.Option key={`${item.id}`} value={item['@id']}
                                            label={`${displayTime(i18n.language, item.start)} - ${displayTime(i18n.language, item.end)}`}>
                                            <div className="demo-option-label-item">
                                                {displayTime(i18n.language, item.start)} - {displayTime(i18n.language, item.end)}
                                            </div>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="type"
                            valuePropName={'checked'}
                        >
                            <Checkbox
                                disabled={selectedTimeSlots.length > 1}
                                onChange={onTimeSlotsChange}
                            >
                                {t('A l\'heure')}
                            </Checkbox>
                        </Form.Item>
                        {
                            type === 'duration' && form.getFieldValue('timeSlots')?.length > 0 &&
                            <Space>
                                <Form.Item label={t('durée')} name="duration"
                                    rules={[
                                        {
                                            required: type === 'duration',
                                            message: t('la durée est requise'),
                                        },
                                    ]}>
                                    <TimePicker
                                        format={'HH:mm'}
                                        showNow={false}
                                        onChange={onTimeSlotsChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t('Intervalle entre les réservation (minutes)')}
                                    name="breaktime"
                                >
                                    <InputNumber
                                        min={0}
                                        step={5}
                                        onChange={onTimeSlotsChange}
                                    />
                                </Form.Item>
                            </Space>
                        }
                        {
                            item &&
                            <div>
                                <small>{t('Modifier un horaire, le type de catégories, la durée ou le temps de d\'intervalle supprimera tous les prix non valides liés à cette catégorie')}</small>
                            </div>
                        }
                    </div>
                    <div style={{ width: '45%' }}>
                        <Form.Item label={t('extras')}>
                            <Form.Item name="tag">
                                <Select
                                    style={{ width: 300 }}
                                    placeholder={t('Sélectionner une catégorie')}
                                    onChange={onTagChange}
                                >
                                    {tags.map(tag => (
                                        <Select.Option
                                            key={tag.id}
                                            value={tag.id}
                                            label={tag.name}
                                        >
                                            {tag.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="addons">
                                <Select
                                    mode="multiple"
                                    style={{ width: 300 }}
                                    placeholder={t("Sélectionner un extra")}
                                    optionLabelProp="label"
                                    optionFilterProp="label"
                                    value={selectedAddons}
                                    onChange={values => setSelectedAddons(values)}
                                >
                                    {addons.map(addon => (
                                        <Select.Option
                                            key={addon.id}
                                            value={addon['@id']}
                                            label={resolveNamesLanguage(i18n, accountState, addon)}
                                        >
                                            {resolveNamesLanguage(i18n, accountState, addon)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form.Item>


                    </div>
                </div>

                <div className="flex between">
                    <div style={{ width: '45%' }}>

                        <div style={{ display: "flex" }}>
                            <Form.Item
                                style={{ width: '50%' }}
                                label={t('Afficher le nombre de personnes')}
                                tooltip={t('Le client pourra ainsi préciser le nombre de personne pour la réservation')}
                                name="isLimited"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={value => setDisabledRange(!value)}
                                />
                            </Form.Item>

                            <Form.Item
                                style={{ width: '50%' }}
                                label={t('Limitation de personnes')}
                                name="rangePeople"
                            >
                                <Slider
                                    range
                                    min={1}
                                    max={10}
                                    marks={marks}
                                    disabled={disabledRange}
                                    onChange={e => console.log(e)}
                                />
                            </Form.Item>
                        </div>

                        <Form.Item
                            label={t('Informations clients')}
                            name="isConfidential"
                            tooltip={t('assurer la confidentialité des informations clients (email, téléphone)')}
                        >
                            <Radio.Group>
                                <Radio value={true}>{t('confidentiel')}</Radio>
                                <Radio value={false}>{t('non confidentiel')}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div style={{ width: '45%' }}>
                        <Form.Item label={t('Equipements')}
                            name="services" tooltip={t('5 maximum')}>
                            <Select
                                mode="multiple"
                                style={{ minWidth: '250px', width: '100%' }}
                                placeholder="Wifi ..."
                                optionLabelProp="label"
                                optionFilterProp="label"
                                maxTagCount='responsive'
                                onSearch={handleSearch}
                                loading={servicesLoading}
                            >
                                {
                                    services.map(item =>
                                        <Select.Option key={`${item.id}`} value={item['@id']}
                                            label={resolveNamesLanguage(i18n, accountState, item)}
                                            disabled={servicesSelected.length >= 5 && !servicesSelected.includes(item['@id'])}>
                                            <Space>
                                                <div className="select-option" style={{
                                                    display: 'inline-block',
                                                    width: '15px',
                                                    height: "auto"
                                                }}><span
                                                        dangerouslySetInnerHTML={{ __html: item.icon }} /></div>
                                                {resolveNamesLanguage(i18n, accountState, item)}
                                            </Space>
                                        </Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t('Type de service')}
                            name="addCatTags"
                        >
                            <Select
                                mode="multiple"
                                style={{ width: 300 }}
                                placeholder={t("Sélectionner un tag")}
                                optionFilterProp="label"
                                loading={loadingCatTags}
                                disabled={loadingCatTags}
                            >
                                {catTags.map(catTag => (
                                    <Select.Option key={catTag.id} value={catTag.name}>{catTag.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>


                {
                    !modal &&
                    <Form.Item>
                        <Space>
                            <Button onClick={onClose}>{t('retour')}</Button>
                            <Button type="primary" onClick={handleOk}>{item ? t("modifier") : t("créer")}</Button>
                        </Space>
                    </Form.Item>
                }

            </Form>
        </Spin>
    )

    if (modal) {
        return (
            <Modal visible={visible}
                destroyOnClose
                title={item ? `${t('Editer')} ${resolveNamesLanguage(i18n, accountState, item)}` : t("créer un service")}
                onOk={handleOk}
                okText={item ? t("modifier") : t("créer")}
                onCancel={onClose}
                cancelText={t('retour')}
                width={1300}
                style={{ top: '20px' }}
            >
                {content}
            </Modal>
        )
    } else {
        return content;
    }
}
