import React, {useState} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom'

import {Button, Card, Form, Input, message} from 'antd';
import {useAuthContext} from "../providers/AuthProvider";
import {useTranslation} from "react-i18next";
import ModalForgetPassword from "../components/Modal/ModalForgetPassword";

import logo from '../../public/logobd.png'

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function Login() {

    const {t} = useTranslation();
    const [authState, authDispatch] = useAuthContext();
    const {authLogin, authIsLogged} = authDispatch;
    const location = useLocation();
    const history = useHistory();

    const [processing, setProcessing] = useState(false);
    const isClientLogin = location.pathname === '/login';

    const onFinish = async (values) => {
        setProcessing(true)
        await authLogin(values.email, values.password, (response) => {
            if (response.error) {
                message.error(response.error)
                setProcessing(false)
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const changeLogin = () => {
        if (isClientLogin)
            history.push('/login-admin');
        else
            history.push('/login');
    }

    if (authIsLogged())
        return authState.auth.roles.includes('ROLE_ADMIN') && !isClientLogin ? <Redirect to="/home"/> :
            <Redirect to="/accueil"/>

    return (
        <div className="login-form-container">
            <Card className="card-style">
                <div className="text-center mb-1em">
                    <img src={logo} alt="" style={{width: 150}}/>
                </div>
                <h2 style={{textAlign: 'center', fontSize: '1.8em'}}>{isClientLogin ? t('connexion client') : t('connexion hôtelier')}</h2>
                <Form
                    {...layout}
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        margin: '1em auto',
                        minWidth: "50%",
                        padding: "1em",
                    }}
                >
                    <Form.Item
                        label="Username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('Entrez votre email.'),
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={t('Mot de passe')}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t('Entrez un mot de passe.'),
                            },
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <div className="flex justify-end">
                        <ModalForgetPassword/>
                    </div>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={processing}>
                            {t('Valider')}
                        </Button>
                    </Form.Item>
                </Form>

                <div className="flex between">
                    <Button type="link" onClick={changeLogin}>
                        {isClientLogin ? t('Je suis hôtelier') : t('Je suis voyageur')}
                    </Button>
                    {!isClientLogin &&
                    <Button type="link" onClick={() => history.push('/create-account')}>
                        {t('Créer un compte')}
                    </Button>}
                </div>
            </Card>
        </div>
    );
};
