import React, {useState} from 'react';
import {Button, Space} from 'antd';

import ApiCollectionLatest from '../ApiCollectionLatest.js';
import {TimeslotForm} from "./TimeSlotForm";
import Modal from "antd/es/modal/Modal";
import {DeleteTwoTone, EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import {useApiContext} from "../../providers/ApiProvider";
import {useTranslation} from "react-i18next";
import {displayTime} from "../utils";

const TimeSlotDash = ({id, endpoint, cardTitle, ...rest}) => {

    const [apiDispatch] = useApiContext();
    const {apiDeleteEntity} = apiDispatch
    const {t, i18n} = useTranslation();

    const [contentVersion, setContentVersion] = useState(0)
    const [selectedItem, setSelectedItem] = useState(null)
    const [creationMode, setCreationMode] = useState(false)

    const columns = [
        {
            title: '',
            dataIndex: 'categories',
            search: true,
            sorter: true,
            render: (categories, record) =>
                <small>{displayTime(i18n.language, record.start)} {t('à')} {displayTime(i18n.language, record.end)}</small>
        },
        {
            title: '',
            dataIndex: 'categories',
            search: true,
            sorter: true,
            render: (categories) => <small
                style={{color: categories.length === 0 ? '#E64D3D' : ''}}>{categories.length} {t('services liées')}</small>
        },
        {
            title: '',
            dataIndex: 'email',
            width: '50px',
            render: (email, record) =>
                <div style={{textAlign: "right"}}>
                    <Space>
                        <EditTwoTone onClick={() => setSelectedItem(record)}/>
                        <DeleteTwoTone twoToneColor="#E64D3D" onClick={() => showConfirm(record)}/>
                    </Space>
                </div>
        },
    ];

    const handleCreationFormClose = () => {
        setSelectedItem(null);
        setCreationMode(false)
    }

    const showConfirm = (item) => {
        Modal.confirm({
            title: t('confirmer la suppression'),
            icon: <ExclamationCircleOutlined/>,
            content: t('cette catégorie ne pourra plus être réserver mais les réservations en cours reste valide.'),
            okText: t('supprimer'),
            cancelText: t('annuler'),
            onOk: () => deleteItem(item),
        });
    }

    const deleteItem = async (item) => {
        await apiDeleteEntity('time_slots', item.id)
        setContentVersion((new Date).getTime())
    }

    function wantCreate() {
        setCreationMode(true);
    }

    if (selectedItem || creationMode)
        return <TimeslotForm modal item={selectedItem} onClose={handleCreationFormClose}/>;

    return (
        <>
            <ApiCollectionLatest
                title={(currentPageData) => <div className="flex between">
                    <b>{t('tranches horaires')}</b>
                    <Space>
                        <Button
                            size="small"
                            onClick={wantCreate}
                        >
                            +
                        </Button>
                    </Space>
                </div>}
                filters={[{name: 'type', value: 'standard'}]}
                fetch_endpoint={`accounts/${id}/time_slots`}
                // delete_endpoint="time_slots"
                columns={columns}
                pageSize={5}
                contentVersion={contentVersion}
                {...rest}
            />
        </>
    );
};

export default TimeSlotDash;
