import React from 'react';
import Icon from '@ant-design/icons';

const FaIcon = ({icon, spin, ...rest}) => {
    if(!icon)
        return <span>💀</span>;

    const SvgComponent = () => (
        <svg className={spin==="pulse" ? 'fa-pulse':(spin ? 'fa-spin':'')} data-prefix={icon.prefix} width="1em" height="1em" viewBox={"0 0 "+icon.icon[0]+" "+icon.icon[1]}>
            <path d={icon.icon[4]}  />
        </svg>
    );
    return <Icon component={SvgComponent} {...rest} />;
};

export default FaIcon;
