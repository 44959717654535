import React from "react";
import {useTranslation} from "react-i18next";
import background from '../images/confidentialité-pic.jpg'
import image from '../images/confidentialité.png'

export default function ConfidentialityMobile() {

    const {t} = useTranslation();

    return (
        <div className="confidentiality-mobile-container" style={{backgroundImage: `url(${background})`}}>
            <div><h1>{t('Confidentialité')}</h1></div>

            <div className="divider-mobile"/>

            <p>{t('Notre interface garantit une  confidentialité modulable : vous choisissez le niveau de confidentialité requis selon vos prestations. Le paiement se fait directement à l\'hôtel.')}</p>

            <img src={image} alt=""/>
        </div>
    )
}