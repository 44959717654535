import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {Button, Col, Divider, Input, message, Row, Spin, Table} from "antd";
import {useApiContext} from "../../providers/ApiProvider";


export default function TranslationList({selectedLng, baseLanguage, setNeedUpdate}) {

    const {t} = useTranslation();
    const [apiDispatch] = useApiContext();
    const {apiFetchEntity, apiUpdateEntity, deeplTranslation} = apiDispatch;
    const [loadingLng, setLoadingLng] = useState(true);
    const [language, setLanguage] = useState(null);
    const [isDirty, setIsDirty] = useState(false);
    const [isMissingDirty, setIsMissingDirty] = useState(false);
    const [updateTranslation, SetUpdateTranslation] = useState(1);
    const [updating, setUpdating] = useState(false);
    const [missingKeys, setMissingKeys] = useState({});
    const [textFilter, setTextFilter] = useState('');
    const [tableData, setTableData] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 30,
        showSizeChanger: false,
        position: 'bottom',
    })

    const sortFunction = (a, b) => {
        if (a.key.toLowerCase() > b.key.toLowerCase()) {
            return -1;
        }
        if (b.key.toLowerCase() > a.key.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    function handleTableChange(table_pagination) {
        // update current pagination
        const pager = {...table_pagination};
        pager.current = table_pagination.current;
        setPagination(pager)
        // trigger a data refresh
    }

    const columns = [
        {
            title: 'Clé',
            dataIndex: 'key',
            defaultSortOrder: 'descend',
            onFilter: (value, record) => record.key.includes(value),
            sorter: sortFunction,
            ellipsis: true,

        },
        {
            title: 'Traduction',
            dataIndex: 'translation',
            render: (translation, record) =>
                <Input value={translation} onChange={(e) => handleInputChange(e, record.key)}/>
            ,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (actions, record) =>
                <Button type={'danger'} size="small" shape="round"
                        onClick={() => handleDelete(record.key)}>{t('delete')}</Button>
        }
    ];


    useEffect(() => {
        setLoadingLng(true)
        fetchTranslation();
    }, [selectedLng, updateTranslation])

    const fetchTranslation = async () => {
        let response = await apiFetchEntity('translations', selectedLng.id)

        setLanguage(response);
        getMissingKeys(response);
        setTableData(formatDataForTable(response.content))
        setLoadingLng(false)

    }

    const updateLanguage = async (content) => {
        setUpdating(true)
        let data = {
            content: reOrderContent(content ? content : language.content)
        }

        let response = await apiUpdateEntity('translations', language.id, data)

        if (response['@type'] === 'hydra:Error') {
            SetUpdateTranslation(updateTranslation + 1)
            return message.error(t('la mise à jour a échoué'))
        }
        message.success(t('langue mise à jour'))
        setIsDirty(false);
        setIsMissingDirty(false)
        setUpdating(false)
        SetUpdateTranslation(updateTranslation + 1)
        if (language.lng === 'fr') {
            setNeedUpdate((new Date()).getTime())
        }

    }

    function reOrderContent(content) {
        const ordered = {};
        Object.keys(content).sort().forEach(function (key) {
            ordered[key] = content[key];
        });
        return ordered;
    }


    function formatDataForTable(content, filter = textFilter) {
        let tableData = []
        let keysTable = Object.keys(content);
        for (let i = 0; i < keysTable.length; i++) {
            if (keysTable[i].toLowerCase().includes(filter)) {
                let item = {
                    key: keysTable[i],
                    translation: content[keysTable[i]]
                }
                tableData.push(item)
            }
        }
        return tableData;
    }

    function getMissingKeys(language) {
        let missing = {};
        Object.keys(baseLanguage.content).map(key => {
            if (language.content[key] === undefined) {
                missing[key] = '';
            }
        })
        setMissingKeys(missing);
    }

    function handleInputChange(e, key) {

        let catCopy = {...language};
        catCopy.content[key] = e.target.value;
        setLanguage(catCopy);
        setTableData(formatDataForTable(catCopy.content));
        setIsDirty(true);
    }

    function handleMissingInputChange(e) {
        let key = e.target.dataset.key;
        let missingCopy = {...missingKeys};
        missingCopy[key] = e.target.value.trim();
        setMissingKeys(missingCopy);
        setIsMissingDirty(true);
    }

    function saveMissingChanges() {
        let completed = {}
        Object.keys(missingKeys).map(key => {
            if (missingKeys[key].trim() !== '') {
                completed[key] = missingKeys[key]
            }
        })
        let lngContentCopy = {...language.content, ...completed};
        updateLanguage(lngContentCopy);
    }

    function handleLngSearch(e) {
        let text = e.target.value.toLowerCase();
        setTextFilter(text);
        setTableData(formatDataForTable(language.content, text));
    }


    function handleDelete(deleteKey) {
        let newContent = {}
        for (let [key, translation] of Object.entries(language.content)) {
            if (key.toLowerCase() !== deleteKey.toLowerCase()) {
                newContent[key] = translation;
            }
        }
        setLanguage({...language, content: newContent});
        setTableData(formatDataForTable(newContent));
        setIsDirty(true)
    }


    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const translate = async () => {
        setLoadingLng(true);

        let response = await deeplTranslation(language.lng, 'fr', Object.keys(missingKeys))
            let translatedKeys = {};
            let keys = Object.keys(missingKeys);
            response['translations'].map((item, index) => {
                translatedKeys[keys[index]] = capitalize(item.text);
            })

            let lngContentCopy = {...language.content, ...translatedKeys};
            updateLanguage(lngContentCopy);
    }

    return (
        <Spin spinning={loadingLng}>
            <Table
                title={() => <div style={{display: 'flex', justifyContent: 'space-between'}}>{t("traductions actives")}
                    <Input.Search placeholder={t('rechercher')} style={{width: 300}} onChange={handleLngSearch}/></div>}
                style={{margin: '1em'}}
                columns={columns} dataSource={tableData} bordered
                scroll={{y: 600}} pagination={pagination} onChange={handleTableChange}/>
            {
                isDirty ?
                    <div>
                        <Button loading={updating} type={'primary'}
                                onClick={() => updateLanguage()}>{t('enregistrer')}</Button>
                    </div> : null
            }
            {
                Object.keys(missingKeys).length > 0 ?
                    <>
                        <Divider>{t('traductions manquantes')}</Divider>
                        <div>
                            <Button onClick={translate}>{t('traduire avec Deepl')}</Button>
                        </div>
                        <div style={{margin: '15px 0'}}>
                            <Row key="table-missing" gutter={[8, 8]}>
                                <Col xs={12}><strong>{t('clé').toUpperCase()}</strong></Col>
                                <Col xs={12}><strong>{t('traduction').toUpperCase()}</strong></Col>
                            </Row>
                            <Row key={'missing_trad'} className="mt-5" gutter={[8, 8]}>

                            {Object.keys(missingKeys).map(key =>
                                <React.Fragment key={'missing_'+key}>
                                    <Col xs={12}>{key}</Col>
                                    <Col xs={12}><Input size="small" onChange={handleMissingInputChange}
                                                        data-key={key}/>
                                    </Col>
                                </React.Fragment>
                            )}
                            </Row>

                        </div>
                        {
                            isMissingDirty ?
                                <Row>
                                    <Button loading={updating} onClick={saveMissingChanges}>{t('enregistrer')}</Button>
                                </Row> : null}
                    </> : null
            }

        </Spin>
    )
}
