import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Modal, Select, Space, Spin} from "antd";
import {useApiContext} from "../../providers/ApiProvider";
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fad} from '@fortawesome/pro-duotone-svg-icons'
import {fal} from '@fortawesome/pro-light-svg-icons'
import {far} from '@fortawesome/pro-regular-svg-icons'
import ServiceCard from "./ServiceCard";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../Translation/LanguageSelect";

const FaIcons = {solid: fas, brands: fab, duotone: fad, light: fal, regular: far}


export default function ServiceForm({onClose, isVisible, modal, service}) {

    const [apiDispatch] = useApiContext()
    const {apiPostEntity, apiFetchCollection, apiUpdateEntity, deeplTranslation} = apiDispatch
    const [faResult, setFaResult] = useState([])
    const [flatResult, setFlatResult] = useState([])
    const [currentProvider, setCurrentProvider] = useState('flatIcon')
    const [contentLoading, setContentLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [noResult, setNoResult] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState()
    const [iconError, setIconError] = useState(false)
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [language, setLanguage] = useState('fr')
    const [allLanguages, setAllLanguages] = useState([])
    const [_names, setNames] = useState({})

    useEffect(() => {
        setContentLoading(true)
        let servicesNames = service?.names ? service.names : {fr: ''}
        setNames(service?.names ? service.names : {fr: ''})
        setSelectedIcon(service ? service.icon : '')
        setFaResult([])
        form.setFieldsValue({name: servicesNames[language]})
        setContentLoading(false)

    }, [isVisible])

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 12,
        },
    };

    const checkDuplicate = async (name) => {
        let isDuplicate = false
        let params = {
            filters: [{name: 'name', value: name.trim()}]
        }
        let response = await apiFetchCollection('services', params)
        if (response['hydra:Error']) {
            return isDuplicate
        }
        response['hydra:member'].map(item => {
            let id = service ? service.id : null
            if (item.name.toLowerCase() === name.trim().toLowerCase() && id !== item.id) {
                message.error('Un service avec ce nom existe déjà')
                isDuplicate = true
            }
        })
        return isDuplicate
    }

    const onFinish = async (values) => {
        // console.log('Success:', values);
        setContentLoading(true)
        if (selectedIcon === '') {
            return setIconError(true)
        }
        values.icon = selectedIcon
        let isDuplicate = await checkDuplicate(values.name)
        if (!isDuplicate) {

            let params = {
                ...values,
                name: language === 'fr' ? values.name.trim() : _names.fr,
                names: {..._names, [language]: values.name.trim()},
            }

            if (service) {
                await update(params)
            } else {
                await post(params)
            }

            setSelectedIcon('')
            setIconError(false)
            form.resetFields()
            setContentLoading(false)

            onClose()
        }
    };

    const post = async (values) => {

        let response = await apiPostEntity('services', values)
        message.success(t('service crée avec succes'))
        // console.log(response)
    }

    const update = async (values) => {

        let response = await apiUpdateEntity('services', service.id, values)
        message.success(t('service mis à jour avec succes'))
        // console.log(response)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const handleSubmit = () => {
        form.submit()
    }


    async function iconSearch(e) {
        let provider = currentProvider
        let query = e.trim()
        if (query.length === 0) return;

        setLoading(true)
        setNoResult(false)

        let params = {
            filters:
                [
                    {name: 'query', value: query.toLowerCase()},
                    {name: 'provider', value: provider}
                ]
        }
        // icon finder ajax request only work on https
        let response = await apiFetchCollection('searchIcon', params)

        handleFaResults(response, provider)

    }


    function handleFaResults(response, provider) {

        let data = response.success ? response.data : null

        setNoResult(data.length === 0)
        if (provider === 'flatIcon') {
            setFlatResult(data)
        } else {
            setFaResult(data)
        }
        setLoading(false)
    }

    function selectIcon(icon) {
        setSelectedIcon(getIconString(icon))
    }


    function getIconString(icon) {
        let str = `<svg width="100%" height="100%" viewBox="${"0 0 " + icon.icon[0] + " " + icon.icon[1]}">`

        if (typeof icon.icon[4] === 'string') {
            str += `<path d="${icon.icon[4]}" fill="currentColor"/>`
        } else {
            icon.icon[4].map((item, index) => {
                str += `<path d="${item}" fill="${index === 0 ? 'black' : 'lightgray'}"/>`
            })
        }
        str += "</svg>"
        // console.log(str)
        return str;
    }

    const getFlatIcon = async (icon) => {
        setLoading(true)

        let response = await apiPostEntity('getIcon', {icon: icon})
        setSelectedIcon(response.code)
        setLoading(false)

    }


    const handleLanguageChange = (newLanguage) => {

        let values = form.getFieldsValue()
        console.log(values)
        setNames(prev => {
            return {...prev, [language]: values.name.trim()}
        })

        form.setFieldsValue({
            name: _names[newLanguage] || '',
        })
        setLanguage(newLanguage)
    }

    const translate = async () => {

        let text = _names.en ? _names.en : _names.fr
        if (!text) return message.error('completer l\'anglais ou le francais pour traduire dans cette langue');

        setContentLoading(true);


        let response = await deeplTranslation(language.toUpperCase(), [text])
        console.log(response)
        response['translations'].map((item, index) => {
            setNames({..._names, [language]: item.text});
            form.setFieldsValue({name: item.text})
        })

        setContentLoading(false)
    }

    const content = (
        <Spin spinning={contentLoading}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    name: service?.names?.[language] ? service.names[language] : '',
                    icon: selectedIcon
                }}
            >
                <LanguageSelect callback={handleLanguageChange} currentLng={language} forceLng={true}
                                setAllLanguages={setAllLanguages}/>
                <Button onClick={translate}>Traduire Auto</Button>

                <Form.Item
                    label={t("nom du service")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: t('Toutes les langues sont requises'),
                            validator: (rule, value) => {
                                console.log(language)
                                console.log(_names)
                                console.log(value)
                                for (let i = 0; i < allLanguages.length; i++) {
                                    if ((language === allLanguages[i] && !value) && !_names[allLanguages[i]]) {
                                        return Promise.reject(new Error('La langue anglaise est requise'))
                                    }
                                }
                                return Promise.resolve()

                            }
                        },
                    ]}
                >
                        <Input/>
                </Form.Item>

                <p>Icone</p>
                {
                    iconError && <p><small style={{color: 'red'}}>{t('l\'icone est requis')}</small></p>
                }
                <Space align="start">
                    <ServiceCard service={{name: 'preview', icon: selectedIcon}}/>

                    <Space direction="vertical">
                        <Select onChange={(val) => setCurrentProvider(val)}
                                style={{width: "200px"}}
                                value={currentProvider}>
                            <Select.Option value={'fa'}>Font awesome</Select.Option>
                            <Select.Option value={'flatIcon'}>FlatIcon</Select.Option>
                        </Select>
                        <Input.Search placeholder={t("rechercher un icone")}
                                      style={{width: "200px"}}
                                      loading={loading}
                                      onSearch={(e) => iconSearch(e)}/>
                        {
                            noResult ?
                                <div>{t('aucun résultat pour votre recherche')}</div> : null
                        }

                    </Space>
                </Space>
                <Spin spinning={loading}>
                    <Space className="mt-20" wrap>
                        {
                            currentProvider === 'fa' && faResult.map(icon =>
                                icon.styles.map(style =>
                                    <div className="icon pointer" key={`fa_${style}_${icon.name}`}
                                         onClick={() => selectIcon(FaIcons[style][icon.name], icon.label)}>
                                        <svg width="70%" height="70%"
                                             viewBox={"0 0 " + FaIcons[style][icon.name].icon[0] + " " + FaIcons[style][icon.name].icon[1]}>
                                            {typeof FaIcons[style][icon.name].icon[4] === 'string' ?
                                                <path d={FaIcons[style][icon.name].icon[4]} fill="currentColor"/>
                                                :
                                                FaIcons[style][icon.name].icon[4].map((item, index) =>
                                                    <path key={'path_' + index} d={item}
                                                          fill={index === 0 ? 'black' : 'lightgray'}/>
                                                )
                                            }
                                        </svg>
                                    </div>
                                ))
                        }
                        {
                            currentProvider === 'flatIcon' && flatResult.map(icon =>
                                <div className="icon pointer" key={icon.id} onClick={() => getFlatIcon(icon)}>
                                    <img style={{width: '70%'}} src={icon.images.svg}/>
                                </div>
                            )
                        }
                    </Space>
                </Spin>
            </Form>
        </Spin>
    )

    if (modal)
        return (
            <Modal
                visible={isVisible}
                destroyOnClose
                title={service ? `${t('editer')} ${service.name}` : t("créer un équipement")}
                onCancel={onClose}
                footer={[
                    <Button key="cancel_create" onClick={onClose}>
                        {t('retour')}
                    </Button>,
                    <Button key="create" type="primary" htmlType="submit" onClick={handleSubmit}>
                        {service ? t('modifier') : t('créer')}
                    </Button>
                ]}>
                {content}
            </Modal>
        )

    return (content)

}


